export function isEmpty(value) {
    return value === null || value === undefined || value === '';
}

export function getUid(length=12) {
    return parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", ""));
}

export function getNumberFormat(locale) {
    if(locale.toLowerCase() == 'de-de') {
        return {
            decimalCharacter: ',',
            digitGroupSeparator: '.'
        };
    }
    return {
        decimalCharacter: '.',
        digitGroupSeparator: ','
    };
}

export function sort(left, right, order, callback = undefined) {
    if((left === undefined || left === null) && (right === undefined || right === null)) {
        return 0;
    }
    if((left === undefined || left === null)) {
        return order === 'asc' ? 1 : -1;
    }
    if((right === undefined || right === null)) {
        return order === 'asc' ? 1 : -1;
    }
    if(typeof callback === 'function') {
        const result = callback(left, right, order);
        if(result !== undefined && result !== null) {
            return result * (order === 'asc' ? -1 : 1);
        }
    }
    if(typeof left == 'object' || typeof right == 'object') {
        return 0;
    }
    return left.localeCompare(right) * (order === 'asc' ? 1 : -1);
}