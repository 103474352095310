import KomponenteBase from "./komponente-base";
import { watch } from "vue";

export default class Komponente extends KomponenteBase {
    constructor(verpackung) {
        super(verpackung);
        this.create();
    }

    create(options = {}) {
        const selectable = typeof options.selectable !== "undefined" ? options.selectable : true;
        const selected = typeof options.selected !== "undefined" ? options.selected : false;
        this.key = options.key || '';
        this.name = options.title || '';
        this.hinweis = options.hinweis || '';
        this.imagePart = options.imagePart || '';
        this.hauptKomponente = options.hauptKomponente || false;
        this.kombiPart = options.kombiPart || 0;
        this.selectable = selectable
        this.selected = !selectable ? true : selected;
    }

    getJson() {
        const json = super.getJson();
        json.key = this.key;
        return json;
    }

    validate(options = {}) {
        return super.validate(options);
    }
}