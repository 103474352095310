import verpackungImages from "./verpackungImages";

export default {
    apiUrl: "https://backoffice.packlab.eko-punkt.de",
    apiKey: "XLHrYkCgWEh4FbiczrDXS1GBCQ6oSNsPY4LsfCM5TzbLZXmY",
    partnerKey: "eko",
    testing: false,
    verpackungImages: verpackungImages,
    contactMail: 'packlab@eko-punkt.de',
    contactPhone: '+49 221 9648970',
    contactUrl: 'https://www.eko-punkt.de/kontakt/'
}
