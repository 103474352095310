import {isEmpty} from "../../../../../utils/utils";

Packlab.Component.extend('verpackung-color-select', 'select-field', {
    inject: [ 'apiClient' ],

    props: {
        ruleset: {
            type: Object,
            required: true
        },
        wertstoff: {
            type: Object,
            required: true
        }
    },

    watch: {
        'wertstoff.wertstoff'() {
            this._updateSelectOptions();
        }
    },

    data() {
        return {
            wizardSelectOptions: []
        }
    },

    created() {
        this._updateSelectOptions();
    },

    methods: {
        _updateSelectOptions() {
            this.wizardSelectOptions = [{
                value: 'select',
                label: this.$t('Wählen Sie eine Farbe aus')
            }];
            const wertstoffColors = this.ruleset.getWertstoffColors(this.wertstoff?.wertstoff);

            for(let w in wertstoffColors) {
                const wertstoffColor = wertstoffColors[w];
                this.wizardSelectOptions.push({
                    value: w,
                    label: wertstoffColor.name,
                    default: wertstoffColor.default
                });
            }

            this.loadSelectOptions(this.wizardSelectOptions);
        }
    }
});
