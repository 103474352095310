import template from "./loader-overlay.html.twig";

Packlab.Component.register('loader-overlay', {
    template: template,

    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    }
});
