import template from "./verpackung-materialfraktion-select.html.twig";
import { Form, Field, ErrorMessage } from "vee-validate";
import {isEmpty} from "../../../../../utils/utils";

Packlab.Component.register('verpackung-materialfraktion-select', {
    template,
    components: { Form, Field, ErrorMessage },

    inject: [ 'apiClient' ],

    emits: [ 'update:modelValue', 'change', 'closed' ],

    props: {
        modelValue: {
            required: false,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        },
        btnText: {
            type: [ String, null ],
            required: false,
            default: null
        },
        btnStyle: {
            type: String,
            required: false,
            default: 'white'
        },
        showChangeBtn: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        modalOnly() {
            return this.btnStyle === 'hidden';
        },
        materialFraktionName() {
            if(this.modelValue) {
                return this.ruleset.getMaterialFraktionAuswahlTitle(this.modelValue);
            }
            return null;
        },
        currentStep() {
            return this.steps.filter((step) => { return step.key === this.currentStepKey; })[0] || null;
        },
        showPrevBtn() {
            return this.stepHistory.length > 0;
        },
        showNextBtn() {
            return this.currentStepKey !== 'result';
        },
        showSelectBtn() {
            return this.currentStepKey === 'result';
        },
        showOptionsStep() {
            return this.currentStepKey !== 'result';
        },
        showResultStep() {
            return this.currentStepKey === 'result';
        },
        stepTitle() {
            return this.currentStep?.title || '';
        },
        stepOptions() {
            const options = [];
            const dataOptions = this._getDataOptions(this.currentStepKey);
            let currentOption = 0;

            for(let o in dataOptions) {
                const option = {
                    value: o,
                    label: dataOptions[o].title || o
                };
                if(this.currentStepKey === 'materialFraktionen') {
                    option.materialFraktion = dataOptions[o];
                }
                options.push(option);
            }

            if(isEmpty(this.selectedOption) && options.length > 0) {
                this.selectedOption = options[0].value;
            }

            return options;
        },
        assocStepData() {
            const stepData = {};
            for(let i in this.stepData) {
                const data = this.stepData[i];
                stepData[data.key] = data.value;
            }
            return stepData;
        },
        btnClass() {
            if(this.btnStyle === 'link') {
                return '';
            }
            return `btn btn-${this.btnStyle} btn-sm`;
        },
        recyclinginfrastruktur() {
            if(typeof this.assocStepData.materialFraktionen === 'undefined') {
                return null;
            }
            const materialFraktion = this.ruleset.getMaterialFraktion(this.assocStepData.materialFraktionen);
            if(!materialFraktion) {
                return null;
            }
            return this.ruleset.getInfrastruktur(materialFraktion?.recyclinginfrastruktur);
        }
    },

    data() {
        return {
            isLoading: false,
            showModal: false,
            skipClosedEvent: false,
            wizardData: null,
            currentWizardData: null,
            selectedOption: '',
            currentStepKey: 'materialgruppen',
            stepHistory: [],
            stepData: [],
            steps: [
                {
                    key: 'materialgruppen',
                    title: this.$t('Wählen Sie eine Materialgruppe')
                },
                {
                    key: 'materialUntergruppen',
                    title: this.$t('Wählen Sie eine Untergruppe')
                },
                {
                    key: 'verpackungstypen',
                    title: this.$t('Wählen Sie den Verpackungstyp')
                },
                {
                    key: 'wertstoffe',
                    title: this.$t('Wählen Sie den Wertstoff der Hauptkomponente')
                },
                {
                    key: 'materialFraktionen',
                    title: this.$t('Wählen Sie eine Materialfraktion')
                },
                {
                    key: 'result',
                    title: this.$t('Ihre Auswahl')
                }
            ]
        }
    },

    methods: {
        onSelectMaterialFraktion() {
            this.showModal = true;
        },
        onHideModal() {
            this.showModal = false;
            if(!this.skipClosedEvent) {
                this.$emit('closed');
            }
        },
        onShowModal() {
            this.isLoading = true;
            this.skipClosedEvent = false;
            this.currentStepKey = 'materialgruppen';
            this.stepHistory = [];
            this.stepData = [];
            this.selectedOption = '';
            this.apiClient.getMaterialSelectWizard(this.ruleset.rulesetId)
                .then((result) => {
                    this.wizardData = result.wizard;
                    this.isLoading = false;
                });
        },
        goToNextStep() {
            this.stepHistory.push(this.currentStepKey);
            this._saveStep(this.currentStepKey, this.selectedOption);
            this.selectedOption = '';
        },
        goToPrevStep() {
            this.currentStepKey = this.stepHistory.pop();
            const currentStepDataIndex = this.stepData.findIndex((step) => { return step.key === this.currentStepKey; });
            this.selectedOption = this.stepData[currentStepDataIndex].value || '';
            this.stepData.splice(currentStepDataIndex, this.stepData.length - currentStepDataIndex);
        },
        selectMaterialFraktion() {
            this.skipClosedEvent = true;
            this.$refs.modal.hide();
            this.$emit('update:modelValue', this.assocStepData.materialFraktionen);
            this.$emit('change', {
                materialFraktion: this.assocStepData.materialFraktionen,
                wertstoff: this.assocStepData.wertstoffe
            });
        },
        _getDataOptions(currentStepKey) {
            if(!this.wizardData) {
                return [];
            }
            if(this.stepData.length === 0) {
                return this.wizardData['materialgruppen'];
            }
            let dataOptions = this.wizardData
            for(let s in this.stepData) {
                const step = this.stepData[s];
                if(typeof dataOptions[step.key] === "undefined") {
                    return [];
                }
                if(typeof dataOptions[step.key][step.value] === "undefined") {
                    return [];
                }
                dataOptions = dataOptions[step.key][step.value];
            }
            return dataOptions[currentStepKey];

        },
        _saveStep(stepKey, selectedOption) {
            const nextStepKey = this._getNextStepKey(stepKey);
            this.stepData.push({
                key: stepKey,
                value: selectedOption
            });

            if(nextStepKey !== 'result') {
                const nextStepDataKeys = Object.keys(this._getDataOptions(nextStepKey));
                if(nextStepDataKeys.length === 1) {
                    this._saveStep(nextStepKey, nextStepDataKeys[0]);
                    return;
                }
            }

            this.currentStepKey = nextStepKey;
        },
        _getNextStepKey(currentStepKey) {
            const currentStepIndex = this.steps.findIndex((step) => { return step.key === currentStepKey });
            if(currentStepIndex < 0) {
                return null;
            }
            if(currentStepIndex + 1 > this.steps.length) {
                return null;
            }
            return this.steps[currentStepIndex + 1].key;
        }
    }
})