import template from "./verpackung-checker.html.twig";
import {Form} from "vee-validate";

Packlab.Component.register('verpackung-checker', {
    template,
    emits: [ 'stepChanged' ],
    components: { Form },
    mixins: [ 'placeholder' ],

    inject: [ 'verpackungChecker', 'apiClient' ],

    props: {
        verpackungId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            showConfirmStep: false,
            isLoading: false,
            isSaving: false,
            totalCheckerSteps: 0,
            checkerSteps: null,
            stepHistory: [],
            currentStepKey: null,
            checkerStep: null,
            checkerStepValues: null,
            verpackung: null,
            ruleset: null,
            prevBtn: {
                show: false,
                title: ''
            },
            nextBtn: {
                show: false,
                title: ''
            }
        }
    },

    created() {
        this.createChecker();
    },

    methods: {
        getVerpackungCheckerStepValues() {
            if(typeof this.checkerStepValues['verpackung'] === 'undefined') {
                this.checkerStepValues['verpackung'] = {};
            }
            return this.checkerStepValues['verpackung'];
        },
        createChecker() {
            this.isLoading = true;
            this.checkerSteps = [];
            this.verpackungChecker.createChecker(this.verpackungId)
                .then((checker) => {
                    const notRecyclableReasons = checker.verpackung?.notRecyclableReasons || [];
                    this.checkerSteps = checker.stepKeys;
                    this.totalCheckerSteps = this.checkerSteps.length;
                    if(notRecyclableReasons.indexOf('recyclinginfrastruktur') === -1) {
                        if (this.showConfirmStep) {
                            this.checkerSteps.push('confirm');
                        }
                        this.checkerSteps.push('result');
                    }
                    this.currentStepKey = this.checkerSteps[0];
                    this.loadCheckerStep();
                })
                .catch(() => { this.isLoading = false; });
        },
        loadCheckerStep() {
            if(this.currentStepKey === 'result' || this.currentStepKey === 'confirm' && this.showConfirmStep || this.currentStepKey === 'verpackung-not-recyclable') {
                this.checkerStep = null;
                if(this.currentStepKey === 'result' && !this.showConfirmStep) {
                    this.isLoading = true;
                    this.verpackungChecker.calculateRecyclability(this.verpackungId)
                        .finally(() => {
                            this.isLoading = false;
                            this.$emit('stepChanged', this.currentStepKey);
                            this.updateButtons();
                        });
                } else {
                    this.$emit('stepChanged', this.currentStepKey);
                    this.updateButtons();
                }
            } else {
                this.isLoading = true;
                this.verpackungChecker.getCheckerStep(this.verpackungId, this.currentStepKey)
                    .then((checkerStep) => {
                        this.checkerStep = checkerStep.step;
                        this.checkerStepValues = checkerStep.stepValues;
                        this.verpackung = checkerStep.verpackung;
                        this.apiClient.getVerpackungRuleset(this.verpackung.rulsesetid)
                            .then((ruleset) => {
                                this.ruleset = ruleset;
                                this.updateButtons();
                                this.isLoading = false;
                                this.$emit('stepChanged', this.currentStepKey);
                            })
                            .catch(() => { this.isLoading = false});
                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            }
        },
        updateButtons() {
            this.prevBtn.show = this.stepHistory.length > 0;
            if(this.prevBtn.show) {
                if(this.currentStepKey === 'result' && this.showConfirmStep) {
                    this.prevBtn.title =  '< '+this.$t('Erklärung des Verantwortlichen');
                } else {
                    this.prevBtn.title = this.replacePlaceholders('< '+this.$t('Schritt {step}'), {
                        step: this.stepHistory.length
                    });
                }
            }

            this.nextBtn.show = this.checkerSteps.length > 1 && this.currentStepKey !== 'result';
            if(this.nextBtn.show) {
                if(this.currentStepKey === 'verpackung-not-recyclable') {
                    this.nextBtn.title = this.showConfirmStep ? '> '+this.$t('Erklärung des Verantwortlichen') : '> '+this.$t('Auswertung');
                } else if(this.currentStepKey === 'confirm' && this.showConfirmStep) {
                    this.nextBtn.title = '> '+this.$t('Auswertung');
                } else {
                    if(this.stepHistory.length === this.totalCheckerSteps - 1) {
                        this.nextBtn.title = this.showConfirmStep ? '> '+this.$t('Erklärung des Verantwortlichen') : '> '+this.$t('Auswertung');
                    } else {
                        this.nextBtn.title = this.replacePlaceholders("> "+this.$t('Schritt {step}'), {
                            step: this.stepHistory.length + 2
                        });
                    }
                }
            }
        },
        gotoPrevStep() {
            this.currentStepKey = this.stepHistory.pop();
            this.loadCheckerStep();
        },
        gotoNextStep() {
            if(this.currentStepKey !== 'verpackung-not-recyclable' && this.currentStepKey !== 'confirm') {
                this.stepHistory.push(this.currentStepKey);
            }
            if(this.currentStepKey === 'verpackung-not-recyclable') {
                this.currentStepKey = this.showConfirmStep ? 'confirm' : 'result';
                this.checkerStep = null;
                this.updateButtons();
            } else if(this.currentStepKey === 'confirm' && this.showConfirmStep) {
                this.isSaving = true;
                this.$refs.form.validate().then((result) => {
                    if(!result.valid) {
                        this.isSaving = false;
                        return;
                    }
                    this.verpackungChecker.saveCheckerBmg(this.verpackungId, this.verpackung.bmg)
                        .then(() => {
                            this.isSaving = false;
                            this.currentStepKey = 'result';
                            this.checkerStep = null;
                            this.updateButtons();
                        })
                        .catch(() => { this.isSaving = false; });
                });
            } else {
                this.isSaving = true;
                this.verpackungChecker.saveCheckerStep(this.verpackungId, this.currentStepKey, this.checkerStepValues)
                    .then((result) => {
                        const currentStepIndex = this.checkerSteps.indexOf(this.currentStepKey);
                        this.currentStepKey = result.nextStepKey || null;
                        if(this.currentStepKey === 'verpackung-not-recyclable') {
                            this.checkerStep = null;
                            this.updateButtons();
                        } else {
                            this.currentStepKey = currentStepIndex > -1 ? (this.checkerSteps[currentStepIndex + 1]) || null : null;
                            this.loadCheckerStep();
                        }
                        this.isSaving = false;
                    })
                    .catch(() => { this.isSaving = false; });
            }

        }
    }
});
