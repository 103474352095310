import template from "./product-box-footer.html.twig";

Packlab.Component.register('product-box-footer', {
    template,

    props: {
        products: {
            type: Array,
            required: false,
            default: []
        }
    },

    computed: {
        hasFlatrateProduct() {
            for(let p in this.products) {
                if(this.products[p].unlimitedCertificates) {
                    return true;
                }
            }
            return false;
        }
    }
});