import ModelBase from "./model-base";
import VerpackungTyp from "./verpackung-typ";
import {isEmpty} from "../../../utils/utils";

export default class VerpackungKombi extends ModelBase {
    constructor() {
        super();

        this.isKombi = false;
        this.createKombi = false;
        this.isGruppe = false;
        this.createGruppe = false;
        this.id = null;
        this.verpackungId = null,
        this.gruppeId = null;
        this.name = '';
        this.gruppeName = '';
        this.ean = '';
        this.image = {};
        this.activeVerpackungTypIndex = 0;
        this.verpackungTypen = [];
    }

    load(json, ruleset) {
        this.isKombi = json.isKombi;
        this.isGruppe = json.isGruppe;
        this.id = json.id;
        this.gruppeId = json.gruppeId;
        this.name = json.name;
        this.gruppeName = json.gruppeName;
        this.ean = json.ean;
        this.image = json.image;
        this.rulesetId = json.rulesetId;

        for(let i in json.verpackungTypen) {
            const verpackungTypJson = json.verpackungTypen[i];
            const verpackungTyp = new VerpackungTyp(this);
            verpackungTyp.getEmitter().on('updateVerpackungTyp', this._updateVerpackungTyp.bind(this));
            verpackungTyp.load(verpackungTypJson, ruleset);
            verpackungTyp.name = verpackungTypJson.name;
            this.verpackungTypen.push(verpackungTyp);
        }
    }

    create(options = {}) {
        this.createKombi = options.createKombi || false;
        this.createGruppe = options.createGruppe || false;
        this.id = options.id || null;
        this.verpackungId = options.verpackungId || null;
        this.gruppeId = options.gruppeId || null;
        this.isKombi = options.isKombi || false;
        this.isGruppe = options.isGruppe || false;
        this.name = options.name || '';
        this.gruppeName = options.gruppeName || '';
        this.activeVerpackungTypIndex = options.activeVerpackungTypIndex || 0;

        if((options.verpackungTypen || null) !== null) {
            for(let o in options.verpackungTypen) {
                this.addVerpackungTyp(options.verpackungTypen[o]);
            }
        } else {
            let verpackungTypOptions = (options.verpackungTyp || [])[0] || null;
            verpackungTypOptions = verpackungTypOptions ? verpackungTypOptions : options?.verpackungTyp;
            this.addVerpackungTyp(verpackungTypOptions);
        }

    }

    validate(options = {}) {
        const result = {};
        const verpackungTypenResult = {};
        const materialFraktion = options.materialFraktion === true;
        options.isKombi = this.isKombi;
        if(!materialFraktion && (this.isKombi || this.createKombi) && isEmpty(this.name)) {
            result['name'] = this.name;
        }
        /*if(!materialFraktion && this.isKombi && isEmpty(this.ean)) {
            result['ean'] = this.ean;
        }*/
        for (let p in this.verpackungTypen) {
            const verpackungsTypResult = this.verpackungTypen[p].validate(options);
            if (Object.keys(verpackungsTypResult).length > 0) {
                verpackungTypenResult[this.verpackungTypen[p].uid] = verpackungsTypResult;
            }
        }
        if(Object.values(verpackungTypenResult).length > 0) {
            result['verpackungTypen'] = verpackungTypenResult;
        }
        console.log('result', result);
        return result;
    }

    addVerpackungTyp(options) {
        this.verpackungTypen.push(this._createVerpackungTyp(options));
    }

    getActiveVerpackungTyp() {
        return this.verpackungTypen[this.activeVerpackungTypIndex];
    }

    getJson() {
        let createKombi = false;
        let deleteKombi = false;
        for(let t in this.verpackungTypen) {
            const verpackungsTyp = this.verpackungTypen[t];
            if(verpackungsTyp.createKombi) {
                createKombi = true;
            }
            if(verpackungsTyp.deleteKombi) {
                deleteKombi = true;
            }
        }
        return {
            isKombi: this.isKombi,
            isGruppe: this.isGruppe,
            createKombi: this.createKombi || createKombi,
            createGruppe: this.createGruppe,
            deleteKombi: deleteKombi,
            id: this.id,
            gruppeId: this.gruppeId,
            name: this.name,
            gruppeName: this.gruppeName,
            ean: this.ean,
            image: this.image,
            verpackungTypen: this.verpackungTypen.map((verpackungTyp) => { return verpackungTyp.getJson(); })
        }
    }

    getTotalVerpackungTypen() {
        return this.verpackungTypen.length;
    }

    hasKombiVerpackungTyp() {
        return this.verpackungTypen.filter((verpackungTyp) => { return (verpackungTyp.isKombi || verpackungTyp.createKombi) && !verpackungTyp.isKombi.deleteKombi });
    }

    _createVerpackungTyp(options) {
        const verpackungTyp = new VerpackungTyp(this);
        verpackungTyp.getEmitter().on('updateVerpackungTyp', this._updateVerpackungTyp.bind(this));
        verpackungTyp.create(options);
        return verpackungTyp;
    }

    _updateVerpackungTyp(verpackungTyp) {
    }
}