import { warn } from "../../utils/debug-utils";
const mixinRegistry = new Map();

function getMixinRegistry() {
    return mixinRegistry;
}

function register(mixinName, mixin = {}) {
    if (!mixinName || !mixinName.length) {
        warn(
            'MixinFactory',
            'A mixin always needs a name.',
            mixin,
        );
        return null;
    }

    if (mixinRegistry.has(mixinName)) {
        warn(
            'MixinFactory',
            `The mixin "${mixinName}" is already registered. Please select a unique name for your mixin.`,
            mixin,
        );
        return null;
    }

    mixinRegistry.set(mixinName, mixin);

    return mixin;
}

function getByName(mixinName) {
    if (!mixinRegistry.has(mixinName)) {
        throw new Error(`The mixin "${mixinName}" is not registered.`);
    }

    return mixinRegistry.get(mixinName);
}

export default {
    register,
    getByName,
    getMixinRegistry,
};