import template from "./verpackung-gruppe-delete-btn.html.twig";

Packlab.Component.register('verpackung-gruppe-delete-btn', {
    template,

    inject: [ 'apiClient' ],

    props: {
        verpackungGruppeId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isLoading: false,
            isDeleting: false,
            showModal: false,
            verpackungGruppe: null
        }
    },

    methods: {
        onOpenModal() {
            if(this.verpackungGruppe) {
                this.showModal = true;
            } else {
                this.isLoading = true;
                this.apiClient.getVerpackungsGruppe(this.verpackungGruppeId)
                    .then((result) => {
                        this.verpackungGruppe = result.verpackungGruppe;
                        this.showModal = true;
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            }
        },
        onHideModal() {
            this.showModal = false;
        },
        deleteverpackungGruppe() {
            this.isDeleting = true;
            this.apiClient.deleteVerpackungsGruppe(this.verpackungGruppeId)
                .then(() => {
                    this.isDeleting = false;
                    this.showModal = false;
                    this.$router.go(-1);
                })
                .catch(() => { this.isDeleting = false; });
        }
    }
});
