Packlab.Component.extend('salutation-enum-select', 'select-field', {
    created() {
      this.loadSelectOptions([
          {
              value: 81,
              label: this.$t('Herr')
          },
          {
              value: 82,
              label: this.$t('Frau')
          },
          {
              value: 83,
              label: this.$t('Ohne Anrede')
          },
      ]);
    },
    methods: {
        getDefaultOption() {
            if(this.defaultOption) {
                return this.defaultOption;
            }
            return {
                value: this.defaultValue,
                label: this.$t('Wählen Sie eine Anrede aus')
            }
        },
        updateDefaultValue() {
            return false;
        }
    }
});
