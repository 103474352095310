import template from "./verpackung-edit-btn.html.twig";

Packlab.Component.register('verpackung-edit-btn', {
    template,

    emits: [ 'click' ],
    inject: [ 'verpackungService' ],

    props: {
        verpackungId: {
            type: String,
            required: true
        },
        btnType: {
            type: String,
            required: false,
            default: 'primary'
        }
    },

    data() {
        return {
            isLoading: false,
            showCertificateModal: false,
            showRulesetModal: false,
            hasCertificate: null
        }
    },

    methods: {
        onOpenModal() {
            this.isLoading = true;
            this.verpackungService.loadVerpackung(this.verpackungId, false)
                .then((result) => {
                    this.verpackung = result.verpackungTyp.getVerpackungById(this.verpackungId);
                    if(!result.ruleset.isDefault) {
                        this.showRulesetModal = true;
                    } else if(this.verpackung.hasCertificate) {
                        this.showCertificateModal = true;
                    } else {
                        this.editVerpackung();
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        editVerpackung() {
            this.$emit('click', { verpackungId: this.verpackungId, verpackung: this.verpackung });
        }
    }
});