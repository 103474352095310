import template from "./verpackung-gruppe-copy-verpackung-page.html.twig";

Packlab.Component.register('verpackung-gruppe-copy-verpackung-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            verpackungGruppeId: this.$route.params.verpackungGruppeId,
            verpackungId: this.$route.params.verpackungId,
            isLoading: false,
            verpackungGruppeCopy: null,
            formData: {}
        }
    },

    created() {
        this.loadVerpackungGruppeCopy();
    },

    methods: {
        loadVerpackungGruppeCopy() {
            this.isLoading = true;
            this.apiClient.getVerpackungsGruppeCopy(this.verpackungGruppeId, this.verpackungId)
                .then((result) => {
                    this.verpackungGruppeCopy = result.verpackungGruppeCopy;
                    try {
                        this._createFormData();
                    } catch(e) {
                        console.error(e);
                    }
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        _createFormData() {
            if(this.verpackungGruppeCopy.verpackungKombis.length > 0) {
                this.formData.verpackungKombis = {};
            }
            for(let v in this.verpackungGruppeCopy.verpackungKombis) {
                const verpackungKombi = this.verpackungGruppeCopy.verpackungKombis[v];
                const verpackungKombiFormData = {
                    ean: verpackungKombi.ean,
                    name: verpackungKombi.name,
                    verpackungen: {}
                }
                this._createVerpackungenFormData(verpackungKombiFormData.verpackungen, verpackungKombi.verpackungen);
                this.formData.verpackungKombis[verpackungKombi.id] = verpackungKombiFormData;
            }

            if(this.verpackungGruppeCopy.verpackungen.length > 0) {
                this.formData.verpackungen = {};
                this._createVerpackungenFormData(this.formData.verpackungen, this.verpackungGruppeCopy.verpackungen);
            }
        },
        _createVerpackungenFormData(verpackungenFormData, verpackungen) {
            for(let v in verpackungen) {
                const verpackung = verpackungen[v];
                const verpackungFormData = {
                    name: verpackung.name,
                    ean: verpackung.ean,
                    dimensions: verpackung.dimensions,
                    description: verpackung.description,
                    komponenten: {},
                    weitereKomponenten: {}
                }
                this._createKomponentenFormData(verpackungFormData.komponenten, verpackung.komponenten);
                this._createKomponentenFormData(verpackungFormData.weitereKomponenten, verpackung.weitereKomponenten);
                verpackungenFormData[verpackung.id] = verpackungFormData;
            }
        },
        _createKomponentenFormData(komponentenFormData, komponenten) {
            for(let k in komponenten) {
                const komponente = komponenten[k];
                const wertstoffeFormData = {};
                for(let w in komponente.wertstoffe) {
                    const wertstoff = komponente.wertstoffe[w];
                    const wertstoffData = {};
                    for(let fieldName in wertstoff.conditionalFields) {
                        if(wertstoff.conditionalFields[fieldName] && typeof wertstoff.data[fieldName] !== 'undefined') {
                            wertstoffData[fieldName] = wertstoff.data[fieldName];
                        }
                    }
                    wertstoffeFormData[wertstoff.id] = {
                        weight: wertstoff.weight,
                        data: wertstoffData
                    };
                }
                const komponenteFormData = {
                    name: komponente.name,
                    wertstoffe: wertstoffeFormData
                };
                komponentenFormData[komponente.id] = komponenteFormData;
            }
        }
    }
});