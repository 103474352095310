import template from "./contract-list-page.html.twig";

Packlab.Component.register('contract-list-page', {
    template,

    inject: [ 'apiClient' ],
    mixins: [ 'enum' ],

    data() {
        return {
            isLoading: false,
            contracts: []
        };
    },

    computed: {
        contractTableColumns() {
            return [
                {
                    field: 'contractNumber',
                    label: this.$t('Vertragsnummer')
                },
                {
                    field: 'endDate',
                    label: this.$t('Laufzeit bis'),
                    type: 'date'
                },
                {
                    field: 'freeCertificates',
                    label: this.$t('Zertifikate kostenlos')
                },
                {
                    field: 'certificates',
                    label: this.$t('Zertifikate inklusive')
                },
                {
                    field: 'accounts',
                    label: this.$t('Anzahl Zugänge')
                },
                {
                    field: 'calculateFunds',
                    label: this.$t('Fondsberechnung')
                },
                {
                    field: 'contractStatus',
                    label: this.$t('Vetragsstatus')
                }                
                
            ];
        }
    },

    created() {
        this.loadContracts();
    },

    methods: {
        loadContracts() {
            this.isLoading = true;
            this.apiClient.getContracts()
                .then((result) => {
                    this.contracts = result.entities;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                });
        }
    }
});
