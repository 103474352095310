import template from "./verpackung-checker-yes-no-rule.html.twig";
import {getUid} from "../../../../../../utils/utils";

Packlab.Component.register('verpackung-checker-yes-no-rule', {
    template,

    props: {
        checkerStepValues: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },

    computed: {
        ruleId() {
            return getUid();
        },
        defaultValue() {
            if(typeof this.checkerStepValues['values'] === 'undefined') {
                this.checkerStepValues['values'] = {};
            }
            if(typeof this.checkerStepValues['values'][this.rule.checkKey] === 'undefined') {
                this.checkerStepValues['values'][this.rule.checkKey] = this.rule.defaultValue;
            }
            return this.checkerStepValues['values'][this.rule.checkKey];
        },
        options() {
            return [
                {
                    value: 40,
                    label: this.$t('Ja')
                },
                {
                    value: 41,
                    label: this.$t('Nein')
                }
            ]
        },
        isChecked() {
            if(typeof this.checkerStepValues['values'] === 'undefined') {
                return false;
            }
            return (this.checkerStepValues['values'][this.rule.checkKey] || 41) === 40;
        },

        contactPhone() {
            return Packlab.Config.contactPhone;
        },
        contactUrl() {
            return Packlab.Config.contactUrl;
        }
    },

    methods: {
        onRadioChanged(e) {
            this.checkerStepValues['values'][this.rule.checkKey] = e.target.checked ? 40 : 41;
        }
    }
});
