export function warn(name = 'Core', ...message) {
    if(typeof console === 'undefined') {
        return;
    }
    message.unshift(`[${name}]`);
    console.warn.apply(this, message);
}

export function error(name = 'Core', ...message) {
    if(typeof console === 'undefined') {
        return;
    }
    message.unshift(`[${name}]`);
    console.error.apply(this, message);
}

export default {
    warn,
    error
}
