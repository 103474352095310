import template from "./customer-password-change-page.html.twig";
import {Form} from "vee-validate";

Packlab.Component.register('customer-password-change-page', {
    template,

    components: { Form },

    inject: [ 'apiClient' ],
    mixins: [ 'form-validation' ],

    data() {
        return {
            isSaving: false,
        }
    },

    methods: {
        savePassword() {
            this.$refs.passwordForm.validatePassword(this.$refs.form)
                .then((result) => {
                    this.isSaving = true;
                    this.apiClient.saveCustomerAccountPassword('me', result.currentPassword, result.password)
                        .then(() => {
                            this.$router.push({ name: 'customer-profile' });
                        })
                        .catch(({ errors }) =>  {
                            this.isSaving = false;
                            this.setCustomFormErrors(this.$refs.form, errors);
                        });
                });
        }
    }
});