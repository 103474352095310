import template from "./customer-account-list-page.html.twig";

Packlab.Component.register('customer-account-list-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            isLoading: false,
            showAddCustomerAccountBtn: false
        }
    },

    created() {
        this.isLoading = true;
        this.apiClient.getContext().then((context) => {
            this.showAddCustomerAccountBtn = (context?.contract?.totalCustomerAccounts || 1) > (context?.contract?.usedCustomerAccounts || 1);
        }).finally(() => this.isLoading = false );
    }
});