import template from "./verpackung-create-wizard-page.html.twig";

Packlab.Component.register('verpackung-create-wizard-page', {
    template,

    data() {
        return {
            isLoading: false
        }
    },

    computed: {
        kombiVerpackungId() {
            return this.$route.query.kombiVerpackungId || null;
        }
    },

    methods: {
        onSaved() {
            this.$router.push({ name: 'verpackungen-list' });
        }
    }
});
