import template from "./verpackung-detail-page.html.twig";

Packlab.Component.register('verpackung-detail-page', {
    template,

    mixins: [ 'verpackung' ],
    inject: [ 'verpackungService' ],

    watch: {
        verpackungId() {
            this.loadVerpackung();
        }
    },

    computed: {
        verpackungId() {
            return this.$route.params.verpackungId;
        },
        verpackungKombiId() {
            return this.$route.query.verpackungKombiId || null;
        },
        verpackungMaterialGruppe() {
            const materialGruppe = this.ruleset.getMaterialGruppe(this.verpackung.materialGruppe);
            if(!materialGruppe) {
                return this.verpackung.materialGruppe;
            }
            return materialGruppe.title;
        },
        verpackungMaterialfration() {
            const materialFraktion = this.ruleset.getMaterialFraktion(this.verpackung.materialFraktion);
            if(!materialFraktion) {
                return this.ruleset.getMaterialFraktionAuswahlTitle(this.verpackung.materialFraktion);
            }
            return this.ruleset.getMaterialFraktionAuswahlTitle(this.verpackung.materialFraktion);
        },
        verpackungKomponenten() {
            const verpackungKomponenten = [];

            for(let k in this.verpackung.komponenten) {
                if(this.verpackung.komponenten[k].selected) {
                    verpackungKomponenten.push(this.verpackung.komponenten[k]);
                }
            }
            for(let k in this.verpackung.weitereKomponenten) {
                verpackungKomponenten.push(this.verpackung.weitereKomponenten[k]);
            }
            return verpackungKomponenten;
        },
        hasParent() {
            return this.verpackung && this.verpackung.parentGruppeVerpackungId !== null;
        },
        hasGroup() {
            return this.verpackung && this.verpackung.verpackungGruppe && this.verpackung.verpackungGruppe.id;
        },
        isVerpackungKombi() {
            return this.verpackung && this.verpackung.verpackungKombi && this.verpackung.verpackungKombi.id;
        },
    },

    data() {
        return {
            isLoading: false,
            verpackungTyp: null,
            verpackung: null,
            isKombi: false,
            ruleset: null
        }
    },

    created() {
        this.loadVerpackung();
    },

    methods: {
        loadVerpackung() {
            this.isLoading = true;
            this.verpackungService.loadVerpackung(this.verpackungId, false)
                .then((result) => {
                    this.verpackungTyp = result.verpackungTyp;
                    this.verpackung = this.verpackungTyp.getVerpackungById(this.verpackungId);
                    console.log('this.verpackung', this.verpackung.isRated());
                    this.isKombi = this.verpackung.verpackungKombi !== null;
                    this.ruleset = result.ruleset;
                    this.isLoading = false;
                })
                .catch((e) => { console.error(e); this.isLoading = false; })
        },
        getKomponenteTitle(komponente) {
            if(komponente.key === 'custom') {
                const komponenteName = this.ruleset.getKomponentenName(komponente.nameKey);
                if (!komponenteName) {
                    return komponente.nameKey;
                }
                if (komponenteName.customInput === true) {
                    return komponente.customName;
                }
                return komponenteName.name;
            }
            const verpackunTyp = this.ruleset.getVerpackungTyp(this.verpackung.verpackungTypKey);
            const verpackungTypGruppe = this.ruleset.getVerpackungTypGruppe(verpackunTyp.verpackungTypGruppe);
            if(!verpackungTypGruppe) {
                return komponente.key;
            }
            const verpackungTypGruppeKomponente = verpackungTypGruppe.komponenten[komponente.key] || null;
            return verpackungTypGruppeKomponente ? verpackungTypGruppeKomponente.title : komponente.key;
        },
        getWertstoffTitle(wertstoff) {
            return wertstoff.wertstoff;
        },
        getWertstoffColor(wertstoff) {
            return this.ruleset.getWertstoffColorName(wertstoff.color);
        }
    }
});