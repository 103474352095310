import template from "./register-optin-page.html.twig";
import { Form } from "vee-validate";

Packlab.Component.register('customer-register-optin-page', {
    template,

    components: { Form },
    inject: [ 'apiClient' ],

    data() {
        return {
            password: ''
        }
    },

    methods: {
        onOptin(e) {
            e.preventDefault();
            this.$refs.passwordForm.validatePassword(this.$refs.form)
                .then((result) => {
                    this.apiClient.customerOptin(
                        this.$route.query.token,
                        this.$route.query.hash,
                        result.password
                    )
                        .then(() => {
                            window.location.href = "/";
                        })
                        ;
                });
        }
    }
});
