import template from "./buy-product-btn.html.twig";

Packlab.Component.register('buy-product-btn', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            showBtn: false
        }
    },

    created() {
        this.apiClient.getContext().then((context) => {
             if(!context.contract) {
                 return;
             }
             if(context.contract.unlimitedCertificates) {
                 return;
             }
             this.showBtn = true;
        });
    }
});