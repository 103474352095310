import VueAdapter from "./vue/vue.adapter";

export default class Application {
    /**
     *
     * @param {Bottle} container
     * @param {Object} config
     */
    constructor(container, config) {
        const noop = () => {};
        /**
         *
         * @type {Bottle}
         * @private
         */
        this._container = container;
        this._config = config;
        this._vueAdapter = new VueAdapter(this);
        this._container.service('factory', noop);
        this._container.service('service', noop);
        this._locale = localStorage.getItem('packlabLocale') || 'de-DE';
    }

    addFactory(name, factory) {
        this._container.factory(`factory.${name}`, factory.bind(this));
    }

    addServiceFactory(name, factory) {
        this._container.factory(`service.${name}`, factory.bind(this));
    }

    /**
     *
     * @returns {Bottle}
     */
    getContainer(containerName) {
        const containerNames = this._container.list();
        if (containerNames.indexOf(containerName) !== -1) {
            return this._container.container[containerName];
        }
        return this._container.container;
    }

    getLocale() {
        return this._locale;
    }

    setLocale(locale) {
        localStorage.setItem('packlabLocale', locale);
        this._locale = locale;
    }

    start() {
        this._vueAdapter.startVue();
    }

    getConfig() {
        return this._config;
    }
}