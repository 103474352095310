import { isEmpty } from "../../../../../utils/utils";

Packlab.Component.extend('address-country-select', 'select-field', {

    inject: [ 'apiClient' ],

    data() {
        return {
            countries: null
        }
    },

    created() {
        this.loadCountries();
    },

    methods: {
        loadCountries() {
            this.isLoading = true;
            this.countries = [];
            this.apiClient.getCountries()
                .then((countries) => {
                    let defaultValue = null;
                    this.countries.push({
                        value: '',
                        label: this.$t('Wählen Sie ein Land aus'),
                        iso: null
                    });
                    for(let c in countries) {
                        this.countries.push({
                            value: countries[c].id,
                            label: countries[c].name,
                            iso: countries[c].iso
                        });
                    }
                    for(let c in this.countries) {
                        if(this.modelValue?.length === 2 && this.modelValue == this.countries[c].iso || isEmpty(this.modelValue) && this.countries[c].iso === 'DE') {
                            defaultValue = this.countries[c].value;
                            break;
                        }
                    }
                    this.loadSelectOptions(this.countries);
                    if(defaultValue) {
                        this.$emit('update:modelValue', defaultValue);
                        this.$emit('change', defaultValue);
                    }
                })
                .finally(() => { this.isLoading = false; });
        }
    }
});
