Packlab.Component.extend('yes-no-select', 'select-field', {
    created() {
        this.loadSelectOptions([
            {
                value: 40,
                label: this.$t('Ja')
            },
            {
                value: 41,
                label: this.$t('Nein')
            }
        ]);
    }
});
