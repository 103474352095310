import template from "./customer-account-edit-page.html.twig";

Packlab.Component.register('customer-account-edit-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            customerAccount: {},
            isLoading: false,
            isSaving: false,
        }
    },

    computed: {
        customerAccountId() {
            return this.$route.params.customerAccountId;
        }
    },

    created() {
        this.loadCustomerAccount();
    },

    methods: {
        loadCustomerAccount() {
            this.isLoading = true;
            this.apiClient.getCustomerAccount(this.customerAccountId)
                .then((customerAccount) => {
                    this.customerAccount = customerAccount;
                    this.isLoading = false;
                });
        },
        updateCustomerAccount() {
            this.isSaving = true;
            this.$refs.customerAccountForm.validate().then((result) => {
                if(!result.valid) {
                    this.isSaving = false;
                    return;
                }

                this.apiClient.updateCustomerAccount(this.customerAccountId, this.customerAccount)
                    .then(() => {
                        this.$router.push({ path: `/accounts/${this.customerAccountId}` });
                        this.isSaving = false;
                    })
                    .catch(({errors}) => {
                        this.$refs.customerAccountForm.notifyFormErrors(errors);
                        this.isSaving = false;
                    });
            });
        }
    }
});
