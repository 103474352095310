import template from "./customer-account-table.html.twig";

Packlab.Component.register('customer-account-table', {
    template,

    inject: [ 'apiClient' ],
    mixins: [ 'enum' ],

    data() {
        return {
            isLoading: false,
            customerAccounts: null
        }
    },

    computed: {
        columns() {
            return [
                {
                    field: 'email',
                    label: this.$t('E-Mail-Adresse')
                },
                {
                    field: 'salutationEnum',
                    label: this.$t('Anrede')
                },
                {
                    field: 'firstname',
                    label: this.$t('Vorname')
                },
                {
                    field: 'lastname',
                    label: this.$t('Nachname')
                },
                {
                    field: 'membershipStatus',
                    label: this.$t('Status')
                }
            ];
        }
    },

    created() {
        this.loadCustomerAccounts();
    },

    methods: {
        loadCustomerAccounts() {
            this.isLoading = true;
            this.apiClient.getCustomerAccounts()
                .then((customerAccounts) => {
                    this.customerAccounts = customerAccounts;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        }
    }
});
