import {getUid, isEmpty} from "../../../utils/utils";
import FieldRuleResolver from "../../../utils/field-rule-resolver";
import ModelBase from "./model-base";

export default class KomponenteWertstoff extends ModelBase {
    constructor(komponente) {
        super();
        this.komponente = komponente;
        this.create();
    }

    load(json, ruleset) {
        const data = json.data || {};
        this.id = json.id;
        this.hauptwertstoff = json.hauptwertstoff;
        this.wertstoff = json.wertstoff;
        this.rezyclatquote = json.rezyclatquote;
        this.color = json.color;
        this.totalWeight = json.totalWeight;
        this.weight = json.weight;
        this.weightRatio = json.weightRatio;
        this.recyclebar = json.recyclebar;
        this.recyclableWeight = json.recyclableWeight;
        this.recyclableWeightRatio = json.recyclableWeightRatio;
        this.data = json.data;
        this.conditionalFields = json.conditionalFields;
        this.notRecyclableReasons = json.notRecyclableReasons;
    }

    create(options = {}) {
        this.id = null;
        this.hauptwertstoff = false;
        this.wertstoff = '';
        this.rezyclatquote = 0;
        this.color = '';
        this.totalWeight = 0;
        this.weight = 0;
        this.weightRatio = 0;
        this.data = {
            faserstoffanteil: 100,
            anteilFarbe: 1,
            glasEtikett: 0,
            wasserloeslicheBestandteile: 1.5,
            transmissionsgrad10: 41
        };
        this.conditionalFields = {};
        this.notRecyclableReasons = {};
    }

    getJson() {
        return {
            id: this.id,
            hauptwertstoff: this.hauptwertstoff,
            wertstoff: this.wertstoff,
            rezyclatquote: this.rezyclatquote,
            color: this.color,
            weight: this.weight,
            data: this.data,
            conditionalFields: this.conditionalFields
        };
    }

    getConditionData(ruleset, field, dataField) {
        if(field === 'wertstoff' && dataField === 'key') {
            return this.wertstoff;
        } else if(field === 'wertstoff') {
            const selectedWertstoff = ruleset.getWertstoff(this.wertstoff);
            if(!selectedWertstoff) {
                return null;
            }
            return selectedWertstoff[dataField] || null;
        }
        return null;
    }

    updateConditionalField(ruleset, field, values) {
        const fieldRuleResolver = new FieldRuleResolver(ruleset, values);
        let match = true;
        if(field === 'wasserloeslicheBestandteile') {
            match = (values.komponente.key || '') === 'korpus' || (values.komponente.default || false);
        }
        this.conditionalFields[field] = fieldRuleResolver.resolve('visibility', 'wertstoff', field) && match;
        return this.conditionalFields[field] || false;
    }

    validate(options = {}) {
        const result = {};
        if(isEmpty(this.wertstoff)) {
            result['wertstoff'] = this.wertstoff;
        }
        if(isEmpty(this.color)) {
            result['color'] = this.color;
        }
        if(this.weight <= 0) {
            result['weight'] = this.weight;
        }
        if(isEmpty(this.rezyclatquote) || this.rezyclatquote < 0 || this.rezyclatquote > 100) {
            result['rezyclatquote'] = this.rezyclatquote;
        }
        for(let fieldName in this.conditionalFields) {
            const isVisible = this.conditionalFields[fieldName];
            if(isVisible && this.data[fieldName] === null) {
                result[fieldName] = this.data[fieldName];
            }
        }
        return result;
    }
}