import template from "./av-vertrag-page.html.twig";

Packlab.Component.register('av-vertrag-page', {
    template,

    inject: [ 'apiClient' ],

    computed: {
        showTestBtn() {
            return Packlab.Config.testing === true;
        }
    },

    data() {
        return {
            isDownloading: false
        }
    },

    methods: {
        onDownloadAvVertrag() {
            this.apiClient.getAvVertrag()
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = `data:application/pdf;base64,${response.contents}`;
                    link.setAttribute('download', response.fileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .finally(() => { this.isDownloading = false; });
        },
        onOpenTestUrl() {
            this.apiClient.getContext()
                .then((context) => {
                    const url = Packlab.Config.apiUrl + `/packlab/av-vertrag/pdf-test?customerId=${context.customer.customerId}&customerAccountId=${context.customer.customerAccountId}`;
                    window.open(url, '_blank').focus();
                });
        }
    }
});