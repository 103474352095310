import { warn } from "../../utils/debug-utils";
import TemplateFactory from "./template.factory";

const componentRegistry = new Map();
const overrideRegistry = new Map();

let templatesResolved = false;

function getComponentRegistry() {
    return componentRegistry;
}

/**
 * @returns {Map}
 */
function getOverrideRegistry() {
    return overrideRegistry;
}

function resolveTemplates() {
    TemplateFactory.resolveTemplates();
    templatesResolved = true;
    return true;
}

/**
 * @param {String} name
 * @param {Object} [config={}]
 * @returns {Boolean|Object}
 */
function register(name, config = {}) {
    if (componentRegistry.has(name)) {
        warn(
            'ComponentFactory',
            `The component "${name}" is already registered. Please select a unique name for your component.`,
            config
        );
        return false;
    }

    config.name = name;

    if (config.template) {
        TemplateFactory.registerTemplate(name, config.template);
        delete config.template;
    } else if (!config.functional && typeof config.render !== 'function') {
        warn(
            'ComponentFactory',
            `The component "${config.name}" needs a template to be functional.`,
            'Please add a "template" property to your component definition',
            config
        );
        return false;
    }

    componentRegistry.set(name, config);

    return config;
}

/**
 * @param {String} name
 * @param {String} extendComponentName
 * @param {Object} config
 * @returns {Object} config
 */
function extend(name, extendComponentName, config) {
    if (config.template) {
        TemplateFactory.extendTemplate(name, extendComponentName, config.template);
        delete config.template;
    } else {
        TemplateFactory.extendTemplate(name, extendComponentName);
    }

    config.name = name;
    config.extends = extendComponentName;

    componentRegistry.set(name, config);

    return config;
}

/**
 * @param name
 * @param config
 * @param overrideIndex
 * @returns {*}
 */
function override(name, config, overrideIndex = null) {
    config.name = name;
    if (config.template) {
        TemplateFactory.registerTemplateOverride(name, config.template, overrideIndex);
        delete config.template;
    }

    const overrides = overrideRegistry.get(name) || [];

    if (overrideIndex !== null && overrideIndex >= 0 && overrides.length > 0) {
        overrides.splice(overrideIndex, 0, config);
    } else {
        overrides.push(config);
    }

    overrideRegistry.set(name, overrides);

    return config;
}

/**
 * @param name
 * @returns {string}
 */
function getTemplate(name) {
    if (!templatesResolved) {
        resolveTemplates();
    }
    return TemplateFactory.getRenderedTemplate(name);
}

/**
 * @param name
 * @param skipTemplate
 * @returns {*}
 */
function build(name, skipTemplate = false) {
    if (!componentRegistry.has(name)) {
        return false;
    }

    if (!templatesResolved) {
        resolveTemplates();
    }

    let config = Object.create(componentRegistry.get(name));

    if (config.extends) {
        const extendComp = build(config.extends, true);

        if (extendComp) {
            config.extends = extendComp;
        } else {
            delete config.extends;
        }
    }

    if(config.mixins) {
        for(let m in config.mixins) {
            if(typeof config.mixins[m] == "string") {
                config.mixins[m] = Packlab.Mixin.getByName(config.mixins[m]);
            }
        }
    }

    if (overrideRegistry.has(name)) {
        const overrides = overrideRegistry.get(name);

        convertOverrides(overrides).forEach((overrideComp) => {
            const comp = Object.create(overrideComp);

            comp.extends = Object.create(config);
            config = comp;
        });
    }

    const superRegistry = buildSuperRegistry(config);

    if (isNotEmptyObject(superRegistry)) {
        const inheritedFrom = isAnOverride(config)
            ? `#${name}`
            : config.extends.name;

        config.methods = { ...config.methods, ...addSuperBehaviour(inheritedFrom, superRegistry) };
    }

    if (skipTemplate !== true) {
        config.template = getTemplate(name);
    } else {
        delete config.template;
    }

    return config;
}

/**
 * @param {Object} overrides
 * @returns {Object}
 */
function convertOverrides(overrides) {
    return overrides
        .reduceRight((acc, overrideComp) => {
            if (acc.length === 0) {
                return [overrideComp];
            }

            const previous = acc.shift();

            Object.entries(overrideComp).forEach(([prop, values]) => {
                if (previous.hasOwnProperty(prop)) {
                    if (typeof values === 'object') {
                        Object.entries(values).forEach(([methodName, methodFunction]) => {
                            if (!previous[prop].hasOwnProperty(methodName)) {
                                // move the function over
                                previous[prop][methodName] = methodFunction;
                                delete overrideComp[prop][methodName];
                            }
                        });
                    }
                } else {
                    previous[prop] = values;
                    delete overrideComp[prop];
                }
            });

            return [...[overrideComp], previous, ...acc];
        }, []);
}

/**
 * @param {Object} config
 * @returns {Object}
 */
function buildSuperRegistry(config) {
    let superRegistry = {};

    ['computed', 'methods'].forEach((methodOrComputed) => {
        if (!config[methodOrComputed]) {
            return;
        }

        const methods = Object.entries(config[methodOrComputed]);

        methods.forEach(([name, method]) => {
            if (methodOrComputed === 'computed' && typeof method === 'object') {
                Object.entries(method).forEach(([cmd, func]) => {
                    const path = `${name}.${cmd}`;

                    superRegistry = updateSuperRegistry(superRegistry, path, func, methodOrComputed, config);
                });
            } else {
                superRegistry = updateSuperRegistry(superRegistry, name, method, methodOrComputed, config);
            }
        });
    });

    return superRegistry;
}

function updateSuperRegistry(superRegistry, methodName, method, methodOrComputed, config) {
    const superCallPattern = /\.\$super/g;
    const methodString = method.toString();
    const hasSuperCall = superCallPattern.test(methodString);

    if (!hasSuperCall) {
        return superRegistry;
    }

    if (!superRegistry.hasOwnProperty(methodName)) {
        superRegistry[methodName] = {};
    }

    const overridePrefix = isAnOverride(config) ? '#' : '';

    superRegistry[methodName] = resolveSuperCallChain(config, methodName, methodOrComputed, overridePrefix);

    return superRegistry;
}

/**
 * @param {String} inheritedFrom
 * @param {Object} superRegistry
 * @returns {Object}
 */
function addSuperBehaviour(inheritedFrom, superRegistry) {
    return {
        $super(name, ...args) {
            this._initVirtualCallStack(name);

            const superStack = this._findInSuperRegister(name);
            const superFuncObject = superStack[this._virtualCallStack[name]];

            this._virtualCallStack[name] = superFuncObject.parent;

            const result = superFuncObject.func.bind(this)(...args);

            // reset the virtual call-stack
            if (superFuncObject.parent) {
                this._virtualCallStack[name] = this._inheritedFrom();
            }

            return result;
        },
        _initVirtualCallStack(name) {
            // if there is no virtualCallStack
            if (!this._virtualCallStack) {
                this._virtualCallStack = { name };
            }

            if (!this._virtualCallStack[name]) {
                this._virtualCallStack[name] = this._inheritedFrom();
            }
        },
        _findInSuperRegister(name) {
            return this._superRegistry()[name];
        },
        _superRegistry() {
            return superRegistry;
        },
        _inheritedFrom() {
            return inheritedFrom;
        }
    };
}

/**
 * @param {Object} config
 * @param {String} methodName
 * @param {String} methodsOrComputed
 * @param {String} overridePrefix
 * @returns {Object}
 */
function resolveSuperCallChain(config, methodName, methodsOrComputed = 'methods', overridePrefix = '') {
    const extension = config.extends;

    if (!extension) {
        return {};
    }

    const parentName = `${overridePrefix}${extension.name}`;
    let parentsParentName = extension.extends ? `${overridePrefix}${extension.extends.name}` : null;

    if (parentName === parentsParentName) {
        if (overridePrefix.length > 0) {
            overridePrefix = `#${overridePrefix}`;
        }

        parentsParentName = `${overridePrefix}${extension.extends.name}`;
    }

    const methodFunction = findMethodInChain(extension, methodName, methodsOrComputed);

    const parentBlock = {};
    parentBlock[parentName] = {
        parent: parentsParentName,
        func: methodFunction
    };

    const resolvedParent = resolveSuperCallChain(extension, methodName, methodsOrComputed, overridePrefix);

    return {
        ...resolvedParent,
        ...parentBlock
    };
}

/**
 * @param {Object} extension
 * @param {String} methodName
 * @param {String} methodsOrComputed
 * @returns {Object} superCallChain
 */
function findMethodInChain(extension, methodName, methodsOrComputed) {
    const splitPath = methodName.split('.');

    if (splitPath.length > 1) {
        return resolveGetterSetterChain(extension, splitPath, methodsOrComputed);
    }

    if (extension[methodsOrComputed] && extension[methodsOrComputed][methodName]) {
        return extension[methodsOrComputed][methodName];
    }

    if (extension.extends) {
        return findMethodInChain(extension.extends, methodName, methodsOrComputed);
    }

    return null;
}

/**
 * @param {Object} extension
 * @param {string[]} path
 * @param {String} methodsOrComputed
 * @returns {Object} superCallChain
 */
function resolveGetterSetterChain(extension, path, methodsOrComputed) {
    const [methodName, cmd] = path;

    if (!extension[methodsOrComputed]) {
        return findMethodInChain(extension.extends, methodName, methodsOrComputed);
    }

    if (!extension[methodsOrComputed][methodName]) {
        return findMethodInChain(extension.extends, methodName, methodsOrComputed);
    }

    return extension[methodsOrComputed][methodName][cmd];
}

/**
 * @param {Object} config
 * @returns {Boolean}
 */
function isAnOverride(config) {
    if (!config.extends) {
        return false;
    }

    return config.extends.name === config.name;
}

/**
 * @param {Object} obj
 * @returns {Boolean}
 */
function isNotEmptyObject(obj) {
    return (Object.keys(obj).length !== 0 && obj.constructor === Object);
}

export default {
    register,
    extend,
    override,
    build,
    getTemplate,
    getComponentRegistry,
    getOverrideRegistry,
    resolveTemplates
};
