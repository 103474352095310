import template from "./verpackung-gruppe-editor.html.twig";

Packlab.Component.register('verpackung-gruppe-editor', {
    template,

    props: {
        verpackung: {
            type: Object,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        }
    }
});
