Packlab.Mixin.register('placeholder', {
    methods: {
        replacePlaceholders(text, placeholders) {
            for (let name in placeholders) {
                text = text.replaceAll('{' + name + '}', placeholders[name]);
            }
            return text;
        }
    }
});
