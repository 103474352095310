Packlab.Component.extend('verpackung-materialart-select', 'select-field', {
    inject: [ 'apiClient' ],

    props: {
        ruleset: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            wizardSelectOptions: []
        }
    },

    created() {
        const lucidMaterialgruppen = this.ruleset.getLucidMaterialgruppen();
        for(let g in lucidMaterialgruppen) {
            this.wizardSelectOptions.push({
                value: g,
                label: lucidMaterialgruppen[g].title
            });
        }
        this.loadSelectOptions(this.wizardSelectOptions);
    }
});
