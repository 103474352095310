import template from "./customer-account-view-page.html.twig";

Packlab.Component.register('customer-account-view-page', {
    template,

    mixins: [ 'placeholder' ],
    inject: [ 'apiClient' ],

    data() {
        return {
            customerAccount: {},
            isLoading: false,
            showDeleteConfirmModal: false,
            isDeleting: false
        }
    },

    computed: {
        customerAccountId() {
            return this.$route.params.customerAccountId;
        }
    },

    created() {
        this.loadCustomerAccount();
    },

    methods: {
        loadCustomerAccount() {
            this.isLoading = true;
            this.apiClient.getCustomerAccount(this.customerAccountId)
                .then((customerAccount) => {
                    this.customerAccount = customerAccount;
                    this.isLoading = false;
                });
        },
        deleteCustomerAccount() {
            this.isDeleting = true;
            this.apiClient.deleteCustomerAccount(this.customerAccountId)
                .finally(() => {
                    this.$refs.deleteConfirmModal.hide();
                    this.isDeleting = false;
                    this.$router.replace('/accounts');
                });
        }
    }
});
