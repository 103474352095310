
import {isEmpty} from "./utils";
export default class FieldRuleResolver {
    constructor(ruleset, values) {
        this.ruleset = ruleset;
        this.fieldRules = ruleset.getFieldRules();
        this.values = values;
    }

    resolve(ruleType, object, field) {
        const fieldConditions = this._getFieldConditions(ruleType, object, field);

        if(fieldConditions.length === 0) {
            return false;
        }

        for(let c in fieldConditions) {
            if(!this._matchCondition(fieldConditions[c].condition)) {
                return false;
            }
        }

        return true;
    }

    _getFieldConditions(ruleType, object, field) {
        return Array.from(this.fieldRules).filter((fieldRule) => { return fieldRule.type === ruleType && fieldRule.object === object && fieldRule.field === field; });
    }

    _getConditionObjectValue(condition) {
        const conditionObject = this.values[condition.object] || null;
        if(conditionObject === null) {
            return null;
        }
        if(condition.data) {
            if(typeof conditionObject.getConditionData !== "function") {
                return null;
            }
            return conditionObject.getConditionData(this.ruleset, condition.field || null, condition.data);
        }
        return conditionObject[condition.field] || null;
    }

    _matchCondition(condition) {
        switch (condition.type)  {
            case 'equals':
                return this._matchEqualsCondition(condition);
            case 'equalsAny':
                return this._matchEqualsAnyCondition(condition);
            case 'and':
                return this._matchAndCondition(condition);
            default:
                console.error('unknown condition', condition);
                break;
        }
    }

    _matchEqualsCondition(condition) {
        const conditionValue = this._getConditionObjectValue(condition);
        return conditionValue == condition.value;
    }

    _matchEqualsAnyCondition(condition) {
        const conditionObjectValue = this._getConditionObjectValue(condition);
        let conditionValue = condition.value;
        if(isEmpty(conditionObjectValue) || isEmpty(conditionValue)) {
            return false;
        }
        if(!Array.isArray(conditionValue) && typeof conditionValue === "object") {
            conditionValue = Object.values(conditionObjectValue);
        } else if(!Array.isArray(conditionValue)) {
            conditionValue = [ conditionValue ];
        }
        return conditionValue.indexOf(conditionObjectValue) !== -1;
    }

    _matchAndCondition(condition) {
        for(let c in condition.conditions) {
            if(!this._matchCondition(condition.conditions[c])) {
                return false;
            }
        }
        return true;
    }
}