import template from "./verpackung-check-page.html.twig";

Packlab.Component.register('verpackung-check-page', {
    template,

    inject: [ 'verpackungService' ],

    computed: {
        verpackungId() {
            return this.$route.params.verpackungId;
        }
    },

    data() {
        return {
            isLoading: false,
            verpackung: null
        }
    },

    created() {
        this.loadVerpackung();
    },

    methods: {
        loadVerpackung() {
            this.isLoading = true;
            this.verpackungService.loadVerpackung(this.verpackungId, false)
                .then((result) => {
                    this.verpackung = result.verpackungTyp.getVerpackungById(this.verpackungId);
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; })
        }
    }

});