import template from "./customer-account-create-page.html.twig";

Packlab.Component.register('customer-account-create-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            customerAccount: {},
            isLoading: false
        }
    },

    created() {
        this.isLoading = true;
        this.apiClient.getContext().then((context) => {
            if((context?.contract?.usedCustomerAccounts || 1) >= (context?.contract?.totalCustomerAccounts || 1)) {
                this.$router.replace('/accounts');
            }
        }).finally(() => this.isLoading = false );
    },

    methods: {
        createCustomerAccount() {
            this.isLoading = true;
            this.$refs.customerAccountForm.validate().then((result) => {
                if(!result.valid) {
                    this.isLoading = false;
                    return;
                }

                this.apiClient.createCustomerAccount(this.customerAccount)
                    .then(() => {
                        this.$router.push({ path: `/accounts` });
                        this.isLoading = false;
                    })
                    .catch(({errors}) => {
                        this.$refs.customerAccountForm.notifyFormErrors(errors);
                        this.isLoading = false;
                    });
            });
        }
    }
});
