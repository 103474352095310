import template from "./customer-account-recover-page.html.twig";
import {ErrorMessage, Field, Form} from "vee-validate";

Packlab.Component.register('customer-account-recover-page', {
    template,

    mixins: [ 'form-validation', 'notifications' ],
    inject: [ 'apiClient' ],
    components: {Form, Field, ErrorMessage},

    data() {
        return {
            isSaving: false,
            email: ''
        };
    },

    methods: {
        sendPassword(e) {
            this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    return;
                }

                this.isSaving = true;
                this.apiClient.sendCustomerAccountRecoverMail(this.email)
                    .then(() => {
                        this.publishInfo('success', this.$t('Sie erhalten eine E-Mail mit einem Link zum zurücksetzen Ihres Passwortes. Sollten Sie keine E-Mail erhalten, prüfen Sie Ihren Spam-Ordner'));
                        this.email = '';
                        this.isSaving = false;
                    })
                    .catch(({ errors }) => {
                        this.isSaving = false;
                        errors.forEach((error) => {
                            if(error.status == 404) {
                                this.publishError(404, this.$t('Die E-Mail-Adresse konnte keinem Zugangskonto zugeordnet werden.'));
                            } else {
                                this.puslishError(error);
                            }
                        });
                    });
            });
        }
    }
});