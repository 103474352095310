import template from "./verpackung-zertifikat-checkout-page.html.twig";
import { Form, Field, ErrorMessage } from "vee-validate";

Packlab.Component.register('verpackung-zertifikat-checkout-page', {
    template,
    components: { Form, Field, ErrorMessage },

    inject: [ 'apiClient' ],
    mixins: [ 'form-validation' ],

    computed: {
        objectType() {
            return this.$route.params.objectType;
        },
        objectId() {
            return this.$route.params.objectId;
        },
        productNumber() {
            return this.$route.params.productNumber;
        }
    },


    created() {

    },

});
