import template from "./verpackung-export-btn.html.twig";

Packlab.Component.register('verpackung-export-btn', {
    template,

    inject: [ 'apiClient' ],

    props: {
        objectType: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isExporting: false
        }
    },

    methods: {
        onExport() {
            const promise = this._startExport();
            if(!promise) {
                return;
            }
            this.isExporting = true;
            promise.then((json) => {
                const jsonStr = JSON.stringify(json, undefined, 2);
                const element = document.createElement('a');
                element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(jsonStr));
                element.setAttribute('download', this._getFilename());
                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();
                document.body.removeChild(element);
            })
            .finally(() => this.isExporting = false);
        },
        _startExport() {
            if(this.objectType == 'verpackung') {
                return this.apiClient.exportVerpackungen();
            } else if(this.objectType == 'all') {
                return this.apiClient.exportAllVerpackungen();
            } else if(this.objectType == 'verpackung_kombi') {
                return this.apiClient.exportverpackungKombis();
            } else {
                console.error('unknown object-type: ', this.objectType);
                return null;
            }
        },
        _getFilename() {
            if(this.objectType == 'verpackung') {
                return this.$t('Verpackungen') + '.json';
            } else if(this.objectType == 'verpackung_kombi') {
                return this.$t('Kombinationsverpackungen') + '.json';
            } else {
                return this.$t('Export') + '.json';
            }
        }
    }
});