import template from "./customer-company-form.html.twig";

Packlab.Component.register('customer-company-form', {
    template,
    mixins: [ 'form-validation' ],

    props: {
        customer: {
            type: Object,
            required: true
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        },
    },


});
