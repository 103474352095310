export default class ConditionResolver {
    constructor(values) {
        this.values = values;
    }

    resolveCondition(condition) {
        const conditionType = condition?.condition || null;
        const conditionRules = condition?.rules || [];

        if(!condition || conditionRules.length === 0) {
            return true;
        }

        switch (conditionType.toLowerCase()) {
            case 'and':
                return this._resolveAndCondition(conditionRules);
            case 'or':
                return this._resolveOrCondition(conditionRules);
        }

        return false;
    }

    _resolveAndCondition(rules) {
        for(let r in rules) {
            if(!this._resolveRule(rules[r])) {
                return false;
            }
        }
        return true;
    }

    _resolveOrCondition(rules) {
        for(let r in rules) {
            if(this._resolveRule(rules[r])) {
                return true;
            }
        }
        return false;
    }

    _resolveRule(rule) {
        const ruleField = rule.field || null;
        const ruleOperator = rule.operator || null;
        const ruleValue = rule.value || undefined;
        if(!ruleField || !ruleOperator || ruleValue === undefined) {
            return false;
        }
        if(typeof this.values[ruleField] === 'undefined') {
            return false;
        }
        switch (ruleOperator.toLocaleString()) {
            case 'in':
                return this._resolveInRule(ruleField, ruleValue);
            case 'not_in':
                return !this._resolveInRule(ruleField, ruleValue);
            case 'equal':
                return this._resolveEqualRule(ruleField, ruleValue);
            case 'not_equal':
                return !this._resolveEqualRule(ruleField, ruleValue);
        }
        return false;
    }

    _resolveInRule(ruleField, ruleValue) {
        const value = this.values[ruleField];
        if(typeof ruleValue !== 'object') {
            ruleValue = [ ruleValue ];
        }

        return ruleValue.indexOf(value) !== -1;
    }

    _resolveEqualRule(ruleField, ruleValue) {
        const value = this.values[ruleField];
        if(typeof ruleValue === 'object') {
            ruleValue = ruleValue[0];
        }

        return ruleValue.toString().toLowerCase() === value.toString().toLowerCase();
    }
}