import Bottle from "bottlejs";
import config from "../../../config/config.inc";

import vueStore from "./vue/vue.store";
import TemplateFactory from "./factory/template.factory";
import ComponentFactory from "./factory/component.factory";
import MixinFactory from "./factory/mixin.factory";
import Application from "./application";
import mitt from 'mitt'
import ApiClient from "../utils/api-client";
import VerpackungService from "../utils/verpackung-service";
import VerpackungChecker from "../utils/verpackung-checker";
import Whitelabel from "../utils/whitelabel";

Bottle.config = { strict: false };
const container = new Bottle();
const emiter = mitt();
const application = new Application(container, config);
const apiClient = new ApiClient(config);

application.addServiceFactory('apiClient', (container) => {
    return apiClient;
});
application.addServiceFactory('verpackungService', (container) => {
    return new VerpackungService(container.apiClient);
});
application.addServiceFactory('verpackungChecker', (container) => {
    return new VerpackungChecker(container.apiClient);
});
container.service('vueStore', (container) => {
  return vueStore;
});

application.addServiceFactory('whitelabel', (container) => {
    return new Whitelabel(container.apiClient, vueStore);
});

window.onunhandledrejection = event => {
    apiClient.logError(event.type || 'onunhandledrejection', {
        message: event.reason.message || '',
        stack: event.reason.stack || '',
        url: window.location.href
    });
};
window.onerror = (msg, url, lineNo, linePos, error) => {
    apiClient.logError('onerror', error.name || 'error', {
        message: error.message || msg,
        msg: msg,
        stack: error.stack || '',
        url: window.location.href
    });
};

const Packlab = {
    Config: config,
    Container: container,
    Application: application,
    Emitter: emiter,

    Template: {
        registerTemplate: TemplateFactory.registerTemplate,
        extendTemplate: TemplateFactory.extendTemplate,
        registerTemplateOverride: TemplateFactory.registerTemplateOverride,
        getRenderedTemplate: TemplateFactory.getRenderedTemplate,
        resolveTemplates: TemplateFactory.resolveTemplates,
        clearTwigCache: TemplateFactory.clearTwigCache,
        getTwigCache: TemplateFactory.getTwigCache,
        disableTwigCache: TemplateFactory.disableTwigCache,
        getTemplateRegistry: TemplateFactory.getTemplateRegistry,
        getNormalizedTemplateRegistry: TemplateFactory.getNormalizedTemplateRegistry,
        getTemplateOverrides: TemplateFactory.getTemplateOverrides
    },

    Component: {
        register: ComponentFactory.register,
        extend: ComponentFactory.extend,
        override: ComponentFactory.override,
        build: ComponentFactory.build,
        getTemplate: ComponentFactory.getTemplate,
        getComponentRegistry: ComponentFactory.getComponentRegistry,
        getOverrideRegistry: ComponentFactory.getOverrideRegistry,
        resolveTemplates: ComponentFactory.resolveTemplates
    },

    Mixin: {
        register: MixinFactory.register,
        getByName: MixinFactory.getByName,
        getMixinRegistry: MixinFactory.getMixinRegistry
    },

    Store: vueStore
};

window.Packlab = Packlab;
export default Packlab;