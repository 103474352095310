export default class Whitelabel {
    /**
     * @param {ApiClient} apiClient
     * @param $store
     */
    constructor(apiClient, $store) {
        this.apiClient = apiClient;
        this.$store = $store;
        Packlab.Emitter.on('context.loaded', this._onContextLoaded.bind(this));
    }

    resolveWhitelabel($route) {
        return new Promise((resolve) => {
            const whitelabelKey = $route?.params?.whitelabelKey || null;
            const whitelabel = this.$store.state.whitelabel;
            if(whitelabelKey === null) {
                resolve({ loaded: false });
                return;
            }
            if(whitelabel && whitelabel.key == whitelabelKey) {
                resolve({ loaded: false });
                return;
            }
            this.apiClient.getWhitelabel(whitelabelKey).then((result) => {
                resolve({ loaded: true, whitelabel: result.whitelabel });
                this._resolveWhitelabel(result.whitelabel);
            });
        });
    }

    getWhitelabel() {
        return this.$store.getters.getWhitelabel;
    }

    _onContextLoaded({ context }) {
        if(context.whitelabel) {
            this._resolveWhitelabel(context.whitelabel);
        }
    }

    _resolveWhitelabel(whitelabel, emitEvent = true) {
        this.$store.dispatch('setWhitelabel', whitelabel);
        if(emitEvent) {
            Packlab.Emitter.emit('whitelabel.loaded', whitelabel);
        }
    }
}