import {isEmpty} from "../../utils/utils";

Packlab.Mixin.register('verpackung', {
    methods: {
        getVerpackungKomponentenWertstoffDataFieldValues(verpackungKomponentenWertstoff) {
            const dataFields = [];
            for(let key in verpackungKomponentenWertstoff.conditionalFields) {
                if(!verpackungKomponentenWertstoff.conditionalFields[key] || typeof verpackungKomponentenWertstoff.data[key] === 'undefined') {
                    continue;
                }
                const value = verpackungKomponentenWertstoff.data[key];
                switch (key) {
                    case 'anteilFarbe':
                        dataFields.push({
                            title: this.$t('Anteil Farbe'),
                            value,
                            formattedValue: this.$n(value, 'percent3') + ' %',
                        })
                        break;
                    case 'faserstoffanteil':
                        dataFields.push({
                            title: this.$t('Faserstoffanteil'),
                            value,
                            formattedValue: this.$n(value, 'integer') + ' %'
                        })
                        break;
                    case 'glasEtikett':
                        dataFields.push({
                            title: this.$t('zu wie viel % ist die Glasoberfläche von einem Etikett bedeckt'),
                            value,
                            formattedValue: this.$n(value, 'percent') + ' %'
                        })
                        break;
                    case 'wasserloeslicheBestandteile':
                        dataFields.push({
                            title: this.$t('Wie hoch ist der Anteil an wasserlöslichen Bestandteilen in %'),
                            value,
                            formattedValue: this.$n(value, 'percent') + ' %'
                        })
                        break;
                    case 'transmissionsgrad10':
                        dataFields.push({
                            title: this.$t('Der Transmissionsgrad beträgt mind. 10%?'),
                            value,
                            formattedValue: 'TODO: transmissionsgrad10'
                        })
                        break;
                }
            }
            return dataFields;
        }
    }
});
