export default [
    { name: 'index', path: '/:whitelabelKey?', component: "index-page" },
    { name: 'test', path: '/:whitelabelKey?/test', component: "test-page" },

    { name: 'customer-login', path: '/:whitelabelKey?/login', component: "customer-login-page" },
    { name: 'backoffice-login', path: '/:whitelabelKey?/backoffice-login/:contextToken/:secureToken', component: 'backoffice-login-page' },
    { name: 'customer-register', path: '/:whitelabelKey?/customer/register', component: 'customer-register-page' },
    { name: 'customer-register-confirm', path: '/:whitelabelKey?/customer/register/confirm', component: 'index-page', props: { registerConfirm: true } },
    { name: 'customer-register-optin', path: '/:whitelabelKey?/customer/register/optin', component: 'customer-register-optin-page' },

    { name: 'customer-profile', path: '/:whitelabelKey?/customer', component: 'customer-profile-page' },
    { name: 'customer-profile-edit', path: '/:whitelabelKey?/customer/edit', component: 'customer-profile-edit-page' },
    { name: 'customer-email-change', path: '/:whitelabelKey?/customer/email', component: 'customer-email-change-page' },
    { name: 'customer-password-change', path: '/:whitelabelKey?/customer/password', component: 'customer-password-change-page' },
    { name: 'customer-account-recover', path: '/:whitelabelKey?/password', component: 'customer-account-recover-page' },
    { name: 'customer-account-recover-password-change', path: '/:whitelabelKey?/password-recover', component: 'customer-account-recover-password-change-page' },
    { name: 'customer-settings', path: '/:whitelabelKey?/customer/settings', component: 'customer-settings-page' },

    { name: 'customer-account-list', path: '/:whitelabelKey?/accounts', component: 'customer-account-list-page' },
    { name: 'customer-account-crete', path: '/:whitelabelKey?/accounts/create', component: 'customer-account-create-page' },
    { name: 'customer-account-view', path: '/:whitelabelKey?/accounts/:customerAccountId', component: 'customer-account-view-page' },
    { name: 'customer-account-edit', path: '/:whitelabelKey?/accounts/:customerAccountId/edit', component: 'customer-account-edit-page' },

    { name: 'av-vertrag', path: '/:whitelabelKey?/av-vertrag', component: 'av-vertrag-page' },
    { name: 'contract-list', path: '/:whitelabelKey?/contracts', component: 'contract-list-page' },
    { name: 'contract-detail', path: '/:whitelabelKey?/contract/:contractId', component: 'contract-detail-page' },
    { name: 'contract-detail2', path: '/:whitelabelKey?/contract', component: 'contract-detail-page' },
    { name: 'contract-cancellation', path: '/:whitelabelKey?/contract/:contractId/cancel', component: 'contract-cancellation-page' },

    { name: 'order-list', path: '/:whitelabelKey?/orders', component: 'order-list-page' },
    { name: 'order-detail', path: '/:whitelabelKey?/orders/:orderId', component: 'order-detail-page' },

    { name: 'fonds-beitrag-overview', path: '/:whitelabelKey?/fonds', component: 'fonds-beitrag-overview-page' },
    { name: 'fonds-beitrag-list', path: '/:whitelabelKey?/fonds/verpackungen', component: 'fonds-beitrag-list-page' },
    { name: 'fonds-beitrag-edit', path: '/:whitelabelKey?/fonds/verpackungen/:verpackungId', component: 'fonds-beitrag-edit-page' },

    { name: 'verpackungen-list', path: '/:whitelabelKey?/verpackungen', component: 'verpackung-list-page' },
    { name: 'verpackung-create-wizard', path: '/:whitelabelKey?/verpackung/wizard', component: 'verpackung-create-wizard-page' },

    { name: 'verpackung-detail', path: '/:whitelabelKey?/verpackung/:verpackungId', component: 'verpackung-detail-page' },
    { name: 'verpackung-edit', path: '/:whitelabelKey?/verpackung/:verpackungId/edit', component: 'verpackung-edit-wizard-page' },
    { name: 'verpackung-check', path: '/:whitelabelKey?/verpackung/:verpackungId/check', component: 'verpackung-check-page' },
    { name: 'verpackung-zertifikat-cart', path: '/:whitelabelKey?/verpackung/:objectType/:objectId/cart', component: 'verpackung-zertifikat-cart-page' },
    { name: 'verpackung-zertifikat-checkout', path: '/:whitelabelKey?/verpackung/:objectType/:objectId/:productNumber/checkout', component: 'verpackung-zertifikat-checkout-page' },

    { name: 'verpackungen-gruppe-list', path: '/:whitelabelKey?/verpackung-gruppe', component: 'verpackung-gruppe-list-page' },
    { name: 'verpackungen-gruppe-detail', path: '/:whitelabelKey?/verpackung-gruppe/:verpackungGruppeId', component: 'verpackung-gruppe-detail-page' },
    { name: 'verpackungen-gruppe-edit', path: '/:whitelabelKey?/verpackung-gruppe/:verpackungGruppeId/edit', component: 'verpackung-gruppe-edit-page' },

    { name: 'verpackungen-kombi-list', path: '/:whitelabelKey?/kombi-verpackung', component: 'verpackung-kombi-list-page' },
    { name: 'verpackungen-kombi-detail', path: '/:whitelabelKey?/kombi-verpackung/:kombiVerpackungId', component: 'verpackung-kombi-detail-page' },
    { name: 'verpackungen-kombi-edit', path: '/:whitelabelKey?/kombi-verpackung/:kombiVerpackungId/edit', component: 'verpackung-kombi-edit-page' },

    { name: 'cart', path: '/:whitelabelKey?/cart', component: 'cart-page' },
    { name: 'cart-checkout', path: '/:whitelabelKey?/cart/:productNumber', component: 'cart-checkout-page' },

    { name: 'order_payment_complete', path: '/:whitelabelKey?/payment/:orderId/:orderDeepLinkCode/complete', component: 'order-payment-complete-page' },

    { name: 'static-hersteller-spezifikation', path: '/:whitelabelKey?/hilfe/hersteller-spezifikation', component: 'static-page', props: { textkey: 'hersteller-spezifikation' } },
    { name: 'static-glossar', path: '/:whitelabelKey?/hilfe/glossar', component: 'static-page' , props: { textkey: 'glossar' } },
    { name: 'static-abkuerzungen', path: '/:whitelabelKey?/hilfe/abkuerzungen', component: 'static-page', props: { textkey: 'abkuerzungen' } },
    { name: 'static-impressum', path: '/:whitelabelKey?/impressum', component: 'static-page', props: { textkey: 'impressum' } },
    { name: 'static-datenschutz', path: '/:whitelabelKey?/datenschutz', component: 'static-page', props: { textkey: 'datenschutz' } },
    { name: 'static-agb', path: '/:whitelabelKey?/agb', component: 'static-page', props: { textkey: 'agb' } },


    { path: '/:whitelabelKey?/404', component: 'error-404-page' },
    { path: '/:whitelabelKey?/:pathMatch(.*)*', redirect: '/404' }
];
