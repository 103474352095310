import template from "./verpackung-gruppe-komponente-wertstoff.html.twig";
import {getUid} from "../../../../utils/utils";

Packlab.Component.register('verpackung-gruppe-komponente-wertstoff', {
    template,

    mixins: [ 'form-validation' ],

    props: {
        wertstoff: {
            type: Object,
            required: true
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            uid: getUid()
        }
    },

    methods: {
        hasDataField(fieldName) {
            return typeof this.wertstoff.data[fieldName] !== 'undefined';
        },
        getIntVal(value) {
            if(value === 0 || value === undefined) {
                return 0;
            }
            return parseInt(value);
        },
        getFloatVal(value) {
            if(value === 0 || value === undefined) {
                return 0;
            }
            return parseFloat(value);
        }
    }
});