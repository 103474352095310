import template from "./packlab-wizard.html.twig";

Packlab.Component.register('packlab-wizard', {
    template,
    emits: [ 'beforeGoToNextStep' ],

    props: {
        steps: {
            type: Array,
            required: true
        },
        startStep: {
            type: String,
            required: true
        },
        showStepHeader: {
            type: Boolean,
            required: false,
            default: false
        },
        showPrevBtnCallback: {
            type: Function,
            required: false,
            default: null
        },
        disablePrevBtnCallback: {
            type: Function,
            required: false,
            default: null
        },
        showNextBtnCallback: {
            type: Function,
            required: false,
            default: null
        },
        disableNextBtnCallback: {
            type: Function,
            required: false,
            default: null
        },
        nextStepValidateHandler: {
            type: Function,
            required: false,
            default: null
        }
    },

    computed: {
        showPrevBtn() {
            if(this.showPrevBtnCallback) {
                return this.showPrevBtnCallback({ steps: this.steps, currentStep: this.currentStep, stepHistory: this.stepHistory });
            }
            return this.stepHistory.length > 0;
        },
        disablePrevBtn() {
            if(this.disablePrevBtnCallback) {
                return this.disablePrevBtnCallback({ steps: this.steps, currentStep: this.currentStep, stepHistory: this.stepHistory });
            }
            return false;
        },
        showNextBtn() {
            if(this.showNextBtnCallback) {
                return this.showNextBtnCallback({ steps: this.steps, currentStep: this.currentStep, stepHistory: this.stepHistory });
            }
            return this.steps[this.steps.length - 1].key !== this.currentStep;
        },
        disableNextBtn() {
            if(this.disableNextBtnCallback) {
                return this.disableNextBtnCallback({ steps: this.steps, currentStep: this.currentStep, stepHistory: this.stepHistory });
            }
            return false;
        }
    },

    data() {
        return {
            currentStep: this.startStep,
            stepHistory: [],
            isNextStepBtnLoading: false
        }
    },

    methods: {
        onGoToPrevStep() {
            this.currentStep = this.stepHistory.pop();
        },
        onGoToNextStep() {
            if(typeof this.nextStepValidateHandler === 'function') {
                const result = this.nextStepValidateHandler({ currentStep: this.currentStep });
                if(result === null) {
                    return;
                }
                if(typeof result === "string") {
                    this._goToNextStep(result);
                } else if(typeof result === "boolean") {
                    if(result) {
                        this._goToNextStep();
                    }
                } else if(typeof result === "object" && result instanceof Promise) {
                    this.isNextStepBtnLoading = true;
                    Promise.resolve(result)
                        .then((result) => {
                            if (result === false) {
                                this.isNextStepBtnLoading = false;
                                return;
                            }

                            let nextStep = typeof result === 'object' ? (result.nextStep || null) : null;
                            this._goToNextStep(nextStep);
                            this.isNextStepBtnLoading = false;
                        })
                        .catch(() => {
                            this.isNextStepBtnLoading = false;
                        });
                } else {
                    console.error('unknown result type', result);
                }
            } else {
                this._goToNextStep();
            }
        },
        _goToNextStep(nextStep = null) {
            if(nextStep) {
                const nextStepIndex = this.steps.findIndex((step) => { return step.key === nextStep });
                nextStep = this.steps[nextStepIndex] || null;
            } else {
                const currentStepIndex = this.steps.findIndex((step) => { return step.key === this.currentStep });
                nextStep = this.steps[currentStepIndex + 1] || null;
            }

            if(!nextStep) {
                return ;
            }
            this.stepHistory.push(this.currentStep);
            this.currentStep = nextStep.key;
        }
    }

});