import ModelBase from "./model-base";
import KomponenteWertstoff from "./komponente-wertstoff";

export default class KomponenteBase extends ModelBase {

    constructor(verpackung) {
        super();
        this.verpackung = verpackung;
        this.id = null;
        this.image = {};
        this.notRecyclableReasons = {};
        this.wertstoffe = [];
    }

    load(json, ruleset) {
        this.id = json.id;
        this.totalWeight = json.totalWeight;
        this.weight = json.weight;
        this.weightRatio = json.weightRatio;
        this.recyclableWeight = json.recyclableWeight;
        this.recyclableWeightRatio = json.recyclableWeightRatio;
        this.image = json.image;
        this.notRecyclableReasons = json.notRecyclableReasons;
        this.recyclebar = json.recyclebar;

        for(let w in json.wertstoffe) {
            const wertstoffJson = json.wertstoffe[w];
            const wertstoff = new KomponenteWertstoff(this);
            wertstoff.load(wertstoffJson, ruleset);
            this.wertstoffe.push(wertstoff);
        }
    }

    create(options = {}) {

    }

    validate(options = {}) {
        const result = {};
        const wertstoffeResult = {};
        const wertstoffe = options.wertstoffe === true;

        if(!wertstoffe) {
            return {};
        }

        if(this.wertstoffe.length === 0) {
            result['emptyWertstoffe'] = true;
        } else {
            for (let w in this.wertstoffe) {
                if (!this.wertstoffe[w].isValid(options)) {
                    const wertstoffResult = this.wertstoffe[w].validate(options);
                    if (Object.keys(wertstoffResult).length > 0) {
                        wertstoffeResult[this.wertstoffe[w].uid] = wertstoffResult;
                    }
                }
            }
            if(Object.keys(wertstoffeResult).length > 0) {
                result['wertstoffe'] = wertstoffeResult;
            }
        }

        return result;
    }

    hasWertstoff(wertstoffKey) {
        return this.wertstoffe.filter((wertstoff) => { return wertstoff.wertstoff === wertstoffKey; }).length > 0;
    }

    createWertstoff(wertstoffKey, hauptwertstoff = false) {
        const wertstoff = new KomponenteWertstoff(this);
        wertstoff.wertstoff = wertstoffKey;
        wertstoff.hauptwertstoff = hauptwertstoff;
        this.wertstoffe.push(wertstoff);
    }

    addDefaultWertstoff() {
        if(this.wertstoffe.length > 0) {
            return;
        }
        const wertstoff = new KomponenteWertstoff(this);
        this.wertstoffe.push(wertstoff);
    }

    getJson() {
        return {
            id: this.id,
            image: this.image,
            wertstoffe: this.wertstoffe.map((wertstoff) => { return wertstoff.getJson(); })
        };
    }
}