import template from "./verpackung-checker-verpackung-step.html.twig";

Packlab.Component.register('verpackung-checker-verpackung-step', {
    template,

    props: {
        verpackung: {
            type: Object,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        },
        checkerStep: {
            type: Object,
            required: true
        },
        checkerStepValues: {
            type: Object,
            required: true
        }
    },

    computed: {
        gutmaterial() {
            if(!this.ruleset) {
                return null;
            }
            return this.ruleset.getMaterialFraktion(this.verpackung.materialFraktion)?.gutmaterialHinweis || null;
        },
        schlechtmaterial() {
            if(!this.ruleset) {
                return null;
            }
            return this.ruleset.getMaterialFraktion(this.verpackung.materialFraktion)?.schlechtmaterialHinweis || null;
        },
        recyclinginfrastrukturNotRecyclable() {
            if(!this.verpackung) {
                return false;
            }
            const notRecyclableReasons = this.verpackung.notRecyclableReasons;
            return notRecyclableReasons.indexOf('recyclinginfrastruktur') >= 0;
        },
        recyclinginfrastruktur() {
            const materialFraktion = this.ruleset.getMaterialFraktion(this.verpackung.materialFraktion);
            if(!materialFraktion) {
                return null;
            }
            return this.ruleset.getInfrastruktur(materialFraktion?.recyclinginfrastruktur);
        },
        contactPhone() {
            return Packlab.Config.contactPhone;
        },
        contactMail() {
            return Packlab.Config.contactMail;
        },
        contactUrl() {
            return Packlab.Config.contactUrl;
        }
    },
});