import template from "./product-box.html.twig";

Packlab.Component.register('product-box', {
    template,

    mixins: [ 'placeholder' ],

    props: {
        product: {
            type: Object,
            required: true
        },
        showCheckoutBtn: {
            type: Boolean,
            required: false,
            default: true
        },
        getProductUrlCallback: {
            type: Function,
            required: false,
            default: null
        }
    },

    computed: {
        productImage() {
            if(this.product?.cover?.thumbnails) {
                const thumbnail = this.product?.cover?.thumbnails.filter((thumbnail) => { return thumbnail.meta.width === 400 && thumbnail.meta.height === 400; })[0] || null;
                if(thumbnail) {
                    return thumbnail.url;
                }
            }
            return null;
        },
        productUrl() {
            if(this.getProductUrlCallback && this.product) {
                return this.getProductUrlCallback(this.product.productNumber);
            }
            return {
                name: 'cart-checkout',
                params: { productNumber: this.product.productNumber }
            };
        },
        durationMonths() {
            if(this.product.durationMonths > 0) {
                if(this.product.durationMonths % 12 === 0) {
                    //Jahre
                    const durationYears = this.product.durationMonths / 12;
                    if(durationYears > 1) {
                        return this.replacePlaceholders(this.$t('Vertragslaufzeit {durationYears} Jahre'), {
                            durationYears
                        });
                    }
                    return this.$t('Vertragslaufzeit 1 Jahr')
                }
            }
            return null;
        }
    }
});