import template from "./contract-detail-page.html.twig";

Packlab.Component.register('contract-detail-page', {
    template,
    inject: [ 'apiClient' ],
    mixins: [ 'enum' ],

    data() {
        return {
            isLoading: false,
            contractId: null,
            showCancelBtn: false,
            contract: null,
            activeCancellation: false
        }
    },

    computed: {
        duration() {
            if(this.contract.durationMonth > 0) {
                return this.contract.durationMonth + ' ' + this.$t('Monate') + ' ' + this.$d(this.contract.startDate, 'date') + ' - ' + this.$d(this.contract.endDate, 'date');
            }
            return this.$t('Keine Laufzeit');
        },
        status(){
            return this.getVertragStatusEnumText(this.contract.contractStatus);
        },
        accounts(){
            if(this.contract.unlimitedCustomerAccounts) {
                return this.contract.usedCustomerAccounts + " / " + this.$t('unbegrenzt');
            }
            return this.contract.usedCustomerAccounts + " / " + this.contract.totalCustomerAccounts;
        },
        zertifikateKostenlos() {
            return this.contract.usedFreeCertificates + " / " + this.contract.totalFreeCertificates;
        },
        zertifikate() {
            if(this.contract.unlimitedCertificates) {
                return this.contract.usedCertificates + " / " + this.$t('unbegrenzt');
            }
            return this.contract.usedCertificates + " / " + this.contract.totalCertificates;
        },
        fonds() {
            return this.getYesnoEnumText(this.contract.calculateFundContributionYesno);
        },
        cancellation() {
            return this.contract.cancellationRequestedDatetime ? this.$d(this.contract.cancellationRequestedDatetime, 'date') : '';
        }

    },

    created() {
        this.loadContract();
    },

    methods: {
        loadContract() {
            this.isLoading = true;
            if(this.$route.params?.contractId) {
                this.contractId = this.$route.params?.contractId;
                this.apiClient.getContract(this.contractId)
                    .then((result) => {
                        this.contract = result.contract;
                        this.showCancelBtn = this.contract.durationMonth > 0 && this.contract.cancellationRequestedYesno === 41 && this.contract.contractStatus <= 2802;// (this.contract.cancellationRequestedYesno == 40 || this.contract.contractStatus > 2802) ? false : true;
                        this.activeCancellation = this.contract.cancellationRequestedYesno === 40;
                    })
                    .finally(() => this.isLoading = false );
            } else {
                console.log('BBB');
                this.apiClient.getContext().then((context) => {
                    this.contract = context.contract;
                    this.contractId = this.contract.contractId;
                })
                .finally(() => this.isLoading = false );
            }

        }
    }
});
