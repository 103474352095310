export default class VerpackungChecker {
    /**
     *
     * @param {ApiClient} apiClient
     */
    constructor(apiClient) {
        this.apiClient = apiClient;
    }

    createChecker(verpackungId) {
        return this.apiClient.postRequest(`verpackung/${verpackungId}/checker`);
    }

    getCheckerStep(verpackungId, stepKey) {
        return this.apiClient.getRequest(`verpackung/${verpackungId}/checker/${stepKey}`);
    }

    saveCheckerStep(verpackungId, stepKey, values) {
        return this.apiClient.postRequest(`verpackung/${verpackungId}/checker/${stepKey}`, { values });
    }
    saveCheckerBmg(verpackungId, bmg) {
        return this.apiClient.postRequest(`verpackung/${verpackungId}/checker/bmg`, bmg);
    }

    calculateRecyclability(verpackungId) {
        return this.apiClient.postRequest(`verpackung/${verpackungId}/checker/bmg/calculate`);
    }

    getVerpackungBmg(verpackungId) {
        return this.apiClient.getRequest(`verpackung/${verpackungId}/checker/bmg`);
    }

    getCheckResult(verpackungId) {
        return new Promise((resolve, reject) => {
            this.apiClient.getRequest(`verpackung/${verpackungId}/checker/result`)
                .then(async (result) => {
                    try {
                        const verpackungen = [];
                        for (let v in result.verpackungen) {
                            const verpackung = result.verpackungen[v];
                            const ruleset = await this.apiClient.getVerpackungRuleset(verpackung.rulesetid);
                            const verpackungKomponenten = this._getVerpackungKomponenten(verpackung);
                            const verpackungWertstoffe = {};
                            const recyclebareVerpackungWertstoffe = {};
                            let totalRecyclebareWeight = 0;
                            let totalRecyclebareWeightRatio = 0;

                            for(let k in verpackungKomponenten) {
                                const verpackungKomponente = verpackungKomponenten[k];
                                for(let w in verpackungKomponente.wertstoffe) {
                                    const komponenteWerstoff = verpackungKomponente.wertstoffe[w];
                                    if(typeof verpackungWertstoffe[komponenteWerstoff.wertstoff] === 'undefined') {
                                        verpackungWertstoffe[komponenteWerstoff.wertstoff] = {
                                            wertstoff: komponenteWerstoff,
                                            weight: 0,
                                            weightRatio: 0
                                        };
                                    }
                                    verpackungWertstoffe[komponenteWerstoff.wertstoff].weight+= komponenteWerstoff.weight;
                                    verpackungWertstoffe[komponenteWerstoff.wertstoff].weightRatio+= komponenteWerstoff.weightRatio;
                                    if(komponenteWerstoff.recyclebar) {
                                        if(typeof recyclebareVerpackungWertstoffe[komponenteWerstoff.wertstoff] === 'undefined') {
                                            recyclebareVerpackungWertstoffe[komponenteWerstoff.wertstoff] = {
                                                wertstoff: komponenteWerstoff,
                                                weight: 0,
                                                weightRatio: 0
                                            };
                                        }
                                        recyclebareVerpackungWertstoffe[komponenteWerstoff.wertstoff].weight+= komponenteWerstoff.recyclableWeight;
                                        recyclebareVerpackungWertstoffe[komponenteWerstoff.wertstoff].weightRatio+= komponenteWerstoff.recyclableWeightRatio;
                                        totalRecyclebareWeight+= komponenteWerstoff.recyclableWeight;
                                        totalRecyclebareWeightRatio+= komponenteWerstoff.recyclableWeightRatio;
                                    }
                                }
                            }

                            verpackungen.push({
                                verpackung,
                                ruleset,
                                verpackungWertstoffe,
                                recyclebareVerpackungWertstoffe,
                                totalRecyclebareWeight,
                                totalRecyclebareWeightRatio
                            });
                        }
                        resolve(verpackungen);
                    } catch(e) {
                        reject(e);
                    }
                })
                .catch(reject);
        });
        return this.apiClient.getRequest(`verpackung/${verpackungId}/checker/result`)
    }

    _getVerpackungKomponenten(verpackung) {
        const verpackungKomponenten = [];
        for(let k in verpackung.komponenten) {
            verpackungKomponenten.push(verpackung.komponenten[k]);
        }
        for(let k in verpackung.weitereKomponenten) {
            verpackungKomponenten.push(verpackung.weitereKomponenten[k]);
        }
        return verpackungKomponenten;
    }
}