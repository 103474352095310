Packlab.Mixin.register('notifications', {
    methods: {
        publishErrors(errors) {
            errors.forEach(error => {
                this.publishError(error. code, error.detail);
            });
        },

        publishError(code, message) {
            Packlab.Emitter.emit('notification.createError', {
                code: code,
                message: message
            });
        },

        publishInfo(code, message) {
            Packlab.Emitter.emit('notification.createInfo', {
                code: code,
                message: message
            });
        },

        publishToast(type, message, header) {
            this.$store.dispatch('publishToast', { type, message, header });
        }
    }
});
