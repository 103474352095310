import template from "./select-field.html.twig";
import {ErrorMessage, Field} from "vee-validate";
import {isEmpty} from "../../../../../utils/utils";

Packlab.Component.register('select-field', {
    template,

    components: {Field, ErrorMessage},
    emits: [ 'update:modelValue', 'change'],

    props: {
        modelValue: {
            required: true,
            default: null
        },
        defaultValue: {
            required: false,
            default: ''
        },
        defaultOption: {
            type: [ String, Object ],
            required: false,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: null
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            isLoading: false,
            _selectOptions: []
        };
    },

    computed: {
        currentValue() {
            return this.modelValue || this.currentValue;
        },
        defaultSelectOption() {
            return this.getDefaultOption();
        },
        selectOptions() {
            return this._selectOptions.filter((option) => {
                const groupKey = option.group || null;
                return !groupKey;
            });
        },
        selectGroups() {
            const selectOptions = Array.from(this._selectOptions);
            const selectGroups = Array.from(this.getSelectGroups());
            const groupedOptions = {};
            const groups = [];

            for (let i in selectOptions) {
                const option = selectOptions[i];
                const groupKey = option.group || null;
                if (!groupKey) {
                    continue;
                }
                if (typeof groupedOptions[groupKey] !== "object") {
                    groupedOptions[groupKey] = [];
                }
                groupedOptions[groupKey].push(option);
            }

            for(let i in selectGroups) {
                const group = selectGroups[i];
                if(typeof groupedOptions[group.group] === "object") {
                    groups.push({
                        label: group.label,
                        options: groupedOptions[group.group]
                    });
                }
            }
            return groups;
        },
        fieldId() {
            return this.id ? this.id : this.name;
        },
        readonlyOptions() {
            if(this.isLoading) {
                return '';
            }
            if(this.modelValue === null) {
                return '';
            }
            const options = [];
            const defaultValues = (typeof this.modelValue === 'object' ? this.modelValue : [ this.modelValue ]).map((v) => { return v.toString(); });
            for(let o in this.selectOptions) {
                if(defaultValues && defaultValues.indexOf(this.selectOptions[o].value.toString()) > -1) {
                    options.push(this.selectOptions[o].label);
                }
            }
            for(let g in this.selectGroups) {
                const groupOptions = this.selectGroups[g].options;
                for(let o in groupOptions) {
                    if(defaultValues && defaultValues.indexOf(groupOptions[o].value.toString()) > -1) {
                        options.push(groupOptions[o].label);
                    }
                }
            }

            return options.join(', ');
        },
        updateValue(value) {
            this.$emit('update:modelValue', value);
            this.$emit('change', value);
        }
    },

    methods: {
        onChange(e) {
            this.$emit('update:modelValue', e.target.value);
            this.$emit('change', e);
        },
        loadSelectOptions(selectOptions) {
            let updateDefaultValue = isEmpty(this.modelValue) && this.updateDefaultValue();
            if(!isEmpty(this.modelValue) && selectOptions.map((option) => option.value).indexOf(this.modelValue) < 0) {
                updateDefaultValue = true;
            }
            try {
                if (updateDefaultValue) {
                    let defaultOption = selectOptions.filter((option) => option.default)[0] || null;
                    if (!defaultOption) {
                        defaultOption = selectOptions[0] || null;
                    }
                    if (defaultOption) {
                        this.$emit('update:modelValue', defaultOption.value);
                    }
                }
            } catch(e) {
                console.error(e);
            }
            this._selectOptions = selectOptions;
        },
        updateDefaultValue() {
            return true;
        },
        getSelectGroups() {
            return [];
        },
        getFieldRules() {
            return true;
        },
        getDefaultOption() {
            if(this.defaultOption && typeof this.defaultOption === "string") {
                return {
                    value: this.defaultValue,
                    label: this.defaultOption
                }
            }
            if(this.defaultOption && typeof this.defaultOption === "object") {
                return this.defaultOption;
            }
            return null;
        }
    }
});