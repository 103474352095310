import template from "./cart-page.html.twig";

Packlab.Component.register('cart-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            isLoading: false,
            showTeaser: false
        }
    },

    created() {
        this.isLoading = true;
        this.apiClient.getContext().then((context) => {
            this.showTeaser = context.customer === null;
            if(context?.contract?.unlimitedCertificates) {
                this.$router.replace('/');
            }
        })
        .finally(() => this.isLoading = false );
    }
});