Packlab.Mixin.register('enum', {
    methods: {
        getGenderEnumText(salutationEnum) {
            switch (salutationEnum) {
                case 80:
                    return this.$t('Divers');
                case 81:
                    return this.$t('Herr');
                case 82:
                    return this.$t('Frau');
            }
            return '';
        },
        getSalutationEnumText(salutationEnum) {
            switch (salutationEnum) {
                case 80:
                    return this.$t('Sehr geehrte Damen und Herren');
                case 81:
                    return this.$t('Sehr geehrter Herr');
                case 82:
                    return this.$t('Sehr geehrte Frau');
            }
            return '';
        },
        getStatusEnumText(statusEnum) {
            switch (statusEnum) {
                case 20:
                    return this.$t('Aktiv');
                case 21:
                    return this.$t('Inaktiv');
            }
            return '';
        },
        getVertragStatusEnumText(statusEnum) {
            switch (statusEnum) {
                case 2800:
                    return this.$t('Neu');                
                case 2801:
                    return this.$t('Bezahlung offen');
                case 2802:
                    return this.$t('Aktiv');
                case 2803:
                    return this.$t('Inaktiv');
                case 2809:
                    return this.$t('gekündigt');                    
            }
            return '';
        },        
        getYesnoEnumText(yesnoEnum) {
            switch (yesnoEnum) {
                case 40:
                    return this.$t('Ja');
                case 41:
                    return this.$t('Nein');
            }
            return '';
        },
        getBillingTypeEnumText(billingTypeEnum) {
            switch (billingTypeEnum) {
                case 2000:
                    return this.$t('einmalig');
                case 2001:
                    return this.$t('monatlich');
                case 2002:
                    return this.$t('je Verpackung');
                case 2003:
                    return this.$t('jährlich');
            }
            return '';
        },
        getUserAccountStatusEnumText(statusEnum){
            switch (statusEnum) {
                case 2201:
                    return this.$t('Aktiv');
                case 2202:
                    return this.$t('gesperrt');
                case 2203:
                    return this.$t('warte auf Aktivierung');
                case 2209:
                    return this.$t('deaktiv');
            
            }
            return '';
        }
    }
});