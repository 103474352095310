import template from "./customer-register-form.html.twig";

Packlab.Component.register('customer-register-form', {
    template: template,
    mixins: [ 'form-validation' ],

    props: {
        customer: {
            type: Object,
            required: true
        },
        onlyEmailAddress: {
            type: Boolean,
            required: false,
            default: false
        },
        emailReadOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    }
});