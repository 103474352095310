import template from "./verpackung-wizard-step.html.twig";

Packlab.Component.register('verpackung-wizard-step', {
    template,

    props: {
        verpackungId: {
            type: [ String, null ],
            required: false,
            default: null
        },
        verpackungKombi: {
            type: Object,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        }
    },

    computed: {
        verpackungTyp() {
            return this.verpackungKombi ? this.verpackungKombi.getActiveVerpackungTyp() : null;
        }
    }
});