import template from "./verpackung-kombi-edit-btn.html.twig";

Packlab.Component.register('verpackung-kombi-edit-btn', {
    template,

    emits: [ 'click' ],
    inject: [ 'apiClient' ],

    props: {
        verpackungKombiId: {
            type: String,
            required: true
        },
        btnType: {
            type: String,
            required: false,
            default: 'primary'
        }
    },

    data() {
        return {
            isLoading: false,
            showCertificateModal: false,
            showRulesetModal: false,
            verpackungKombi: null,
            hasCertificate: null
        }
    },

    methods: {
        onOpenModal() {
            if(this.verpackung) {
               this.showModal = true;
            } else {
                this.isLoading = true;
                this.apiClient.getKombiVerpackung(this.verpackungKombiId)
                    .then((result) => {
                        this.verpackungKombi = result.verpackungKombi;
                        if(!result.ruleset.isDefault) {
                            this.showRulesetModal = true;
                            this.isLoading = false;
                            return;
                        }
                        this.apiClient.hasCertificate('verpackung_kombi', this.verpackungKombiId)
                            .then((result) => {
                                this.hasCertificate = result.hasCertificate;
                                if(this.hasCertificate) {
                                    this.showCertificateModal = true;
                                } else {
                                    this.editVerpackungKombi();
                                }
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    });
            }
        },
        editVerpackungKombi() {
            this.$emit('click', { verpackungKombiId: this.verpackungKombiId, verpackungKombi: this.verpackungKombi, hasCertificate: this.hasCertificate });
        }
    }
});