import template from "./notification-container.html.twig";

Packlab.Component.register('notification-container', {
    template: template,

    created() {
        Packlab.Emitter.on('notification.createError', this._createError.bind(this));
        Packlab.Emitter.on('notification.createInfo', this._createInfo.bind(this));
        Packlab.Emitter.on('customer.loggedin', this._clearNotifications.bind(this));
    },

    watch: {
        $route() {
            this._clearNotifications();
        }
    },

    data() {
        return {
            notifications: []
        };
    },

    methods: {
        deleteNotification(index) {
            if(typeof this.notifications[index] !== "undefined") {
                this.notifications.splice(index, 1);
            }
        },

        _createError(notification) {
            this._createNotification('danger', this._translateNotification(notification.code, notification.message));
        },

        _createInfo(notification) {
            this._createNotification('success', this._translateNotification(notification.code, notification.message));
        },

        _translateNotification(code, message) {
            switch(code) {
                case 'packlab.customer-account.not-found':
                    return this.$t('Es konnte kein Kunde mit den angegebenen Zugangsdaten gefunden werden.');
                case 'packlab.customer-account.invald-password':
                    return this.$t('Ihr eingegebenes Passwort ist falsch!');
            }
            return message;
        },

        _createNotification(type, message) {
            this.notifications.push({
                type,
                message
            })
        },

        _clearNotifications() {
            this.notifications = [];
        }
    }
});
