import {isEmpty} from "../../utils/utils";

const registeredDumpWatchers = {};

Packlab.Mixin.register('verpackung-editor', {
    methods: {
        isVerpackungKombi(verpackung) {
            return verpackung.verpackungKombi !== null;
        },

        /**
         * @param {VerpackungTyp} verpackungTyp
         */
        isCreateVerpackungKombi(verpackungTyp) {
            if(verpackungTyp.verpackungKombi && (verpackungTyp.verpackungKombi.createKombi || verpackungTyp.verpackungKombi.isKombi)) {
                return this.$t('Kombinationsverpackung') + ': ' . verpackungTyp.verpackungKombi.name;
            }
            return false;
        },

        /**
         * @param {VerpackungTyp} verpackungTyp
         */
        showVerpackungKombiName(verpackungTyp) {
            return verpackungTyp.verpackungKombi && (verpackungTyp.verpackungKombi.createKombi || verpackungTyp.verpackungKombi.isKombi);
        },

        /**
         * @param {VerpackungTyp} verpackungTyp
         */
        showVerpackungGruppeName(verpackungTyp) {
            return verpackungTyp.verpackungKombi && (verpackungTyp.verpackungKombi.createGruppe || verpackungTyp.verpackungKombi.isGruppe);
        },

        /**
         * @param {VerpackungTyp} verpackungTyp
         */
        getVerpackungKombiName(verpackungTyp) {
            if(verpackungTyp.verpackungKombi && (verpackungTyp.verpackungKombi.createKombi || verpackungTyp.verpackungKombi.isKombi)) {
                return this.$t('Kombinationsverpackung') + ': ' + verpackungTyp.verpackungKombi.name;
            }
            return '';
        },

        getVerpackungGruppeName(verpackungTyp) {
            if(verpackungTyp.verpackungKombi && (verpackungTyp.verpackungKombi.createGruppe || verpackungTyp.verpackungKombi.isGruppe)) {
                return this.$t('Verpackungsgruppe') + ': ' + verpackungTyp.verpackungKombi.gruppeName;
            }
            return '';
        },

        showVerpackungTypTitle(verpackungTyp, verpackungId = null) {
            if(verpackungId && verpackungTyp.isKombi && !verpackungTyp.deleteKombi) {
                return false;
            }
            return true;
        },

        /**
         * @param {VerpackungTyp} verpackungTyp
         */
        getVerpackungTypTitle(verpackungTyp) {
            if(verpackungTyp.hasVerpackungKombiTypeGruppe() && (verpackungTyp.isKombi && !verpackungTyp.deleteKombi || verpackungTyp.createKombi)) {
                if(!verpackungTyp.verpackungKombi.isKombi && !verpackungTyp.verpackungKombi.createKombi) {
                    return this.$t('Kombinationsverpackung') + ': ' + verpackungTyp.name;
                }
                return verpackungTyp.name;
            }
            return verpackungTyp.getMainVerpackung().name;
        },

        /**
         * @param {VerpackungTyp} verpackungTyp
         */
        showVerpackungTypMeta(verpackungTyp, verpackungId = null) {
            if(verpackungId && verpackungTyp.isKombi && !verpackungTyp.deleteKombi) {
                return false;
            }
            if(verpackungTyp.hasVerpackungKombiTypeGruppe() && (verpackungTyp.isKombi && !verpackungTyp.deleteKombi || !verpackungTyp.isKombi && !verpackungTyp.createKombi)) {
                return true;
            }
            return false;
        },

        /**
         * @param {Verpackung} verpackung
         */
        showVerpackungMeta(verpackung) {
            const verpackungTyp = verpackung.verpackungTyp;
            return (verpackungTyp.isKombi && !verpackungTyp.deleteKombi || verpackungTyp.createKombi) && verpackung.hasSelectedKomponenten();
        },

        /**
         * @param {Verpackung} verpackung
         */
        showVerpackungName(verpackung) {
            const verpackungTyp = verpackung.verpackungTyp;
            return (verpackungTyp.isKombi && !verpackungTyp.deleteKombi || verpackungTyp.createKombi) && verpackung.hasSelectedKomponenten();
        },

        /**
         * @param {Verpackung} verpackung
         */
        getVerpackungName(verpackung) {
            return verpackung.name;
        },

        /**
         * @param {Verpackung} verpackung
         */
        showVerpackungKomponentenTitle(verpackungIndex, verpackung) {
            const verpackungTyp = verpackung.verpackungTyp;
            if(verpackungTyp.verpackungTypKey === 'custom') {
                return false;
            }
            return (verpackungTyp.isKombi && !verpackungTyp.deleteKombi || verpackungTyp.createKombi) && Object.values(verpackung.komponenten).length > 0 || verpackungIndex === 0;
        },

        /**
         * @param {Verpackung} verpackung
         */
        showVerpackungWeitereKomponenten(verpackung, verpackungId = null) {
            const verpackungTyp = verpackung.verpackungTyp;
            if(verpackungTyp.verpackungTypKey === 'custom' || verpackungId && verpackungId === verpackung.id) {
                return true;
            }
            return verpackung.hasSelectedKomponenten();
        },

        /**
         * @param {Verpackung} verpackung
         */
        showMaterialfraktionSelect(verpackung) {
            const verpackungTyp = verpackung.verpackungTyp;
            return verpackung.verpackungIndex != verpackungTyp.mainVerpackungIndex || isEmpty(verpackungTyp.materialFraktion);
        },

        /**
         * @param {Verpackung} verpackung
         */
        showVerpackungEanInput(verpackung) {
            const verpackungTyp = verpackung.verpackungTyp;
            const verpackungKombi = verpackungTyp.verpackungKombi;
            if(verpackungTyp.verpackungTypKey === 'custom' && !verpackungKombi.isKombi && !verpackungKombi.createKombi) {
                return true;
            }

            if(verpackungTyp.createKombi || verpackungKombi.createKombi || verpackungKombi.isKombi || verpackungTyp.isKombi && !verpackungTyp.deleteKombi) {
                return false;
            }

            return true;
        },

        /**
         *
         * @param {VerpackungKombi} verpackungKombi
         * @returns {boolean}
         */
        showVerpackungKombiForm(verpackungKombi) {
            if(verpackungKombi.createKombi) {
                return false;
            }
            for(let t in verpackungKombi.verpackungTypen) {
                const verpackungTyp = verpackungKombi.verpackungTypen[t];
                if(verpackungTyp.createKombi) {
                    return true;
                }
            }
            return false;
        },

        isMainVerpackungIndex(verpackung) {
            const verpackungTyp = verpackung.verpackungTyp;
            return verpackung.verpackungIndex === verpackungTyp.mainVerpackungIndex;
        },

        registerDumpWatcher(property) {
            /*if (typeof registeredDumpWatchers[property] === "undefined") {
                this.$watch(property, (value) => {
                    const dump = this._dumpObj(property, value);
                    if (dump !== undefined) {
                        console.log(`DUMP: ${property}`, dump);
                    }

                }, {deep: true});
                registeredDumpWatchers[property] = true;
            }*/
        },

        _dumpObj(type, value) {
            if(!value) {
                return null;
            }
            if(type === 'verpackungKombi') {
                return {
                    isKombi: value.isKombi,
                    createKombi: value.createKombi,
                    verpackungTypen: value.verpackungTypen.map((verpackungTyp) => {
                        return {
                            name: verpackungTyp.name,
                            isKombi: verpackungTyp.isKombi,
                            createKombi: verpackungTyp.createKombi,
                            skipKombi: verpackungTyp.skipKombi,
                            deleteKombi: verpackungTyp.deleteKombi
                        }
                    })
                };
            }
            return undefined;
        },
    }
});
