import template from "./verpackung-checker-rule-group.html.twig";

Packlab.Component.register('verpackung-checker-rule-group', {
    template,

    props: {
        verpackung: {
            type: Object,
            required: true
        },
        ruleGroup: {
            type: Object,
            required: true
        },
        checkerStepValues: {
            type: Object,
            required: true
        }
    }
});