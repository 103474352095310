import template from "./packlab-uploader.html.twig";
import { Field, ErrorMessage } from "vee-validate";
import {getUid} from "../../../../utils/utils";

Packlab.Component.register('packlab-uploader', {
    template,
    components: { Field, ErrorMessage },

    emits: [ 'fileChanged', 'uploadStarted', 'uploadProgress', 'uploadCompleted' ],

    inject: [ 'apiClient' ],

    mixins: [ 'form-validation' ],

    props: {
        file: {
            type: Object,
            required: true
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },
        showDeleteBtn: {
            type: Boolean,
            required: false,
            default: true
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        },
        showFilename: {
            type: Boolean,
            required: false,
            default: true
        },
        accept: {
            type: String,
            required: false,
            default: '.png, .jpg, .jpeg, .gif'
        },
        btnText: {
            type: [ String, null ],
            required: false,
            default: null
        },
        deleteModalTitle: {
            type: [ String, null ],
            required: false,
            default: null
        },
        deleteModalText: {
            type: [ String, null ],
            required: false,
            default: null
        },
    },

    computed: {
        thumbnailSrc() {
            if(this.base64Thumbnail) {
                return this.base64Thumbnail;
            }
            if(this.file?.hasThumbnail || false) {
                return this.file.thumbnailUrl;
            }
            return null;
        },
        hasFile() {
            return this.file?.hasFile || false;
        },
        filename() {
            return this.file?.filename || '';
        }
    },

    data() {
        return {
            progress: 0,
            base64Thumbnail: null,
            showDeleteFileModal: false,
            fieldName: 'file-' + getUid()
        }
    },

    methods: {
        choiceFile() {
            this.$refs.fileuploadInput.click();
        },
        onFileSelected(files) {
            if(typeof files[0] === 'undefined') {
                return;
            }
            this.file.fileId = getUid();
            this.$emit('fileChanged', {
                fileId: this.file.fileId,
                file: files[0]
            });
            this.startUpload(files[0]);
        },
        startUpload(file) {
            this.file.saveImage = true;
            this.file.deleteFile = false;
            this.file.filename = file.name;
            this.file.hasFile = true;
            this.$emit('uploadStarted', {
                fileId: this.file.fileId,
                file: file
            });
            this.apiClient.uploadFile(this.file.fileId, file, this.uploadProgress.bind(this))
                .then((result) => {
                    this.base64Thumbnail = result.thumbnail;
                });
        },
        uploadProgress(event) {
            const { loaded, total } = event;
            this.progress = Math.round( (loaded * 100) / total );
            if(loaded >= total) {
                this.$emit('uploadCompleted', event);
                this.progress = 0;
            } else {
                this.$emit('uploadProgress', event);
            }
        },
        onOpenDeleteFileModal() {
            this.showDeleteFileModal = true;
        },
        onHideDeleteFileModal() {
            this.showDeleteFileModal = false;
        },
        onHideDelteFileModal() {
            this.$refs.deleteFileModal.hide();
        },
        deleteFile() {
            this.file.deleteFile = true;
            this.base64Thumbnail = null;
            this.file.hasThumbnail = false;
            this.file.hasFile = false;
            if(typeof this.file.copyFrom !== 'undefined') {
                delete this.file.copyFrom;
            }
            this.$refs.deleteFileModal.hide();
        }
    }
});