import template from "./packlab-page.html.twig";

Packlab.Component.register('packlab-page', {
    template: template,

    mixins: [ 'has-slot' ],

    props: {
        showHeadline: {
            type: Boolean,
            required: false,
            default: true
        },
        size: {
            type: String,
            required: false,
            default: 'small'
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        isSaving: {
            type: Boolean,
            required: false,
            default: false
        },
        showNotifications: {
            type: Boolean,
            required: false,
            default: true
        },
    },
});
