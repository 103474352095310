import template from "./packlab-table.html.twig";
import {getUid, isEmpty} from "../../../../utils/utils";

Packlab.Component.register('packlab-table', {
    template,

    emits: [ 'onClickCell' ],

    props: {
        tableName: {
            type: String,
            required: false,
            default: null
        },
        columns: {
            type: Array,
            required: true
        },
        rows: {
            type: Object,
            required: false
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        class: {
            type: String,
            required: false,
            default: null
        },
        customSort: {
            type: Function,
            required: false,
            default: undefined
        },
        pageSize: {
            type: Number,
            required: false,
            default: 25
        }
    },

    computed: {
        cssClass() {
            return this.class
        }
    },

    data() {
        return {
            tableId: 'table-' + getUid(),
            bootstrapTable: null,
            isLoadingTable: false

        };
    },

    updated() {
        if(this.bootstrapTable || this.isLoadingTable) {
            return;
        }
        this.isLoadingTable = true;

        this._initBootstrapTable().then(() => {
            this.bootstrapTable = jQuery('#' + this.tableId).bootstrapTable({
                locale: Packlab.Application.getLocale(),
                toolbar: `#${this.tableId}-toolbar`,
                pageSize: this._getDefaultPageSize(),
                customSort: this.customSort,
                onPageChange: (page, pageSize) => {
                    this._savePageSize(pageSize);
                },
                onClickCell: (field, value, row, element) => {
                    this.$emit('onClickCell', { field, value, row, element });
                }
            });
            this.isLoadingTable = false;
        }).catch((e) => { console.error(e); });
    },

    methods: {
        getContentAlignClass(column, prefix = 'justify-content-lg-') {
            switch(column.align || 'left') {
                case 'left':
                    return prefix + 'start';
                case 'center':
                    return prefix + 'center';
                case 'right':
                    return prefix + 'end';
            }
        },
        getColumnValue(row, column, raw = false) {
            const fields = column.field.split('.');
            let currentValue = row;
            for(let f in fields) {
                const fieldPart = fields[f];
                currentValue = typeof currentValue[fieldPart] === "undefined" ? {} : currentValue[fieldPart];
            }
            if(raw) {
                return currentValue;
            }
            if(column.type) {
                switch(column.type) {
                    case 'date':
                    case 'datetime':
                    case 'time':
                        return typeof currentValue === "string" ? this.$d(currentValue, column.type) : '';
                    case 'percentage':
                        return this.$n(typeof currentValue === 'number' ? currentValue : 0) + ' %';
                }
            }
            return currentValue;
        },
        getColumnData(row, column) {
            const value = this.getColumnValue(row, column, true);

            if(typeof value == 'object') {
                return JSON.stringify(value);
            }

            return value;
        },
        _initBootstrapTable() {
            return new Promise((resolve, reject) => {
                import("jquery").then((jq) => {
                    window.jQuery = window.$ = jq.default;
                    console.log('window.jQuery', window.jQuery.fn);
                    import("bootstrap-table").then(() => {
                        import("bootstrap-table/src/locale/bootstrap-table-de-DE");
                        resolve();
                    }).catch((e) => { reject(e) });
                }).catch((e) => { reject(e) });
            });
        },
        _getDefaultPageSize() {
            if(!this.tableName || typeof(Storage) === "undefined") {
                return this.pageSize;
            }

            try {
                return parseInt(localStorage.getItem(`easylize-table-${this.tableName}-page-size`) || this.pageSize);
            } catch(e) { }

            return this.pageSize;
        },
        _savePageSize(pageSize) {
            if(!this.tableName || typeof(Storage) === "undefined") {
                return this.pageSize;
            }
            try {
                localStorage.setItem(`easylize-table-${this.tableName}-page-size`, pageSize);
            } catch(e) { }
        }
    }
});
