import template from "./order-detail-page.html.twig";

Packlab.Component.register('order-detail-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            isLoading: false,
            isDownloading: false,
            order: null
        }
    },

    computed: {
        orderId() {
            return this.$route.params.orderId;
        }
    },

    created() {
        this.loadOrder();
    },

    methods: {
        loadOrder() {
            this.isLoading = false;
            this.apiClient.getOrder(this.orderId)
                .then((result) => {
                    this.order = result.order;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        }
    }

});