import template from "./verpackung-table.html.twig";
import { sort } from "../../../../../utils/utils";

Packlab.Component.register('verpackung-table', {
    template,

    inject: [ 'apiClient' ],

    props: {
        verpackungKombiId: {
            type: String,
            required: false,
            default: null
        },
        verpackungGruppeId: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        columns() {
            const columns = [
                {
                    field: 'name',
                    label: this.$t('Bezeichnung')
                },
                {
                    field: 'recyclingQuote',
                    label: 'recyclingQuote',
                    type: 'percentage',
                    align: 'right'
                },
                {
                    field: 'certificate',
                    label: 'certificate',
                    align: 'center'
                },
                {
                    field: 'ean',
                    label: this.$t('EAN')
                }
            ];

            if(!this.verpackungKombiId) {
                columns.push({
                    field: 'verpackungKombi',
                    label: this.$t('Kombinationsverpackung')
                });
            }

            if(!this.verpackungGruppeId) {
                columns.push({
                    field: 'verpackungGruppe',
                    label: this.$t('Verpackungsgruppe')
                });
            }
            columns.push({
                field: 'vpRulesetName',
                label: this.$t('Mindeststandard'),
            });
            columns.push({
                field: 'created',
                label: this.$t('hinzugefügt'),
                type: 'datetime'
            });
            columns.push({
                field: 'changed',
                label: this.$t('geändert'),
                type: 'datetime'
            });
            return columns;
        }
    },

    data() {
        return {
            isLoading: false,
            verpackungen: null
        }
    },

    created() {
        this.loadVerpackungen();
    },

    methods: {
        loadVerpackungen() {
            this.isLoading = true;
            this.apiClient.getVerpackungen({ verpackungKombiId: this.verpackungKombiId, verpackungGruppeId: this.verpackungGruppeId })
                .then((result) => {
                    this.verpackungen = result.verpackungen;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },

        getQueryParams(row) {
            const query = {};
            if(this.verpackungKombiId) {
                query.verpackungKombiId = this.verpackungKombiId;
            }
            return query;
        },

        sortTable(sortName, sortOrder, data) {
            data.sort((l, r) => {
                const dataIndex = `_${sortName}_data`;
                const lValue = l[dataIndex]?.value || null;
                const rValue = r[dataIndex]?.value   || null;
                return sort(lValue, rValue, sortOrder, (left, right) => {
                    if(sortName == 'verpackungKombi' || sortName == 'verpackungGruppe') {
                        const lName = lValue?.name || '';
                        const rName = rValue?.name || '';
                        return lName.localeCompare(rName);
                    }
                    return null;
                });
            });
        },

        onClickCell({ field, row }) {
            if(field !== "certificate") {
                return;
            }
            const certificate = row?._certificate_data?.value || null;
            if(!certificate) {
                return;
            }
            this.apiClient.getVerpackungZertifikatPdf(certificate.objectName, certificate.objectId)
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = `data:${response.fileType};base64,${response.contents}`;;
                    link.setAttribute('download', response.fileName);
                    document.body.appendChild(link);
                    link.click()
                    this.isPreparingDownload = false;
                })
        }
    }
});
