import template from "./customer-register-page.html.twig";
import { Form } from "vee-validate";

Packlab.Component.register('customer-register-page', {
    template,
    components: { Form },

    mixins: [ 'form-validation' ],
    inject: [ 'apiClient' ],

    data() {
        return {
            customer: this.getDefaultCustomer(),
            isSaving: false
        }
    },

    methods: {
        getDefaultCustomer() {
            return {
                email: ''
            };
        },
        onRegister(e) {
            e.preventDefault();
            this.$refs.form.validate().then((result) => {
                if (!result.valid) {
                    return;
                }
                this.isSaving = true;
                this.apiClient.customerCreate(this.customer).then(() => {
                    try {
                        window.sessionStorage.setItem('register-customer-account', JSON.stringify(this.customer));
                    } catch(e) { }
                    this.$router.replace({ name: 'customer-register-confirm' });
                }).catch(({ errors }) => {
                    this.setCustomFormErrors(this.$refs.form, errors);
                }).finally(() => this.isSaving = false );
            });
        }
    }
});
