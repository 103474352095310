import template from "./form-row.html.twig";
import {Field, ErrorMessage} from "vee-validate";
import { isEmpty } from "./../../../../utils/utils";

Packlab.Component.register('form-row', {
    template: template,
    components: {Field, ErrorMessage},
    mixins: [ 'form-validation' ],
    emits: [ 'update:modelValue', 'change', 'keyup', 'keydown', 'blur', 'focus'],

    props: {
        entity: {
            type: Object,
            required: false,
            default: null
        },
        entityField: {
            type: String,
            required: false,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: [ String, null ],
            required: false,
            default: null
        },
        label: {
            type: [ String, null ],
            required: false,
            default: null
        },
        placeholder: {
            type: [ String, null ],
            required: false,
            default: null
        },
        helpTooltip: {
            type: [ String, null ],
            required: false,
            default: null
        },
        fieldType: {
            type: [ String, null ],
            required: false,
            default: "text"
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        },
        asRow: {
            type: Boolean,
            required: false,
            default: true
        },
        rowClass: {
            type: String,
            required: false,
            default: null
        },
        labelClass: {
            type: String,
            required: false,
            default: null
        },
        fieldClass: {
            type: String,
            required: false,
            default: null
        },
        entityNamespace: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        modelValue: {
            get() {
                if(!this.entity || !this.entityField) {
                    return null;
                }
                return typeof this.entity[this.entityField] !== 'undefined' ? this.entity[this.entityField] : '';
            },
            set(newValue) {
                if(!this.entity || !this.entityField) {
                    return;
                }
                this.entity[this.entityField] = newValue;
            }
        },
        fieldId() {
            return this.id ? this.id : this.name;
        },
        rowId() {
            return this.fieldId + '_row';
        },
        labelId() {
            return this.fieldId + '_label';
        },
        labelText() {
            return this.isReadonly ? this.label +':' : this.label;
        },
        elementRowClass() {
            if(this.asRow && this.rowClass) {
                return 'form-group mt-3 row ' + this.rowClass;
            } else if(this.asRow) {
                return 'form-group mt-3 rowx border-top border-lg-0 flex-switch';
            } else if(this.rowClass) {
                return this.helpTooltip ? this.rowClass + ' show-tooltip' : this.rowClass;
            }
            return 'form-field-group';
        },
        elementLabelClass() {
            
            let labelClass = 'floating-label fieldtype-' + this.fieldType;
            
            if(this.isReadonly) {
                return 'col ' + labelClass;
            }
            

            if(this.isReadonly) return 'readonly-label fieldtype-' + this.fieldType;
            if(this.fieldType =="pre-filled") return 'floating-label labelTop fieldtype-' + this.fieldType;

            if(this.asRow && this.labelClass) {
                labelClass = 'floating-label ' + this.labelClass;
            } else if(this.asRow) {
                labelClass = 'floating-label fieldtype-' + this.fieldType;
            } else if(this.labelClass) {
                labelClass = this.labelClass + ' supa-class fieldtype-' + this.fieldType;
            }
            if(this.ontop || this.skipOntop) {
                labelClass = labelClass + ' labelTop fieldtype-' + this.fieldType;
            }
            return labelClass;
        },
        elementFieldClass() {
            if(this.isReadonly) {
                return 'row';
            }
            return 'form-field position-relative'
        },
        elementControlClass() {
            return 'form-control'
        }
    },

    data() {
        return {
            ontop: false,
            skipOntop: false,
            showLabel: true,
            validationRules: null,
            validationAttributes: null,
            isRequired: false,

        }
    },

    created() {
        if(typeof this.$attrs.required !== 'undefined') {
            this.isRequired = this.$attrs.required;
        } else if(this.entityNamespace) {
            this.isRequired = this.isFieldRequired(this.entityNamespace, this.entityField);
        }
        if(typeof this.$attrs.rules !== 'undefined') {
            this.validationRules = this.$attrs.rules;
        } else if(this.entityNamespace) {
            this.validationRules = this.buildFormValidationRules(this.entityNamespace, this.entityField, this.entity);
        }
        this.validationAttributes = this.buildFormValidationAttributes(this.entityNamespace, this.entityField, this.validationRules);
        if(this.entity && this.entityField) {
            const watchProperty = `entity.${this.entityField}`;
            this.$watch(watchProperty, (newVal) => {
                this._updateOntop(newVal);
            });
        }
    },

    mounted() {
        const tooltipElement = this.$el.querySelector('[data-bs-toggle="tooltip"]');
        this._updateOntop(this.modelValue);
        if(tooltipElement) {
            bootstrap.Tooltip.getOrCreateInstance(tooltipElement);
        }
    },

    methods: {
        onChange(e) {
            this.$emit('update:modelValue', e.target.value);
            this.$emit('change', e);
            this._updateOntop(e.target.value);
        },
        onKeyUp($event) {
            this.$emit($event.type, $event);
            this._updateOntop($event.target.value);
        },
        /*
        ,
        onFocus(e) {
            this.$emit('input', e);
        }
        */
        _updateOntop(value) {
            this.ontop = !isEmpty(value);
            if(this.$el.querySelector('select') !== null) {
                this.showLabel = !isEmpty(value);
            }
        }
    }
});