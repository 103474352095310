import template from "./verpackung-zertifikat-cart-page.html.twig";
import { Form, Field, ErrorMessage } from "vee-validate";

Packlab.Component.register('verpackung-zertifikat-cart-page', {
    template,
    components: { Form, Field, ErrorMessage },

    inject: [ 'apiClient' ],
    mixins: [ 'form-validation' ],

    computed: {
        objectType() {
            return this.$route.params.objectType;
        },
        objectId() {
            return this.$route.params.objectId;
        }
    },

    data() {
        return {
            isLoading: false,
            customer: null,
            contract: null
        }
    },

    created() {
        this.loadProducts();
    },

    methods: {
        loadProducts() {
            this.isLoading = true;
            this.apiClient.getVerpackungZertifikatCart(this.objectType, this.objectId)
                .then((result) => {
                    this.customer = result.customer;
                    this.contract = result.contract;
                    /*this.product = result.product;
                    this.apiClient.createCart(result.product.id).then(({ cart }) => {
                        this.cart = cart;
                        this.isLoading = false;
                    })
                    .catch(() => { this.isLoading = false; });*/
                })
                .finally(() => { this.isLoading = false; });
        }
    }

});
