import template from "./customer-profile-edit-page.html.twig";
import {Form} from "vee-validate";

Packlab.Component.register('customer-profile-edit-page', {
    template,
    components: { Form },

    inject: [ 'apiClient' ],

    data() {
        return {
            customer: null,
            isLoading: false,
            isSaving: false
        }
    },

    created() {
        this.isLoading = true;
        this.apiClient.getCustomerProfile()
            .then((customer) => {
                this.isLoading = false;
                customer.differentInvoiceAddress = customer.invoiceAddress != null;
                this.customer = customer;
                if(!customer.differentInvoiceAddress) {
                    customer.invoiceAddress = {
                        country: 'DE'
                    };
                }
            })
            .catch(() => {
                this.isLoading = false;
            });
        ;
    },

    methods: {
        saveCustomerProfile() {
            this.$refs.form.validate()
                .then((result) => {
                    if(!result.valid) {
                        return;
                    }
                    this.isSaving = true;
                    this.apiClient.saveCustomerProfile(this.customer)
                        .then(() => {
                            this.$router.push({ name: 'customer-profile' });
                        })
                        .catch(() => {
                            this.isSaving = false;
                        });
                });
        }
    }
});
