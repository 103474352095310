import {getUid} from "../../../utils/utils";
import mitt from "mitt";

export default class ModelBase {
    constructor() {
        this.uid = getUid();
        this.validateOptions = {};
        this._emitter = null;
    }

    load(json, ruleset) {

    }

    create(options = {}) {

    }

    validate(options = {}) {
        return {};
    }

    isValid(options = {}) {
        return Object.keys(this.validate(options)).length === 0;
    }

    getJson() {
        return {};
    }

    getEmitter() {
        if(!this._emitter) {
            this._emitter = mitt();
        }
        return this._emitter;
    }
}