import template from "./order-list-page.html.twig";

Packlab.Component.register('order-list-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            isLoading: false,
            orders: []
        }
    },

    computed: {
        columns() {
            return [
                {
                    field: 'orderNumber',
                    label: this.$t('Rechnungsnummer')
                },
                {
                    field: 'product',
                    label: this.$t('Paket')
                },
                {
                    field: 'contractNumber',
                    label: this.$t('Vertrag')
                },
                {
                    field: 'orderDate',
                    label: this.$t('Rechnungsdatum'),
                    type: 'date'
                },
                {
                    field: 'priceSum',
                    label: this.$t('Summe')
                },
                {
                    field: 'download'
                }
            ];
        }
    },

    created() {
        this.loadOrders();
    },

    methods: {
        loadOrders() {
            this.isLoading = true;
            this.apiClient.getOrders()
                .then((result) => {
                    this.orders = result.orders;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        getOrderProduct(order) {
            const lineItem = Object.values(order.items)[0] || null;
            return lineItem?.label;
        }
    }
});