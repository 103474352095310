import template from "./process-btn.html.twig";

Packlab.Component.register('process-btn', {
    template,

    mixins: [ 'notifications' ],
    emits: [ 'onSuccess', 'onFailed' ],

    props: {
        variant: {
            type: String,
            required: false,
            default: 'primary'
        },
        text: {
            type: String,
            required: false,
            default: null
        },
        clickHandler: {
            type: Function,
            required: true
        },
        notifyOnSuccess: {
            type: Boolean,
            required: false,
            default: true
        },
        successNotifyMsg: {
            type: String,
            required: false,
            default: null
        },

        notifyOnFailed: {
            type: Boolean,
            required: false,
            default: true
        },
        failNotifyMsg: {
            type: String,
            required: false,
            default: null
        },
    },

    computed: {
        btnText() {
            if(this.text) {
                return this.text;
            }
            return '&gt; ' + this.$t('speichern');
        }
    },

    data() {
        return {
            isProcessing: false
        }
    },

    methods: {
        onClick() {
            const callback = this.clickHandler();
            console.log('callback', callback instanceof Promise);
            if(callback && callback instanceof Promise) {
                this.isProcessing = true;
                callback.then((resolve) => {
                    if(this.notifyOnSuccess && this.successNotifyMsg) {
                        this.publishInfo('success', this.successNotifyMsg);
                    }
                    this.$emit('onSuccess', resolve);
                    this.isProcessing = false;
                });
                callback.catch((reject) => {
                    if(this.notifyOnFailed && this.failNotifyMsg) {
                        this.publishError('error', this.failNotifyMsg);
                    }
                    this.$emit('onFailed', reject);
                    this.isProcessing = false;
                });
            }
        }
    }
});