import template from "./order-download-btn.html.twig";

Packlab.Component.register('order-download-btn', {
    template,

    inject: [ 'apiClient' ],

    props: {
        orderId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isDownloading: false
        }
    },

    methods: {
        onSaveOrder() {
            this.isDownloading = true;
            this.apiClient.getOrderPdf(this.orderId)
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = `data:application/pdf;base64,${response.contents}`;;
                    link.setAttribute('download', response.fileName);
                    document.body.appendChild(link);
                    link.click()
                    this.isDownloading = false;
                })
                .catch(() => { this.isDownloading = false; });
        }
    }
});
