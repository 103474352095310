import template from "./verpackung-kombi-delete-btn.html.twig";

Packlab.Component.register('verpackung-kombi-delete-btn', {
    template,

    inject: [ 'apiClient' ],

    props: {
        verpackungKombiId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isLoading: false,
            isDeleting: false,
            showModal: false,
            verpackungKombi: null
        }
    },

    methods: {
        onOpenModal() {
            if(this.verpackungKombi) {
               this.showModal = true;
            } else {
                this.isLoading = true;
                this.apiClient.getKombiVerpackung(this.verpackungKombiId)
                    .then((result) => {
                        this.verpackungKombi = result.verpackungKombi;
                        this.showModal = true;
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            }
        },
        onHideModal() {
            this.showModal = false;
        },
        deleteVerpackungKombi() {
            this.isDeleting = true;
            this.apiClient.deleteKombiVerpackung(this.verpackungKombiId)
                .then(() => {
                    this.isDeleting = false;
                    this.showModal = false;
                    this.$router.go(-1);
                })
                .catch(() => { this.isDeleting = false; });
        }
    }
});