import template from "./verpackung-checker-confirm-step.html.twig";
import {Field, ErrorMessage} from "vee-validate";

Packlab.Component.register('verpackung-checker-confirm-step', {
    template,
    components: { Field, ErrorMessage },

    inject: [ 'apiClient' ],
    mixins: [ 'form-validation' ],

    props: {
        verpackung: {
            type: Object,
            required: true
        }
    },

    computed: {
        verpackungId() {
            return this.$route.params.verpackungId;
        }
    },

    data() {
        return {
            isLoading: false
        }
    },

    created() {
        this.loadBmg();
    },

    methods: {
        loadBmg() {
            this.isLoading = true;
            this.apiClient.getBmg(this.verpackungId)
                .then((result) => {
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        }
    }

});