import template from "./komponenten-wizard-step.html.twig";
import {ErrorMessage, Field, Form} from "vee-validate";

Packlab.Component.register('komponenten-wizard-step', {
    template,

    components: {Form, Field, ErrorMessage},

    mixins: [ 'form-validation', 'verpackung-editor' ],
    inject: [ 'apiClient' ],

    props: {
        verpackungId: {
            type: [ String, null ],
            required: false,
            default: null
        },
        verpackungKombi: {
            type: Object,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: false,
            default: null
        }
    },

    computed: {
        verpackungTyp() {
            return this.verpackungKombi ? this.verpackungKombi.getActiveVerpackungTyp() : null;
        }
    },

    methods: {
        getVerpackungen(verpackungTyp) {
            return verpackungTyp.getEditableVerpackungen(this.verpackungId)
        },
        getWeitereKomponenteName(ruleset, weitereKomponente) {
            return weitereKomponente.getKomponentenNameTitle(ruleset);
        },
        validate() {
            return new Promise((resolve) => {
                this._validateForm().then((formResult) => {

                    const verpackungKombiResult = this.verpackungKombi.validate({ komponenten: true, wertstoffe: true, verpackungId: this.verpackungId });
                    resolve({
                        valid: formResult.valid && Object.values(verpackungKombiResult).length === 0,
                        formResult,
                        verpackungKombiResult
                    });
                });
            });
        },
        save() {
            const verpackungTypSvg = this.$refs.verpackungTypSvg ? (this.$refs.verpackungTypSvg[0] || null) : null;
            return new Promise(async (resolve, reject) => {
                if(!verpackungTypSvg) {
                    resolve(null);
                    return;
                }
                for(let t in this.verpackungKombi.verpackungTypen) {
                    const verpackungTypGruppe = this.verpackungKombi.verpackungTypen[t];
                    for(let v in verpackungTypGruppe.verpackungen) {
                        try {
                            const verpackung = verpackungTypGruppe.verpackungen[v];
                            verpackung.verpackungTypePng = await verpackungTypSvg.getPng(verpackung);
                            console.log('verpackung', verpackung);
                        } catch({ error }) {
                            this.apiClient.logError(error.name || 'error', {
                                message: error.message || '',
                                stack: error.stack || '',
                                url: window.location.href
                            });
                            console.error(error);
                        }
                    }
                }
                resolve();
            });
        },
        _validateForm() {
            return new Promise((resolve) => {
                if(this.form) {
                    this.form.validate().then(resolve);
                } else {
                    resolve({ valid: true });
                }
            });
        },
    }
});