import template from "./check-indicator.html.twig";

Packlab.Component.register('check-indicator', {
    template,

    props: {
        check: {
            type: Boolean,
            required: false,
            default: false
        }
    }
});
