
export default {
    'EKOP_Aerosoldose_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Aerosoldose_P3_LM.svg'),
    'EKOP_Ampulle_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Ampulle_P3_LM.svg'),
    'EKOP_Eimer_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Eimer_P4_LM.svg'),
    'EKOP_Konservendose_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Konservendose_P3_LM.svg'),
    'EKOP_Sichtstreifenbeutel_P4_JH2.svg': require('../assets/images/verpackungImages/EKOP_Sichtstreifenbeutel_P4_JH2.svg'),
    'EKOP_Netz_P4_JH.svg': require('../assets/images/verpackungImages/EKOP_Netz_P4_JH.svg'),

    'EKOP_Getraenkeflasche_P4_JH.svg': require('../assets/images/verpackungImages/EKOP_Getraenkeflasche_P4_JH.svg'),
    'EKOP_Getraenkeflasche_11ecom.svg': require('../assets/images/verpackungImages/EKOP_Getraenkeflasche_11ecom.svg'),
    'EKOP_Getraenkeflasche_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Getraenkeflasche_P3_LM.svg'),
    'EKOP_Getraenkeflasche_Wein_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Getraenkeflasche_Wein_P3_LM.svg'),

    'EKOP_Faltschachtel_Fenster_P4_JH1.svg': require('../assets/images/verpackungImages/EKOP_Faltschachtel_Fenster_P4_JH1.svg'),
    'EKOP_Arzneimittelblister_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Arzneimittelblister_P3_LM.svg'),
    'EKOP_Banderole_P3_JH.svg': require('../assets/images/verpackungImages/EKOP_Banderole_P3_JH.svg'),
    'EKOP_Becher_Dose_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Becher_Dose_P3_LM.svg'),
    'EKOP_Beutel_P3_JH.svg': require('../assets/images/verpackungImages/EKOP_Beutel_P3_JH.svg'),
    'EKOP_Einschlagpapier_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Einschlagpapier_P3_LM.svg'),
    'EKOP_Fluessigkeitskarton_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Fluessigkeitskarton_P3_LM.svg'),
    'EKOP_Folie_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Folie_P3_LM.svg'), 
    'EKOP_Formteil_11ecom.svg': require('../assets/images/verpackungImages/EKOP_Formteil_11ecom.svg'),
    'EKOP_Geschlossene_Schachtel_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Geschlossene_Schachtel_P3_LM.svg'), 
    'EKOP_Gewuerzmuehle_P3_JH.svg': require('../assets/images/verpackungImages/EKOP_Gewuerzmuehle_P3_JH.svg'),
    'EKOP_Haengeblister_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Haengeblister_P3_LM.svg'),
    'EKOP_Hangtag_P3_JH.svg': require('../assets/images/verpackungImages/EKOP_Hangtag_P3_JH.svg'),
    'EKOP_Kanister_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Kanister_P3_LM.svg'),
    'EKOP_Kantenschutz_P3_JH.svg': require('../assets/images/verpackungImages/EKOP_Kantenschutz_P3_JH.svg'),
    'EKOP_Karton_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Karton_P3_LM.svg'),

    'EKOP_Korb_Physalis_P5_JH2.svg': require('../assets/images/verpackungImages/EKOP_Korb_11ecom.svg'),

    'EKOP_Funktionsflasche_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Funktionsflasche_P3_LM.svg'),
    'EKOP_Kartusche_P3_JH.svg': require('../assets/images/verpackungImages/EKOP_Kartusche_P3_JH.svg'),
    'EKOP_Netz_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Netz_P3_LM.svg'),
    'EKOP_Obstschale_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Obstschale_P3_LM.svg'),
    'EKOP_Offene_Schachtel_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Offene_Schachtel_P3_LM.svg'),
    'EKOP_Roehre_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Roehre_P3_LM.svg'),
    'EKOP_Rolle_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Rolle_P3_LM.svg'),
    'EKOP_Rolle_Textilband_P3_JH.svg': require('../assets/images/verpackungImages/EKOP_Rolle_Textilband_P3_JH.svg'),
    'EKOP_Sack_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Sack_P3_LM.svg'),
    'EKOP_Schale_P3_LM.svg': require('../assets/images/verpackungImages/EKOP-Schale-11ecom.svg'),
    'EKOP_Schlauchfolienbeutel_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Schlauchfolienbeutel_P3_LM.svg'),
    'EKOP_Schraubdeckeldose_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Schraubdeckeldose_P3_LM.svg'),
    'EKOP_Standbodenbeutel_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Standbodenbeutel_P3_LM.svg'),
    'EKOP_Steige_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Steige_P3_LM.svg'),
    'EKOP_Stift_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Stift_P3_LM.svg'),
    'EKOP_Traegerplatte_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Traegerplatte_P3_LM.svg'),
    'EKOP_Tube_Applikator_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Tube_Applikator_P3_LM.svg'),
    'EKOP_Tube_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Tube_P3_LM.svg'),
    'EKOP_Tuete_P3_LM.svg': require('../assets/images/verpackungImages/EKOP_Tuete_P3_LM.svg')


};
