import template from "./verpackung-kombi-form.html.twig";
import {Field, ErrorMessage, Form} from "vee-validate";

Packlab.Component.register('verpackung-kombi-form', {
    template,
    components: { Field, ErrorMessage },

    mixins: [ 'form-validation' ],

    props: {
        verpackungKombi: {
            type: Object,
            required: true
        },
        packlabWizard: {
            type: Object,
            required: false,
            default: null
        },
        form: {
            type: Object,
            required: false,
            default: null
        },
        createVerpackungen: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        enableDeleteBtn() {
            return this.verpackungKombi.verpackungTypen.length > 2;
        }
    },

    methods: {
        onAddWeitereVerpackung() {
            this.verpackungKombi.addVerpackungTyp({
                isKombiDraft: true,
                name: this.$t('Weitere Verpackung')
            });
        },

        onRemoveWeitereVerpackung(index) {
            this.verpackungKombi.verpackungTypen.splice(index, 1);
        },

        onConfigureVerpackung(index) {
            if(!this.form || !this.packlabWizard) {
                return;
            }

            this.form.validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                this.verpackungKombi.activeVerpackungTypIndex = index;
                this.packlabWizard.onGoToNextStep();
            });
        }
    }
});
