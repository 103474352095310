import template from "./customer-address-form.html.twig";

Packlab.Component.register('customer-address-form', {
    template,

    mixins: [ 'form-validation' ],

    props: {
        address: {
            type: Object,
            required: true
        },
        addressType: {
            type: String,
            required: true
        },
        showCompanyField: {
            type: Boolean,
            required: false,
            default: false
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        },
    },
});
