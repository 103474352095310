import template from "./customer-settings-page.html.twig";
import { Form} from "vee-validate";

Packlab.Component.register('customer-settings-page', {
    template,

    components: { Form },

    inject: [ 'apiClient' ],

    data() {
        return {
            isLoading: false,
            isSaving: false,
            settings: null
        }
    },

    created() {
        this.loadSettings();
    },

    methods: {
        loadSettings() {
            this.apiClient.getCustomerSettings()
                .then((result) => {
                    this.settings = result.settings;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        save() {
            return this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                return this.apiClient.saveCustomerSettings(this.settings);
            });
        }
    }
});
