import template from "./payment-method-select.html.twig";

Packlab.Component.register('payment-method-select', {
    template,

    inject: [ 'apiClient' ],

    props: {
        modelValue: [ String, null ]
    },

    data() {
        return {
            isLoading: false,
            paymentMethods: null,
            selectedPaymentMethod: null,
            defaultPaymentMethodId: null
        }
    },

    created() {
        this.loadPaymentMethods();
    },

    methods: {
        loadPaymentMethods() {
            this.isLoading = true;
            this.apiClient.getPaymentMethods()
                .then((result) => {
                    this.paymentMethods = result.paymentMethods;
                    if(!this.modelValue && this.defaultPaymentMethodId) {
                        this.$emit('update:modelValue', this.defaultPaymentMethodId);
                        this._setselectedPaymentMethod(this.defaultPaymentMethodId);
                    } else if(!this.modelValue && typeof this.paymentMethods[0] !== "undefined") {
                        this.$emit('update:modelValue', this.paymentMethods[0].id);
                        this._setselectedPaymentMethod(this.paymentMethods[0].id);
                    } else {
                        this._setselectedPaymentMethod(this.modelValue);
                    }
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; })
        },
        _setselectedPaymentMethod(paymentMethodId) {
            this.selectedPaymentMethod = this.paymentMethods.filter((paymentMethod) => { return paymentMethod.id === paymentMethodId; })[0] || null;
        }
    }
});
