import template from "./checkout.html.twig";
import { Form, Field, ErrorMessage } from "vee-validate";

Packlab.Component.register('checkout', {
    template,

    components: { Form, Field, ErrorMessage },
    mixins: [ 'placeholder', 'enum', 'form-validation', 'notifications' ],
    inject: [ 'apiClient' ],

    props: {
        productNumber: {
            type: String,
            required: true
        },
        objectType: {
            type: String,
            required: false,
            default: null
        },
        objectId: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        productImage() {
            if(this.product?.cover?.thumbnails) {
                const thumbnail = this.product?.cover?.thumbnails.filter((thumbnail) => { return thumbnail.meta.width === 400 && thumbnail.meta.height === 400; })[0] || null;
                if(thumbnail) {
                    return thumbnail.url;
                }
            }
            return null;
        },
        activeTabId() {
            return this._getActiveTab()?.id || '';
        },
        backLink() {
            if(this.objectType !== null && this.objectId !== null) {
                return {
                    name: 'verpackung-zertifikat-cart',
                    params: {
                        objectType: this.objectType,
                        objectId: this.objectId
                    }
                }
            }
            return {
                name: 'cart'
            }
        },
        voucherItem() {
            if(!this.cart) {
                return null;
            }
            return this.cart.lineItems.filter((item) => {
                return item.type === 'promotion';
            })[0] || null;
        }
    },

    data() {
        return {
            isLoading: false,
            isWorking: false,
            product: null,
            customer: null,
            hasCustomer: false,
            voucherCode: null,
            showVoucherCodeError: false,
            isLoadingVoucher: false,
            cart: null,
            order: {
                paymentMethodId: null,
                objectType: this.objectType,
                objectId: this.objectId
            },
            tabs: [
                {
                    id: 'product',
                    index: 0,
                    active: true,
                    disabled: false,
                    label: this.$t('Ihre Paketwahl')
                },
                {
                    id: 'register',
                    index: 1,
                    active: false,
                    disabled: true,
                    label: this.$t('Kontaktdaten')
                },
                {
                    id: 'order',
                    index: 2,
                    active: false,
                    disabled: true,
                    label: this.$t('bestellen')
                }
            ],
            btns: {
                prevBtn: {
                    label: this.$t('zurück'),
                    disabled: true
                },
                nextBtn: {
                    label: this.$t('weiter'),
                    disabled: false
                }
            }
        }
    },

    created() {
        this.loadProduct();
        this._updateBtns();
    },

    methods: {
        loadProduct() {
            this.isLoading = true;
            this.apiClient.getProduct(this.productNumber)
                .then((product) => {
                    this.product = product;
                    this.isLoading = false;
                })
                .catch(({ error }) => {
                    this.isLoading = false;
                    if(error.response.status === 404) {
                        this.$router.push('/cart');
                    }
                });
        },
        onPrevBtnClicked() {
            const activeTab = this._getActiveTab();
            if(!activeTab) {
                return;
            }
            if(activeTab.id === 'register') {
                this.setActiveTab('product');
            }
            if(activeTab.id === 'order') {
                this.setActiveTab('register');
            }
        },
        onNextBtnClicked() {
            const activeTab = this._getActiveTab();
            if(!activeTab) {
                return;
            }
            if(activeTab.id === 'product') {
                this.setActiveTab('register');
            } else if(activeTab.id === 'register') {
                this.$refs.registerForm[0].validate().then((result) => {
                    if (!result.valid) {
                        return;
                    }
                    this.isWorking = true;
                    if(this.hasCustomer) {
                        this.apiClient.customerUpdate(this.customer)
                            .then((result) => {
                                this.setActiveTab('order');
                                this.isWorking = false;
                            })
                            .catch(({errors}) => {
                                this.setCustomFormErrors(this.$refs.registerForm[0], errors);
                                this.isWorking = false;
                            });
                    } else {
                        this.apiClient.customerRegister(this.customer)
                            .then(() => {
                                this.setActiveTab('order');
                                this.isWorking = false;
                            })
                            .catch(({errors}) => {
                                this.setCustomFormErrors(this.$refs.registerForm[0], errors);
                                this.isWorking = false;
                            });
                    }
                });
            } else if(activeTab.id === 'order') {
                this.$refs.orderForm[0].validate().then((result) => {
                    if (!result.valid) {
                        return;
                    }
                    this.isLoading = true;
                    this.apiClient.createOrder(this.order)
                        .then((result) => {
                            if(result.createContract) {
                                try {
                                    window.sessionStorage.setItem('register-customer-account', JSON.stringify(this.customer.customerAccount));
                                } catch(e) { }
                                this.$router.replace('/customer/register/confirm');
                            } else if(result.success) {
                                window.location.href = result.redirectUri;
                            }
                            this.isLoading = false;
                        })
                        .catch(() => { this.isLoading = false; });
                });
            }

        },
        onGotoStep(tabId) {
            const activeTab = this._getActiveTab();
            if(tabId === activeTab?.id) {
                return;
            }
            this.setActiveTab(tabId);
        },

        setActiveTab(tabId) {
            if(tabId === 'register') {
                this.isLoading = true;
                this.apiClient.getContext().then((context) => {
                    if (context.customer) {
                        this.apiClient.getCustomerProfile()
                            .then((customer) => {
                                this.hasCustomer = true;
                                this.customer = {
                                    customer: customer.customer,
                                    customerAccount: customer.customerAccount,
                                    address: customer.address,
                                    differentInvoiceAddress: customer.invoiceAddress !== undefined,
                                    invoiceAddress: customer.invoiceAddress || {
                                        street: '',
                                        houseno: '',
                                        zip: '',
                                        city: '',
                                        countryId: ''
                                    }
                                }
                                this.customer.customerAccount.email2 = this.customer.customerAccount.email;
                                this._updateActiveTab(tabId);
                                this.isLoading = false;
                            })
                    } else {
                        this.hasCustomer = false;
                        if(!this.customer) {
                            this.customer = {
                                customer: {
                                    name: '',
                                    businessYesno: 40,
                                    taxNumber: '',
                                },
                                customerAccount: {
                                    salutationEnum: null,
                                    firstname: '',
                                    lastname: '',
                                    email: '',
                                    password: '',
                                    phone: '',
                                },
                                address: {
                                    street: '',
                                    houseno: '',
                                    zip: '',
                                    city: '',
                                    countryId: ''
                                },
                                differentInvoiceAddress: false,
                                invoiceAddress: {
                                    street: '',
                                    houseno: '',
                                    zip: '',
                                    city: '',
                                    countryId: ''
                                }
                            };
                        }
                        this._updateActiveTab(tabId);
                        this.isLoading = false;
                    }
                });
            } else if(tabId === 'order') {
                this.isLoading = true;
                this.apiClient.createCart(this.product.id)
                    .then((result) => {
                        this.cart = result.cart;
                        this.isLoading = false;
                    })
                    .then(() => { this.isLoading = false; });
                this._updateActiveTab(tabId);
            } else {
                this._updateActiveTab(tabId);
            }
        },
        applyVoucher() {
            this.isLoadingVoucher = true;
            this.apiClient.applyVoucher(this.voucherCode).then((result) => {
                this.cart = result.cart;
                this.showVoucherCodeError = (this.cart.errors['promotion-not-found'] || this.cart.errors['promotion-not-eligible'] || null) !== null;
            }).finally(() => this.isLoadingVoucher = false );
        },
        removeVoucher(voucherItem) {
            this.isLoadingVoucher = true;
            this.apiClient.removeVoucher(voucherItem.id).then((result) => {
                this.cart = result.cart;
            }).finally(() => this.isLoadingVoucher = false );
        },
        _updateBtns() {
            const activeTab = this._getActiveTab();
            if(!activeTab) {
                return;
            }
            this.btns.prevBtn.disabled = activeTab.id === 'product';
            this.btns.nextBtn.label = activeTab.id === 'order' ? this.$t('kostenpflichtig bestellen') : this.$t('weiter');
        },

        _getActiveTab() {
            return this.tabs.filter((tab) => { return tab.active; })[0] || null;
        },

        _updateActiveTab(tabId) {
            this.tabs.forEach((tab) => {
                tab.active = tab.id === tabId;
                if(tab.id === tabId) {
                    tab.disabled = false;
                }
            });
            this._updateBtns();
        }
    }
});
