import template from "./number-input.html.twig";
import { Field, ErrorMessage, useField } from "vee-validate";
import AutoNumeric from 'autonumeric/dist/autoNumeric.min';
import {getNumberFormat, getUid} from "../../../../../utils/utils";

Packlab.Component.register('number-input', {
    template,
    components: { Field, ErrorMessage },
    emits: ['update:value'],

    props: {
        value: { required: true, default: null },
        id: {
            type: String,
            required: false,
            default: 'number-input-' + getUid(),
        },
        name: {
            type: String,
            required: false,
            default: 'number-input-' + getUid(),
        },
        decimalPlaces: {
            type: Number,
            default: 2
        }
    },

    data() {
        return {
            autoNumericElement: null
        };
    },

    setup(props, { emit, attrs }) {
        const {
            value: inputValue,
            errorMessage,
            handleBlur,
            handleChange,
            validate,
            meta,
        } = useField(props.name, attrs.rules, {
            initialValue: props.value,
            valueProp: props.value
        });

        const onInput = (event, autoNumericElement) => {
            const value = autoNumericElement.rawValue !== '' ? autoNumericElement.getNumber() : null;
            emit('update:value', value);
        }

        return {
            onInput,
            handleChange,
            handleBlur,
            errorMessage,
            inputValue,
            meta,
        };
    },

    mounted() {
        const numberFormat = getNumberFormat(this.$i18n.locale);
        this.autoNumericElement = new AutoNumeric(this.$refs.autoNumericRef.$el, {
            leadingZero: 'deny',
            decimalCharacter: numberFormat.decimalCharacter,
            digitGroupSeparator: numberFormat.digitGroupSeparator,
            decimalPlaces: this.decimalPlaces,
            modifyValueOnWheel: false,
            unformatOnHover: false,
            minimumValue: 0
        });
        this.updateValue();
    },

    methods: {
        updateValue () {
            this.autoNumericElement.set(this.value !== null ? this.value : '');
        }
    }
});