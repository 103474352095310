import template from "./alert-modal-btn.html.twig";

Packlab.Component.register('alert-modal-btn', {
    template,

    emits: [ 'clicked' ],
    mixins: [ 'has-slot' ],

    props: {
        showModal: {
            type: Boolean,
            required: false,
            default: false
        },
        class: {
            type: String,
            required: false,
            default: 'btn btn-primary'
        }
    },

    data() {
        return {
            showAlertModal: false
        };
    },

    methods: {
        onClick() {
            if(this.showModal) {
                this.showAlertModal = true;
            } else {
                this.$emit('clicked');
            }
        }
    }
});