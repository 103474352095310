import template from "./product-choice.html.twig";

Packlab.Component.register('product-choice', {
    template,

    mixins: [ 'placeholder', 'enum' ],
    inject: [ 'apiClient' ],

    props: {
        objectType: {
            type: String,
            required: false,
            default: null
        },
        objectId: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            isLoading: false,
            products: null
        }
    },

    created() {
        this.loadProducts();
    },

    methods: {
        getProductUrl(productNumber) {
            if(this.objectType !== null && this.objectId !== null) {
                return {
                    name: 'verpackung-zertifikat-checkout',
                    params: {
                        objectType: this.objectType,
                        objectId: this.objectId,
                        productNumber
                    }
                };
            }
            return { name: 'cart-checkout', params: { productNumber } };
        },
        loadProducts() {
            this.isLoading = true;
            this.apiClient.getProducts(this.objectType !== null && this.objectId !== null)
                .then((products) => {
                    this.products = products;
                })
                .finally(() => { this.isLoading = false; } );
        }
    }
});
