import template from "./verpackung-gruppe-edit-page.html.twig";
import { Form } from "vee-validate";

Packlab.Component.register('verpackung-gruppe-edit-page', {
    template,
    components: { Form },

    mixins: [ 'form-validation' ],
    inject: [ 'apiClient' ],

    computed: {
        verpackungGruppeId() {
            return this.$route.params.verpackungGruppeId;
        },
    },

    data() {
        return {
            isLoading: false,
            verpackungGruppe: null,
            isSaving: false
        }
    },

    created() {
        this.loadVerpackungGruppe();
    },

    methods: {
        loadVerpackungGruppe() {
            this.isLoading = true;
            this.apiClient.getVerpackungsGruppe(this.verpackungGruppeId)
                .then((result) => {
                    this.verpackungGruppe = result.verpackungGruppe;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        onSave() {
            this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                this.isSaving = true;
                this.apiClient.saveVerpackungsGruppe(this.verpackungGruppeId, this.verpackungGruppe)
                    .then(() => {
                        this.$router.replace(`/verpackung-gruppe/${this.verpackungGruppeId}`);
                        this.isSaving = false;
                    })
                    .catch(() => { this.isSaving = false; });
            });
        }
    }
});
