import template from "./verpackung-delete-btn.html.twig";

Packlab.Component.register('verpackung-delete-btn', {
    template,

    inject: [ 'verpackungService', 'apiClient' ],

    props: {
        verpackungId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isLoading: false,
            isDeleting: false,
            showModal: false,
            verpackung: null
        }
    },

    methods: {
        onOpenModal() {
            if(this.verpackung) {
               this.showModal = true;
            } else {
                this.isLoading = true;
                this.verpackungService.loadVerpackung(this.verpackungId, false)
                    .then((result) => {
                        this.verpackung = result.verpackungTyp.getVerpackungById(this.verpackungId);
                        this.showModal = true;
                        this.isLoading = false;
                    })
                    .catch(() => {
                        this.isLoading = false;
                    });
            }
        },
        onHideModal() {
            this.showModal = false;
        },
        deleteVerpackung() {
            this.isDeleting = true;
            this.apiClient.deleteVerpackung(this.verpackungId)
                .then(() => {
                    this.isDeleting = false;
                    this.$refs.modal.hide();
                    this.$router.push({ name: 'verpackungen-list' });
                })
                .catch(() => { this.isDeleting = false; });
        }
    }
});