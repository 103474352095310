import { watch } from 'vue';
import template from "./verpackung-type-svg.html.twig";

Packlab.Component.register('verpackung-type-svg', {
    template,

    props: {
        verpackungTyp: {
            type: Object,
            required: true
        },
        verpackungId: {
            type: String,
            required: false,
            default: null
        }
    },

    watch: {
        'verpackungTyp.image'(newImage) {
            if(!newImage) {
                this.svgImage = null;
                return;
            }
            this.loadSVG(newImage);
        },
        'verpackungTyp.verpackungen': {
            handler() {
                this.updateVerpackungKomponenten();
            },
            deep: true
        }
    },

    data() {
        return {
            svgImage: null
        };
    },

    created() {
        if(this.verpackungTyp.image) {
            this.loadSVG(this.verpackungTyp.image);
        }
    },

    methods: {
        getPng(verpackung) {
            return new Promise((resolve, reject) => {
                try {
                    const srcSvg = this.$el.querySelector('svg');
                    if (!srcSvg) {
                        resolve(null);
                        return;
                    }

                    const img = new Image();
                    const DOMURL = window.URL || window.webkitURL || window;
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

                    canvas.width = 400;
                    canvas.height = 400;

                    for (let i = 0; i < srcSvg.attributes.length; i++) {
                        const attrName = srcSvg.attributes[i].nodeName;
                        const attrValue = srcSvg.attributes[i].nodeValue;
                        if (attrName === 'id') {
                            continue;
                        }
                        svg.setAttribute(attrName, attrValue);
                    }

                    const visibleParts = this.$el.querySelectorAll('svg g.show');
                    visibleParts.forEach((part) => {
                        const partNode = part.cloneNode(true);
                        partNode.removeAttribute('id');
                        svg.appendChild(partNode);
                    });

                    const data = (new XMLSerializer()).serializeToString(svg);
                    const svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
                    const url = DOMURL.createObjectURL(svgBlob);
                    img.setAttribute("src", url);
                    img.onload = () => {
                        ctx.drawImage(img, 0, 0);
                        DOMURL.revokeObjectURL(url);

                        const imgURI = canvas
                            .toDataURL('image/png');
                        resolve(imgURI);
                    };
                } catch(error) {
                    reject({ error });
                }
            });
        },
        updateVerpackungKomponenten() {
            const komponenten = this.verpackungTyp.getKomponenten(this.verpackungId);
            for(let k in komponenten) {
                const komponente = komponenten[k];
                const imagePart = document.getElementById(komponente.imagePart);
                if(!imagePart) {
                    continue;
                }
                const selectable = komponente.selectable;
                const isSelected = selectable ? (komponente.selected || false) : true;
                if(isSelected) {
                    imagePart.classList.add('show');
                } else {
                    imagePart.classList.remove('show');
                }
            }
        },
        async loadSVG(image) {
            const imageUrl = Packlab.Config.verpackungImages[image] || null;
            if(!imageUrl) {
                console.error('image url not found: ', image);
                return;
            }
            try {
                const response = await fetch(imageUrl);
                this.svgImage = await response.text();
                setTimeout(this.updateVerpackungKomponenten.bind(this), 10);
            } catch (err) {
                console.error(err);
            }
        }
    }
});
