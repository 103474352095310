import template from "./customer-login-form.html.twig";
import { Form, Field, ErrorMessage } from "vee-validate";

Packlab.Component.register('customer-login-form', {
    template: template,

    mixins: [ 'form-validation', 'notifications', 'whitelabel' ],
    inject: [ 'apiClient' ],

    components: {Form, Field, ErrorMessage},

    data() {
        return {
            isLoading: false,
            email: '',
            password: '',
            whitelabelLogin: false
        };
    },

    created() {
        if(this.whitelabelKey !== undefined && this.whitelabelKey !== '') {
            const token = this.$route?.query?.token || null;
            this.isLoading = true;
            this.whitelabelLogin = true;
            if(token) {
                this.apiClient.setContextToken(token);
                window.location.href = '/';
            } else {
                window.location.href = `${Packlab.Config.apiUrl}/whitelabel/${this.whitelabelKey}/auth`;
            }
        }
    },

    methods: {
        emailRequired() {
            if(this.email === '') {
                return this.getFormValidationRequiredMessage();
            }
            if(!this.isValidEmail(this.email)) {
                return this.getFormValidationEmailMessage();
            }
            return true;
        },
        passwordRequired() {
            if(this.password === '') {
                return this.getFormValidationRequiredMessage();
            }
            return true;
        },
        onLogin(e) {
            e.preventDefault();
            this.isLoading = true;
            this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    this.isLoading = false;
                    return;
                }

                this.apiClient.customerLogin(this.email, this.password).then(() => {
                    this.$router.push({ name: 'index' });
                    this.isLoading = false;
                }).catch(({ errors }) => {
                    this.publishErrors(errors);
                    this.isLoading = false;
                });
            });
        },
    }
});