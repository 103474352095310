import template from "./toast-container.html.twig";

Packlab.Component.register("toast-container", {
    template,

    computed: {
        toats() {
            return this.$store.getters.getToats();
        }
    },
    methods: {
        removeToast(index) {
            this.$store.dispatch('removeToast', index);
        }
    }
});