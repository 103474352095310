import ModelBase from "./model-base";
import Komponente from "./komponente";
import WeitereKomponente from "./weitere-komponente";
import { isEmpty } from "../../../utils/utils";

export default class Verpackung extends ModelBase {
    constructor(verpackungTyp) {
        super();
        this.verpackungTyp = verpackungTyp;
        this.id = null;
        this.parentGruppeVerpackungId = null;
        this.bmg = null;
        this.verpackungGruppe = null;
        this.verpackungGruppeId = null;
        this.verpackungKombi = null;
        this.verpackungKombiId = null;
        this.name = '';
        this.ean = '';
        this.dimensions = '';
        this.description = '';
        this.verpackungIndex = 0;
        this.recyclingQuote = 0;
        this.recyclingQuoteWarning = true;
        this.quantity = 1;
        this.weight = 0.0;
        this.totalWeight = 0.0;
        this.wertstoff = null;
        this.materialFraktion = null;
        this.materialGruppe = null;
        this.verpackungTypKey = null;
        this.hasCertificate = false;
        this.image = {};
        this.verpackungTypePng = null;
        this.notRecyclableReasons = {};
        this.komponenten = {};
        this.weitereKomponenten = [];
        this.herstellerspezifikationen = [];
    }

    load(json, ruleset) {
        this.id = json.id;
        this.parentGruppeVerpackungId = json.parentGruppeVerpackungId;
        this.bmg = json.bmg || null;
        this.verpackungGruppe = json.verpackungGruppe || null;
        this.verpackungGruppeId = json.verpackungGruppeId || null;
        this.verpackungKombi = json.verpackungKombi || null;
        this.verpackungKombiId = json.verpackungKombiId || null;
        this.rulesetId = json.rulesetId;
        this.name = json.name;
        this.ean = json.ean;
        this.dimensions = json.dimensions;
        this.description = json.description;
        this.verpackungIndex = json.verpackungIndex;
        this.recyclingQuote = json.recyclingQuote;
        this.recyclingQuoteWarning = json.recyclingQuoteWarning;
        this.quantity = json.quantity;
        this.weight = json.weight;
        this.totalWeight = json.totalWeight;
        this.wertstoff = json.wertstoff;
        this.image = json.image;
        this.notRecyclableReasons = json.notRecyclableReasons;
        this.materialFraktion = json.materialFraktion;
        this.materialGruppe = json.materialGruppe;
        this.verpackungTypKey = json.verpackungTypKey;
        this.hasCertificate = json.hasCertificate;
        this.herstellerspezifikationen = json.herstellerspezifikationen;

        for(let k in json.komponenten) {
            const komponenteJson = json.komponenten[k];
            if(typeof this.komponenten[komponenteJson.key] !== "undefined") {
                this.komponenten[komponenteJson.key].selected = true;
                this.komponenten[komponenteJson.key].load(komponenteJson, ruleset);
            }
        }

        for(let k in json.weitereKomponenten) {
            const weitereKomponeteJson = json.weitereKomponenten[k];
            const weitereKomponente = new WeitereKomponente(this);
            weitereKomponente.load(weitereKomponeteJson, ruleset);
            this.weitereKomponenten.push(weitereKomponente);
        }
    }

    create(options = {}) {
        this.verpackungIndex = options.verpackungIndex || 0;
    }

    validate(options = {}) {
        //TODO: Check validation

        const result = {};
        const komponenten = options.komponenten === true;
        const materialFraktion = options.materialFraktion === true;
        const iKombi = options.isKombi === true;



        if(materialFraktion) {
            if(isEmpty(this.materialFraktion)) {
                result['materialFraktion'] = this.materialFraktion;
            }
            if(isEmpty(this.wertstoff)) {
                result['wertstoff'] = this.wertstoff;
            }
        } else {
            if (isEmpty(this.materialFraktion)) {
                result['materialFraktion'] = this.materialFraktion;
            }
            /*if (isEmpty(this.ean) && !iKombi) {
                result['ean'] = this.ean;
            }*/
        }
        if(!komponenten) {
            return result;
        }

        const komponentenResult = this._validateKomponentent(this.komponenten, true, options);
        const weitereKomponentenResult = this._validateKomponentent(this.weitereKomponenten, false, options);

        if(Object.keys(komponentenResult).length > 0) {
            result['komponenten'] = komponentenResult;
        }

        if(Object.keys(weitereKomponentenResult).length > 0) {
            result['weitereKomponenten'] = weitereKomponentenResult;
        }
        return result;

    }

    getJson() {
        return {
            id: this.id,
            name: this.name,
            ean: this.ean,
            dimensions: this.dimensions,
            description: this.description,
            quantity: this.quantity,
            verpackungIndex: this.verpackungIndex,
            wertstoff: this.wertstoff,
            materialFraktion: this.materialFraktion,
            materialGruppe: this.materialGruppe,
            image: this.image,
            verpackungTypePng: this.verpackungTypePng || null,
            komponenten: Object.values(this.komponenten).filter((komponente) => { return komponente.selected; }).map((komponente) => { return komponente.getJson(); }),
            weitereKomponenten: this.weitereKomponenten.map((komponente) => { return komponente.getJson(); }),
            herstellerspezifikationen: this.herstellerspezifikationen
        };
    }

    isRated() {
        return this.bmg?.status == 8102;
    }
    setWertstoff(wertstoff, setHauptKomponenteHauptWertstoff = true) {
        this.wertstoff = wertstoff;
        if(!setHauptKomponenteHauptWertstoff) {
            return;
        }

        let mainKomponente = Object.values(this.komponenten).filter((komponente) => { return komponente.hauptKomponente; })[0] || null;
        if(!mainKomponente) {
            mainKomponente = Object.values(this.weitereKomponenten).filter((komponente) => { return komponente.default; })[0] || null;
        }
        if(!mainKomponente) {
            return;
        }

        const notSelectableKomponenten = Object.values(this.komponenten).filter((komponente) => { return !komponente.hauptKomponente && !komponente.selectable; });
        for(let k in notSelectableKomponenten) {
            if(notSelectableKomponenten[k].wertstoffe.length === 0) {
                notSelectableKomponenten[k].createWertstoff(null, false);
            }
        }

        if(mainKomponente.wertstoffe.length === 0 && wertstoff) {
            mainKomponente.createWertstoff(wertstoff, true);
        } else if(mainKomponente.wertstoffe.length > 0 && wertstoff) {
            mainKomponente.wertstoffe[0].wertstoff = wertstoff;
        }
    }

    createKomponente(komponenteKey, komponenteOptions) {
        const komponente = new Komponente(this);
        komponenteOptions.key = komponenteKey;
        komponente.create(komponenteOptions);
        this.komponenten[komponenteKey] = komponente;
    }

    createWeitereKomponente(weitereKomponenteOptions = {}) {
        const weitereKomponente = new WeitereKomponente(this);
        weitereKomponente.addDefaultWertstoff();
        this.weitereKomponenten.push(weitereKomponente);
    }

    createWeitereHauptKomponente(wertstoff = null, defaultName = null) {
        if(Object.values(this.weitereKomponenten).length > 0) {
            return;
        }
        const weitereKomponente = new WeitereKomponente(this);
        weitereKomponente.default = true;
        if(defaultName) {
            weitereKomponente.nameKey = 'sonstige';
            weitereKomponente.isCustomInput = true;
            weitereKomponente.customName = defaultName;
        } else {
            weitereKomponente.nameKey = '';
        }

        if(wertstoff) {
            weitereKomponente.createWertstoff(wertstoff, true);
        } else {
            weitereKomponente.addDefaultWertstoff();
        }

        this.weitereKomponenten.push(weitereKomponente);
    }

    removeWeitereKomponente(index) {
        this.weitereKomponenten.splice(index, 1);
    }

    getSortedKomponenten() {
        const sortedKomponenten = Object.values(this.komponenten).filter((komponente) => komponente.selectable && komponente.selected || !komponente.selectable);

        sortedKomponenten.sort((l, r) => {
            const lHauptKomponente = (l.isKombi && l.kombiPart == 1 || !l.isKombi) && l.hauptKomponente;
            const rHauptKomponente = (r.isKombi && r.kombiPart == 1 || !r.isKombi) && r.hauptKomponente;
            if(lHauptKomponente && !rHauptKomponente) {
                return -1;
            } else if(!lHauptKomponente && rHauptKomponente) {
                return 1;
            }
            return l.name.localeCompare(r.name, undefined, { sensitivity: 'base' });
        });
        return sortedKomponenten;
    }

    getSortedWeitereKomponenten(ruleset) {
        const sortedWeitereKomponenten = Object.values(this.weitereKomponenten);
        sortedWeitereKomponenten.sort((l, r) => {
            const lName = l.getKomponentenNameTitle(ruleset);
            const rName = r.getKomponentenNameTitle(ruleset);
            return lName.localeCompare(rName, undefined, { sensitivity: 'base' });
        });
        return sortedWeitereKomponenten;
    }

    hasSelectedKomponenten() {
        if(this.verpackungTyp.isCustomVerpackungTyp) {
            return true;
        }
        for(let k in this.komponenten) {
            if(this.komponenten[k].selected) {
                return true;
            }
        }
        return false;
    }

    hasKomponenten() {
        if(this.hasSelectedKomponenten()) {
            return true;
        }
    }

    _validateKomponentent(komponenten, checkSelected = false, options = {}) {
        const komponententResult = {};

        for(let k in komponenten) {
            const komponente = komponenten[k];
            if(checkSelected && !komponente.selected) {
                continue;
            }

            const komponenteResult = komponente.validate(options);
            if(Object.keys(komponenteResult).length === 0) {
                continue;
            }
            komponententResult[komponente.uid] = komponenteResult;
        }

        return komponententResult;
    }

    _komponenteSelectionChanged(komponente) {

    }
}