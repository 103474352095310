import template from "./packlab-modal.html.twig";
import bsModal from "bootstrap/js/src/modal";

Packlab.Component.register('packlab-modal', {
    template,

    emits: [ 'show', 'hide' ],
    mixins: [ 'has-slot' ],

    props: {
        autoOpen: {
            type: Boolean,
            default: false
        },
        showCloseBtn: {
            type: Boolean,
            default: true
        },
        modalBackdrop: {
            type: [ Boolean, String ],
            default: true
        },
        modalFocus: {
            type: Boolean,
            default: true
        },
        modalKeyboard: {
            type: Boolean,
            default: true
        },
        buttons: {
            type: Object,
            required: false,
            default: []
        },
        modalClass: {
            type: String,
            default: null
        },
        modalDialogClass: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            modalInstance: null
        }
    },

    computed: {
        showModelHeader() {
            return this.showCloseBtn || this.hasSlot('title');
        }
    },

    mounted() {
        if(this.autoOpen) {
            this.show();
        }
    },

    methods: {
        getInstance() {
            if(!this.modalInstance) {
                this.modalInstance = new bsModal(this.$el, {
                    backdrop: this.modalBackdrop,
                    focus: this.modalFocus,
                    keyboard: this.modalKeyboard
                });
                this.$el.addEventListener('hidden.bs.modal', () => {
                    this.$emit('hide');
                });
            }
            return this.modalInstance;
        },
        show() {
            const modalInstance = this.getInstance();
            modalInstance.show();
            this.$emit('show');
        },
        hide() {
            const modalInstance = this.getInstance();
            modalInstance.hide();
            this.$emit('hide');
        }
    }
});