import template from "./verpackung-edit-wizard-page.html.twig";

Packlab.Component.register('verpackung-edit-wizard-page', {
    template,

    inject: [ 'verpackungService' ],

    computed: {
        verpackungId() {
            return this.$route.params.verpackungId;
        }
    },

    data() {
        return {
            isLoading: false
        }
    },

    methods: {
        onSaved() {
            this.$router.push({ name: 'verpackung-detail', params: { verpackungId: this.verpackungId } });
        }

    }
});