import KomponenteBase from "./komponente-base";
import {isEmpty} from "../../../utils/utils";

export default class WeitereKomponente extends KomponenteBase {
    constructor(verpackung) {
        super(verpackung);
        this.key = 'custom';
        this.default = false;
        this.nameKey = '';
        this.isCustomInput = false;
        this.customName = '';
    }

    getKomponentenNameTitle(ruleset) {
        if(this.isCustomInput) {
            return this.customName;
        }
        return ruleset.getKomponentenNameTitle(this.nameKey);
    }

    load(json, ruleset) {
        super.load(json, ruleset);
        this.default = json.default;
        this.nameKey = json.nameKey;
        this.isCustomInput = json.isCustomInput;
        this.customName = json.customName;
    }

    create(options = {}) {

    }

    validate(options = {}) {
        const result = super.validate(options);

        if(this.isCustomInput && isEmpty(this.customName)) {
            result['customName'] = this.customName;
        }

        return result;
    }

    getJson() {
        const json = super.getJson();
        json.default = this.default;
        json.nameKey = this.nameKey;
        json.isCustomInput = this.isCustomInput;
        json.customName = this.customName;
        return json;
    }
}