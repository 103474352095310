Packlab.Component.extend('verpackung-wertstoff-select', 'select-field', {
    inject: [ 'apiClient' ],

    props: {
        ruleset: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            wizardSelectOptions: [],
            wizardGroupOptions: []
        }
    },

    created() {
        const wertstoffe = this.ruleset.getWertstoffe();
        const lucidMaterialgruppen = this.ruleset.getLucidMaterialgruppen();
        this.wizardSelectOptions = [];
        this.wizardGroupOptions = [];
        for(let w in wertstoffe) {
            this.wizardSelectOptions.push({
                value: w,
                label: wertstoffe[w].title,
                group: wertstoffe[w].lucidMaterialgruppe
            });
        }
        for(let g in lucidMaterialgruppen) {
            this.wizardGroupOptions.push({
                group: g,
                label: lucidMaterialgruppen[g].title
            });
        }

        this.loadSelectOptions(this.wizardSelectOptions);
    },

    methods: {
        getSelectGroups() {
            return this.wizardGroupOptions;
        },
    }
});
