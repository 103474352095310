import template from "./verpackung-gruppe-verpackung-kombi.html.twig";
import {getUid} from "../../../../utils/utils";

Packlab.Component.register('verpackung-gruppe-verpackung-kombi', {
    template,

    props: {
        verpackungKombi: {
            type: Object,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        },
        isChild: {
            type: Boolean,
            required: false,
            default: false
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            uid: getUid()
        }
    },

    methods: {
        getAccordionTitleSlot(key) {
            return `item-${key}-title`;
        },
        getAccordionContentSlot(key) {
            return `item-${key}-content`;
        }
    }
});
