import template from "./fonds-beitrag-table.html.twig";

Packlab.Component.register('fonds-beitrag-table', {
    template,

    inject: [ 'apiClient' ],

    computed: {
        columns() {
            const columns = [
                {
                    field: 'name',
                    label: this.$t('Bezeichnung')
                },
                {
                    field: 'recyclingQuote',
                    label: 'recyclingQuote',
                    type: 'percentage',
                    align: 'right'
                },
                {
                    field: 'totalVerpackungen',
                    label: this.$t('Anzahl'),
                    align: 'right'
                },
                {
                    field: 'fundContribution',
                    label: this.$t('Fondsbeitrag'),
                    align: 'right'
                }
            ];

            if(!this.verpackungKombiId) {
                columns.push({
                    field: 'verpackungKombi',
                    label: this.$t('Kombinationsverpackung')
                });

                columns.push({
                    field: 'verpackungGruppe',
                    label: this.$t('Verpackungsgruppe')
                });
            }

            columns.push({
                field: 'created',
                label: this.$t('hinzugefügt'),
                type: 'datetime'
            });
            columns.push({
                field: 'changed',
                label: this.$t('geändert'),
                type: 'datetime'
            });
            return columns;
        }
    },

    data() {
        return {
            isLoading: false,
            verpackungen: null
        }
    },

    created() {
        this.loadVerpackungen();
    },

    methods: {
        loadVerpackungen() {
            this.isLoading = true;
            this.apiClient.getVerpackungen()
                .then((result) => {
                    this.verpackungen = result.verpackungen;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        }
    }
});
