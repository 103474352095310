import template from "./verpackung-checker-result-step.html.twig";
import {isEmpty} from "../../../../../../utils/utils";

Packlab.Component.register('verpackung-checker-result-step', {
    template,

    mixins: [ 'verpackung' ],
    inject: [ 'verpackungService', 'apiClient', 'verpackungChecker' ],

    props: {
        verpackungId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isLoading: false,
            verpackungen: null,
            context: null
        }
    },



    created() {
        this.loadVerpackung();
    },

    methods: {
        loadVerpackung() {
            this.isLoading = true;
            this.apiClient.getContext()
                .then((context) => {
                    this.context = context;
                    this.verpackungChecker.getCheckResult(this.verpackungId)
                        .then((verpackungen) => {
                            this.verpackungen = verpackungen;
                            for(let v in verpackungen) {
                                this._createVpCircleScript(verpackungen[v].verpackung);
                            }
                            this.isLoading = false;
                        })
                        .catch((e) => { this.isLoading = false; console.error(e); });
                })
                .catch(() => { this.isLoading = false; });
        },
        getCreateCertLink() {
            if(!this.verpackungen) {
                return null;
            }
            const verpackung = this.verpackungen[0]?.verpackung || null;
            if(!verpackung) {
                return null;
            }
            if(verpackung.verpackungGruppeId) {
                return { name: 'verpackungen-gruppe-detail', params: { verpackungGruppeId: verpackung.verpackungGruppeId }, hash: '#top' };
            }
            if(verpackung.verpackungKombiId) {
                return { name: 'verpackungen-kombi-detail', params: { kombiVerpackungId: verpackung.verpackungKombiId }, hash: '#create-certificate-btn' };
            }
            return { name: 'verpackung-detail', params: { verpackungId: verpackung.id }, hash: '#create-certificate-btn' };
        },
        getVerpackungMaterialGruppe(verpackung) {
            const materialGruppe = verpackung.ruleset.getMaterialGruppe(verpackung.verpackung.materialGruppe);
            if(!materialGruppe) {
                return verpackung.verpackung.materialGruppe;
            }
            return materialGruppe.title;
        },
        getVerpackungMaterialfration(verpackung) {
            const materialFraktion = verpackung.ruleset.getMaterialFraktion(verpackung.verpackung.materialFraktion);
            if(!materialFraktion) {
                return verpackung.ruleset.getMaterialFraktionAuswahlTitle(verpackung.verpackung.materialFraktion);
            }
            return verpackung.ruleset.getMaterialFraktionAuswahlTitle(verpackung.verpackung.materialFraktion);
        },
        getRecyclingLevel(verpackung){
            let level = 1;
            const quote = verpackung.verpackung.recyclingQuote;
            const checkResultQuote = verpackung.ruleset.getMatchingCheckResultQuote(quote);
            if(checkResultQuote) {
                level = checkResultQuote.level;
            }
            return "animate-" + verpackung.verpackung.id + " recycling-level-" + level;
        },
        getRecyclingText(verpackung){
            let level = 1;
            const quote = verpackung.verpackung.recyclingQuote;
            const checkResultQuote = verpackung.ruleset.getMatchingCheckResultQuote(quote);
            if(checkResultQuote) {
                return checkResultQuote.title;
            }
            return '';
        },
        getKomponenteTitle(verpackung, komponente) {
            if(komponente.key === 'custom') {
                const komponenteName = verpackung.ruleset.getKomponentenName(komponente.nameKey);
                if (!komponenteName) {
                    return komponente.nameKey;
                }
                if (komponenteName.customInput === true) {
                    return komponente.customName;
                }
                return komponenteName.name;
            }
            const verpackungTyp = verpackung.ruleset.getVerpackungTyp(verpackung.verpackung.verpackungTypKey);
            if(!verpackungTyp) {
                return komponente.key;
            }
            const verpackungTypGruppe = verpackung.ruleset.getVerpackungTypGruppe(verpackungTyp.verpackungTypGruppe);
            if(!verpackungTypGruppe) {
                return komponente.key;
            }
            const verpackungTypGruppeKomponente = verpackungTypGruppe.komponenten[komponente.key] || null;
            return verpackungTypGruppeKomponente ? verpackungTypGruppeKomponente.title : komponente.key;
        },
        getKomponenteWertstoffTitle(verpackung, komponenteWerstoff) {
            const wertstoff = verpackung.ruleset.getWertstoff(komponenteWerstoff.wertstoff);
            if(!wertstoff) {
                return komponenteWerstoff.wertstoff;
            }
            if(isEmpty(wertstoff.title)) {
                return komponenteWerstoff.wertstoff;
            }
            return wertstoff.title;
        },
        getKomponenteWertstoffColor(verpackung, komponenteWertstoff) {
            return verpackung.ruleset.getWertstoffColorName(komponenteWertstoff.color);
        },
        _createVpCircleScript(verpackung) {
            /* circle graphic with dynamic keyframe animation */
            const verpackungId = verpackung.id;
            let script = document.getElementById("style-vp-circle-" + verpackungId);
            if(!script){
                const css = document.createElement('style');
                css.id= "style-vp-circle-" + verpackungId;
                let value = ( 2 * Math.PI *  60 ) - ( 2 * Math.PI *  60 * verpackung.recyclingQuote / 100 );
                css.innerHTML =".circle-wrapper.active  .circulator.animate-"+verpackungId+" svg {animation: offsettozero-"+verpackungId+" 2s linear forwards} @keyframes offsettozero-"+verpackungId+" { to { stroke-dashoffset: "+value+" }}";
                document.head.appendChild(css);
            }
        }
    }
});