import template from "./verpackung-gruppe-detail-page.html.twig";
import { Form } from "vee-validate";
import {getUid} from "../../../../utils/utils";

Packlab.Component.register('verpackung-gruppe-detail-page', {
    template,
    components: { Form },

    mixins: [ 'placeholder' ],
    inject: [ 'apiClient' ],

    computed: {
        verpackungGruppeId() {
            return this.$route.params.verpackungGruppeId;
        },
    },

    data() {
        return {
            isLoading: false,
            verpackungGruppe: null,
            ruleset: null,
            mainVerpackung: null,
            childVerpackungen: {},
            showVerpackungDeleteModal: false,
            deleteVerpackungIndex: null
        }
    },

    created() {
        this.loadVerpackungGruppe();
    },

    methods: {
        loadVerpackungGruppe() {
            this.isLoading = true;
            this.apiClient.getVerpackungsGruppe(this.verpackungGruppeId)
                .then((result) => {
                    this.verpackungGruppe = result.verpackungGruppe;
                    this.ruleset = result.ruleset;
                    this.mainVerpackung = result.mainVerpackung;
                    this.childVerpackungen = result.childVerpackungen;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        onAddChildVerpackung() {
            const child = this._createNewChild();
            this.childVerpackungen[child.uid] = child;
        },
        onAbortCreateVerpackung(childVerpackungIndex) {
            delete this.childVerpackungen[childVerpackungIndex];
        },
        onCreateNewChildVerpackung(childVerpackungIndex) {
            const formIndex = `form-${childVerpackungIndex}`;
            this.$refs[formIndex][0].validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                this.apiClient.createVerpackungGruppeChild(this.verpackungGruppeId, this.childVerpackungen[childVerpackungIndex])
                    .then((result) => {
                        this.childVerpackungen[childVerpackungIndex] = result;
                    });
            });
        },
        onSaveChildVerpackung(childVerpackungIndex) {
            const formIndex = `form-${childVerpackungIndex}`;
            this.$refs[formIndex][0].validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                this.apiClient.updateVerpackungGruppeChild(this.verpackungGruppeId, this.childVerpackungen[childVerpackungIndex])
                    .then((result) => {
                        this.childVerpackungen[childVerpackungIndex] = result;
                    });
            });
        },
        onShowVerpackungDeleteModal(childVerpackungIndex) {
            this.deleteVerpackungIndex = childVerpackungIndex;
            this.showVerpackungDeleteModal = true;
        },
        onHideVerpackungDeleteModal() {
            this.deleteVerpackungIndex = null;
            this.showVerpackungDeleteModal = false;
        },
        onDeleteVerpackung() {
            this.apiClient.deleteVerpackungGruppeChild(this.verpackungGruppeId, this.childVerpackungen[this.deleteVerpackungIndex].id)
                .then(() => {
                    delete this.childVerpackungen[this.deleteVerpackungIndex];
                    this.$refs.verpackungDeleteModal.hide();
                });
        },
        _createNewChild() {
            if(this.mainVerpackung.type == 'verpackung-kombi') {
                return this._createNewVerpackungKombi(this.mainVerpackung);
            }
            return this._createNewVerpackung(this.mainVerpackung);
        },
        _createNewVerpackungKombi(verpackungKombi) {
            const verpackungen = {};
            for(let v in verpackungKombi.verpackungen) {
                const verpackung = verpackungKombi.verpackungen[v];
                const newVerpackung = this._createNewVerpackung(verpackung);
                verpackungen[verpackung.id] = newVerpackung;
            }
            return {
                uid: getUid(),
                type: 'verpackung-kombi',
                createNew: true,
                parentId: verpackungKombi.id,
                name: verpackungKombi.name,
                ean: verpackungKombi.ean,
                verpackungen: verpackungen
            }
        },
        _createNewVerpackung(verpackung) {
            const newKomponenten = {};
            for(let k in verpackung.komponenten) {
                const komponente = verpackung.komponenten[k];
                const newWertstoffe = {};

                for(let w in komponente.wertstoffe) {
                    const wertstoff = komponente.wertstoffe[w];
                    const data = {};
                    for(let dataFieldName in wertstoff.data) {
                        data[dataFieldName] = wertstoff.data[dataFieldName];
                    }
                    newWertstoffe[wertstoff.id] = {
                        parentId: wertstoff.id,
                        weight: wertstoff.weight,
                        rezyclatquote: wertstoff.rezyclatquote,
                        data: data
                    };
                }

                newKomponenten[komponente.id] = {
                    parentId: komponente.id,
                    name: komponente.name,
                    image: this._copyImage(komponente.image, 'verpackung_komponente', komponente.id),
                    wertstoffe: newWertstoffe
                }
            }
            return {
                uid: getUid(),
                type: 'verpackung',
                createNew: true,
                image: this._copyImage(verpackung.image, 'verpackung', verpackung.id),
                parentId: verpackung.id,
                name: verpackung.name,
                ean: verpackung.ean,
                dimensions: verpackung.dimensions,
                description: verpackung.description,
                komponenten: newKomponenten
            }
        },

        _copyImage(srcImage, srcEntityName, srcId) {
            if(!srcImage) {
                return null;
            }
            const image = {};
            for(let key in srcImage) {
                image[key] = srcImage[key];
            }
            image.copyFrom = {
                entityName: srcEntityName,
                id: srcId
            };
            return image;
        }
    }
});
