import template from "./index-page.html.twig";
import {ErrorMessage, Field, Form} from "vee-validate";

Packlab.Component.register('index-page', {
    template: template,

    mixins: [ 'form-validation', 'notifications', 'whitelabel' ],
    inject: [ 'apiClient' ],

    components: {Form, Field, ErrorMessage},

    props: {
        registerConfirm: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            isLoadingContext: false,
            context: null,
            customer: null,
            customers: [],
            isLoading: false,
            showRegisterConfirmModal: false,
            registerCustomerAccount: {},
        }
    },

    created() {
        this._updateContext();
        Packlab.Emitter.on('customer.loggedin', this._updateContext.bind(this));
        Packlab.Emitter.on('apiContextToken.expired', this._onCustomerLoggedout.bind(this));
        Packlab.Emitter.on('customer.loggedout', this._onCustomerLoggedout.bind(this));

        if(this.registerConfirm) {
            try {
                this.registerCustomerAccount = JSON.parse(window.sessionStorage.getItem('register-customer-account'));
            } catch(e) { }
            this.showRegisterConfirmModal = true;
        }
    },

    methods: {
        onLogout() {
            this.apiClient.customerLogout().then(() => {
                this.$router.push({ name: 'index' });
            });
        },
        onSwitchCustomer(customerId) {
            this.apiClient.customerSwitchSwitchCustomer(customerId).then(() => {
                window.location.href = '/';
            });
        },
        onHideRegisterConfirmModal() {
            this.showRegisterConfirmModal = false;
            this.registerCustomerAccount = null;
            this.$router.replace('/');
        },
        _updateContext() {
            this.isLoadingContext = true
            return this.apiClient.getContext().then((context) => {
                this.context = context;
                this.customer = context.customer;
                this.isLoadingContext = false;

                if(context.totalCustomers) {
                    this._loadCustomerList();
                }
                this.isLoadingContext = false;
                if(this.whitelabelKey == 'markant' && !this.customer) {
                    this._markantLogin();
                }
            }).catch(() => {
                this.isLoadingContext = false;
            });
        },
        _onCustomerLoggedout() {
            this.customer = null;
        },
        _loadCustomerList() {
            this.isLoading = true;
            this.apiClient.customerSwitchGetCustomers()
                .then((result) => {
                    this.customers = result.customers;
                })
                .finally(() => { this.isLoading = false });
        },
        _markantLogin() {
            //this.isLoadingContext = true;
        }
    }
});
