import template from "./verpackung-komponente-editor.html.twig";
import { Field, ErrorMessage } from "vee-validate";
import KomponenteWertstoff from "../../../../model/verpackung/komponente-wertstoff";

Packlab.Component.register('verpackung-komponente-editor', {
    template,
    components: { Field, ErrorMessage },
    mixins: [ 'field-rule-resolver', 'form-validation' ],

    props: {
        verpackung: {
            type: Object,
            required: true
        },
        verpackungKombi: {
            type: Object,
            required: true
        },
        komponente: {
            type: Object,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            asRow: false
        }
    },

    methods: {
        dump(wertstoff) {
            console.log('wertstoff', wertstoff);
        },
        addWertstoff() {
            this.komponente.wertstoffe.push(new KomponenteWertstoff(this.komponente));
        },
        removeWertstoff(wertstoffIndex) {
            this.komponente.wertstoffe.splice(wertstoffIndex, 1);
        },
        rezyclatquoteRule(rezyclatquote) {
            if(rezyclatquote < 0 || rezyclatquote > 100) {
                return this.$t('Bitte geben Sie die Rezyclatquote zwischen 0% und 100% ein');
            }
            return true;
        }
    }
});
