import template from "./verpackung-gruppe-list-page.html.twig";

Packlab.Component.register('verpackung-gruppe-list-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            isLoading: false,
            verpackungsGruppen: null
        }
    },

    computed: {
        columns() {
            return [
                {
                    field: 'name',
                    label: this.$t('Bezeichnung')
                },
                /*{
                    field: 'recyclingQuote',
                    label: 'recyclingQuote',
                    type: 'percentage'
                },*/
                {
                    field: 'certificate',
                    label: 'certificate',
                    align: 'center'
                },
                {
                    field: 'vpRuleset.name',
                    label: this.$t('Mindeststandard'),
                },
                {
                    field: 'created',
                    label: this.$t('hinzugefügt'),
                    type: 'datetime'
                },
                {
                    field: 'changed',
                    label: this.$t('geändert'),
                    type: 'datetime'
                }
            ];
        }
    },

    created() {
        this.loadVerpackungsgruppen();
    },

    methods: {
        loadVerpackungsgruppen() {
            this.isLoading = false;
            this.apiClient.getVerpackungsGruppen()
                .then((result) => {
                    this.verpackungsGruppen = result.verpackungsGruppen;
                })
                .catch(() => { this.isLoading = false; });
        },

        onClickCell({ field, row }) {
            if(field !== "certificate") {
                return;
            }
            const certificate = row?._certificate_data?.value || null;
            if(!certificate) {
                return;
            }
            this.apiClient.getVerpackungZertifikatPdf(certificate.objectName, certificate.objectId)
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = `data:${response.fileType};base64,${response.contents}`;;
                    link.setAttribute('download', response.fileName);
                    document.body.appendChild(link);
                    link.click()
                    this.isPreparingDownload = false;
                })
        }
    }
});