import Verpackung from "../app/model/verpackung/verpackung";
import VerpackungTyp from "../app/model/verpackung/verpackung-typ";
import VerpackungKombi from "../app/model/verpackung/verpackung-kombi";

export default class VerpackungService {
    /**
     *
     * @param {ApiClient} apiClient
     */
    constructor(apiClient) {
        this.apiClient = apiClient;
    }

    loadVerpackung(verpackungId, kombi = true) {
        return new Promise((resolve, reject) => {
            this.apiClient.getVerpackung(verpackungId, kombi)
                .then((result) => {
                    this.apiClient.getVerpackungRuleset(kombi ? result.verpackungKombi.rulesetId : result.verpackungTyp.rulesetId)
                        .then((ruleset) => {
                            if(!kombi) {
                                const verpackungTyp = new VerpackungTyp();
                                verpackungTyp.load(result.verpackungTyp, ruleset);
                                resolve({ verpackungTyp, ruleset });
                            } else {
                                const verpackungKombi = new VerpackungKombi();
                                verpackungKombi.verpackungId = verpackungId;
                                verpackungKombi.load(result.verpackungKombi, ruleset);
                                resolve({ verpackungKombi, ruleset });
                            }

                        })
                        .catch(reject);

                })
                .catch(reject);
        });
    }

    saveVerpackungKombi(verpackungKombi) {
        return this.apiClient.postRequest('verpackung', { verpackungKombi: verpackungKombi.getJson() });
    }
}