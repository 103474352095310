import template from "./xlsx-download-btn.html.twig";

Packlab.Component.register('xlsx-download-btn', {
    template,

    inject: [ 'apiClient' ],

    props: {
        type: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            isDownloading: false
        }
    },

    methods: {
        onDownload() {
            this.isDownloading = true;
            this.apiClient.xlsxReportDownload(this.type)
                .then((result) => {
                    const link = document.createElement('a');
                    link.href = `data:${result.contentType};base64,${result.data}`;
                    link.setAttribute('download', result.filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    this.isDownloading = false;
                })
                .catch(() => this.isDownloading = false);
        }
    }
});