import template from "./contract-cancellation-page.html.twig";
import { Form, Field, ErrorMessage } from "vee-validate";

Packlab.Component.register('contract-cancellation-page', {
    template,
    components: { Form, Field, ErrorMessage },

    mixins: [ 'placeholder', 'form-validation' ],
    inject: [ 'apiClient' ],

    data() {
        return {
            isLoading: false,
            contractCancelling: false,
            contractCancelled: false,
            contract: null,
            nextCancellationDate: null,
            cancellationReason: '',
            customerCancellationReason: '',
            contractCancelConfirmCheck: false
        }
    },

    computed: {
        contractId() {
            return this.$route?.params?.contractId;
        },
        cancellationReasons() {
            return [
                {
                    key: 'attractive-price-competitor',
                    label: this.$tc('Attraktiverer Preis bei einem Wettbewerber')
                },
                {
                    key: 'precautionary-cancellation',
                    label: this.$tc('vorsorgliche Kündigung')
                },
                {
                    key: 'going-out-of-business',
                    label: this.$tc('Geschäftsaufgabe')
                },
                {
                    key: 'dissatisfied-with-service',
                    label: this.$tc('unzufrieden mit Service')
                },
                {
                    key: 'other',
                    label: this.$tc('anderer Grund')
                }
            ];
        },

    },

    created() {
        this.loadContract();
    },

    methods: {
        loadContract() {
            this.isLoading = true;
            this.apiClient.getNextContractCancellationDate(this.contractId)
                .then((result) => {
                    this.contract = result.contract;
                    this.nextCancellationDate = result.nextCancellationDate;
                    this.contractCancelled = result.isCancelled;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        onCancelContract() {
            this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                this.contractCancelling = true;
                this.apiClient.cancelContract(this.contractId, this.cancellationReason, this.customerCancellationReason)
                    .then(() => {
                        this.contractCancelled = true;
                        this.contractCancelling = false;
                    })
                    .catch(() => { this.contractCancelling = false; });
            });
        }
    }
});
