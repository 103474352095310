import template from "./backoffice-login-page.html.twig";

Packlab.Component.register('backoffice-login-page', {
    template: template,

    inject: [ 'apiClient' ],

    computed: {
        contextToken() {
            return this.$route.params.contextToken;
        },
        secureToken() {
            return this.$route.params.secureToken;
        }
    },

    data() {
        return {
            isLoading: true
        }
    },



    created() {
        this.apiClient.backofficeLogin(this.contextToken, this.secureToken)
            .finally(() => { this.$router.replace('/') });
    }
});