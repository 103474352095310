import template from "./verpackung-import-btn.html.twig";
import { Form, ErrorMessage, Field } from "vee-validate";

Packlab.Component.register('verpackung-import-btn', {
    template,
    components: { Form, ErrorMessage, Field },
    inject: [ 'apiClient' ],

    data() {
        return {
            isImporting: false,
            showModal: false,
            importFile: null,
            errors: []
        }
    },

    methods: {
        onOpenModal() {
            this.showModal = true;
        },
        onHideModal() {
            this.showModal = false;
        },
        isValidFile(file) {
            if(!file) {
                return this.$t('Bitte wählen Sie eine *.json Datei aus');
            }
            return true
        },
        onImport() {
            this.$refs.form.validate().then((result) => {
                this.clearErrors();
                if(!result.valid) {
                    return;
                }
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    try {
                        const json = JSON.parse(e.target.result);
                        this.isImporting = true;
                        this.apiClient.importVerpackungen(json).then((result) => {
                            if(!result.success) {
                                console.error(result.errors);
                                this.errors.push(this.$t('Die Datei konnte nicht importiert werden'));
                            } else {
                                location.reload();
                            }
                        }).finally(() => this.isImporting = false);
                    } catch(e) {
                        console.error(e, e.target.result);
                        this.errors.push(this.$t('Die Datei konnte nicht gelesen werden'));
                    }
                };
                fileReader.readAsText(this.importFile);
            });
        },
        clearErrors() {
            this.errors = [];
        }
    }
});