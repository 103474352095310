import { createStore } from "vuex";


export default createStore({
   state: {
       toasts: [],
       whitelabel: null
   },
   mutations: {
       publishToast(state, toast) {
           state.toasts.push(toast);
       },
       removeToast(state, index) {
           state.toasts.splice(index, 1);
       },
       setWhitelabel(state, whitelabel) {
           state.whitelabel = whitelabel;
       }
   },
   actions: {
       publishToast(context, toast) {
           context.commit('publishToast', toast);
       },
       removeToast(context, index) {
           context.commit('removeToast', index);
       },
       setWhitelabel(context, whitelabel) {
           context.commit('setWhitelabel', whitelabel);
       }
   },
    getters: {
        getToats: (state) => (route = null) => {
            return state.toasts;
        },
        getWhitelabel: (state) => {
            return state.whitelabel;
        }
    }
});