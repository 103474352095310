import template from "./verpackung-checker-wertstoff-step.html.twig";
import {withScopeId} from "vue";

Packlab.Component.register('verpackung-checker-wertstoff-step', {
    template,

    inject: [ 'apiClient' ],

    props: {
        verpackung: {
            type: Object,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        },
        checkerStep: {
            type: Object,
            required: true
        },
        checkerStepValues: {
            type: Object,
            required: true
        }
    },

    computed: {
        komponenten() {
            return this.verpackung.komponenten.concat(this.verpackung.weitereKomponenten);
        }
    },

    data() {
        return {
            isLoading: false
        }
    },

    methods: {
        getKomponenteName(komponente) {
            if(komponente.key === 'custom' && komponente.isCustomInput) {
                return komponente.customName;
            } else if(komponente.key === 'custom') {
                return this.ruleset.getKomponentenNameTitle(komponente.nameKey);
            } else {
                const verpackunTyp = this.ruleset.getVerpackungTyp(this.verpackung.verpackungTypKey);
                const verpackungTypGruppe = this.ruleset.getVerpackungTypGruppe(verpackunTyp.verpackungTypGruppe);
                const verpackungTypKomponente = verpackungTypGruppe.komponenten[komponente.key] || null;
                return verpackungTypKomponente['title'] || komponente.description;
            }
        },

        getWertstoffName(wertstoff) {
            return this.ruleset.getWertstoffTitle(wertstoff.wertstoff);
        },

        getWertstoffRuleGroups(wertstoff) {
            return this.checkerStep.ruleGroups[wertstoff.id] || [];
        },

        getWertstoffCheckerStepValues(wertstoff) {
            if(typeof this.checkerStepValues[wertstoff.id] === 'undefined') {
                this.checkerStepValues[wertstoff.id] = {};
            }
            return this.checkerStepValues[wertstoff.id];
        }
    }
});