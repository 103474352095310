import template from "./customer-account-form.html.twig";

import { Form } from "vee-validate";

Packlab.Component.register('customer-account-form', {
    template,
    components: { Form },

    mixins: [ 'form-validation' ],

    props: {
        customerAccount: {
            type: Object,
            required: true
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        },
        isEdit: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        validate() {
            return this.$refs.form.validate();
        },
        notifyFormErrors(errors) {
            this.setCustomFormErrors(this.$refs.form, errors);
        }
    }
});
