import template from "./verpackung-zertifikat-btn.html.twig";
import { Form, Field, ErrorMessage } from "vee-validate";

Packlab.Component.register('verpackung-zertifikat-btn', {
    template,

    components: { Form, Field, ErrorMessage },
    inject: [ 'apiClient' ],
    mixins: [ 'form-validation', 'placeholder' ],

    props: {
        objectType: {
            type: String,
            required: true
        },
        objectId: {
            type: String,
            required: true
        }
    },

    computed: {
        showGenZertifikatBtn() {
            return !this.zertifikat;
        },
        showTestBtn() {
            return Packlab.Config.testing === true;
        },
        zertifikatTestUrl() {
            return Packlab.Config.apiUrl + `/packlab/verpackung-zertifikat/${this.objectType}/${this.objectId}/pdf-test`
        }
    },

    data() {
        return {
            enableBtn: false,
            isLoading: false,
            isGenerating: false,
            isPreparingDownload: false,
            chargeable: false,
            totalCertificates: 0,
            usedCertificates: 0,
            totalFreeCertificates: 0,
            usedFreeCertificates: 0,
            unlimitedCertificates: false,
            standDate: null,
            zertifikat: null,
            showGenerateZertifikatModal: false,
            contactPerson: {}
        }
    },

    created() {
        this.loadZertifikat();
    },

    methods: {
        loadZertifikat() {
            this.isLoading = true;

            this.apiClient.getVerpackungZertifikat(this.objectType, this.objectId)
                .then((result) => {
                    this.enableBtn = result.enableBtn;
                    this.zertifikat = result.zertifikat;
                    this.chargeable = result.chargeable;
                    this.totalCertificates = result.totalCertificates;
                    this.usedCertificates = result.usedCertificates;
                    this.totalFreeCertificates = result.totalFreeCertificates;
                    this.usedFreeCertificates = result.usedFreeCertificates;
                    this.unlimitedCertificates = result.unlimitedCertificates;
                    this.standDate = result.standDate;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        onGenerateZertifikat() {
            this.isGenerating = true;
            this.saveContactPerson().
                then(() => {
                    this.apiClient.generateVerpackungZertifikat(this.objectType, this.objectId)
                    .then(() => {
                        this.loadZertifikat();
                        this.isGenerating = false;
                        this.$refs.enerateZertifikatModal.hide();
                    })
                    .catch(() => { this.isGenerating = false; });
                })
                .catch(() => { this.isGenerating = false; });
        },
        onStartCheckout() {
            this.isGenerating = true;
            this.saveContactPerson().
                then(() => {
                    this.$refs.enerateZertifikatModal.hide();
                    this.$router.push({ name: 'verpackung-zertifikat-cart', params: { objectType: this.objectType, objectId: this.objectId }});
                })
                .finally(() => { this.isGenerating = false; });
        },
        onDownloadZertifikat() {
            this.isPreparingDownload = true;
            this.apiClient.getVerpackungZertifikatPdf(this.objectType, this.objectId)
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = `data:${response.fileType};base64,${response.contents}`;;
                    link.setAttribute('download', response.fileName);
                    document.body.appendChild(link);
                    link.click()
                    this.isPreparingDownload = false;
                })
                .catch(() => { this.isPreparingDownload = false; });
        },
        onDownloadAttachments() {
            this.isPreparingDownload = true;
            this.apiClient.getVerpackungZertifikatAttachments(this.objectType, this.objectId)
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = `data:${response.fileType};base64,${response.contents}`;;
                    link.setAttribute('download', response.fileName);
                    document.body.appendChild(link);
                    link.click()
                    this.isPreparingDownload = false;
                })
                .catch(() => { this.isPreparingDownload = false; });
        },
        onShowGenerateZertifikatModal() {
            this.isLoading = true;
            this.apiClient.getVerpackungZertifikatContactPerson(this.objectType, this.objectId)
                .then((result) => {
                    this.contactPerson = result;
                    this.showGenerateZertifikatModal = true;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });

        },
        onGenerateZertifikatModalClosed() {
            this.showGenerateZertifikatModal = false;
        },

        saveContactPerson() {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate().then((result) => {
                    if(!result.valid) {
                        reject();
                        return;
                    }
                    this.apiClient.saveVerpackungZertifikatContactPerson(this.objectType, this.objectId, this.contactPerson.contactEmail, this.contactPerson.contactPerson)
                        .then(resolve)
                        .catch(reject);
                });
            });

        }
    }
});