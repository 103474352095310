import axios from "axios";
import Ruleset from "../app/model/ruleset";

export default class ApiClient {
    constructor(config) {
        this._apiUrl = (config.apiUrl.endsWith('/') ? config.apiUrl.slice(0, -1) : config.apiUrl);
        this._apiKey = config.apiKey;
        this._apiContextTokenTtl = 21600; //6 Stunden
        this._apiAerpackungRulesetTtl = 600; //10 Minuten
        this._apiContextTokenName = 'packlab-api-context-token';
        this._apiContextToken = this._getApiContextToken();
        this._context = null;
        this._whitelabelKey = null;
        this._verpackungRuleset = {};
    }

    logError(type, error) {
        const client = this._createClient();
        client.post(this._getApiActionUrl('system/log'), {
            type, error
        });
    }

    getRequest(endpoint, query = {}, options = {}) {
        return new Promise((resolve, reject) => {
            const client = this._createClient();
            options.params = query;
            client.get(this._getApiActionUrl(endpoint), options)
                .then((response, aa) => {
                    this._updateContextToken(response);
                    resolve(response.data || {});
                })
                .catch((e) => {
                    console.error(e);
                    reject(e);
                });
        });
    }

    postRequest(endpoint, data = {}) {
        return new Promise((resolve, reject) => {
            const client = this._createClient();
            client.post(this._getApiActionUrl(endpoint), this._cleanValidationReport(data))
                .then((response) => {
                    this._updateContextToken(response);
                    resolve(response.data || {});
                })
                .catch(reject);
        });
    }

    patchRequest(endpoint, data = {}) {
        return new Promise((resolve, reject) => {
            const client = this._createClient();
            client.patch(this._getApiActionUrl(endpoint), this._cleanValidationReport(data))
                .then((response) => {
                    this._updateContextToken(response);
                    resolve(response.data || {});
                })
                .catch(reject);
        });
    }

    deleteRequest(endpoint, data = {}) {
        return new Promise((resolve, reject) => {
            const client = this._createClient();
            client.delete(this._getApiActionUrl(endpoint), this._cleanValidationReport(data))
                .then((response) => {
                    this._updateContextToken(response);
                    resolve(response.data || {});
                })
                .catch(reject);
        });
    }

    setContextToken(token) {
        if(this._apiContextToken != token) {
            this._context = null;
        }
        this._setApiContextToken(token);
    }

    getContext() {
        return this.getRequest('context').then((context) => {
            Packlab.Emitter.emit('context.loaded', {
                context
            });
            return context;
        });
        /*return new Promise((resolve, reject) => {
            if(this._context) {
                resolve(this._context);
            } else {
                this.getRequest('context').then((context) => {
                    this._context = context;
                    Packlab.Emitter.emit('context.loaded', {
                        context
                    });
                    resolve(this._context);
                }).catch(reject);
            }
        });*/
    }

    getProducts(getCertificateProducts = false, getRateProducts = true) {
        return this.getRequest('shop/product');
    }

    getProduct(productNumber) {
        return this.getRequest(`shop/product/${productNumber}`);
    }

    createCart(productId) {
        return this.postRequest('shop/cart', {
            productId
        });
    }

    applyVoucher(voucherCode) {
        return this.postRequest('shop/cart/voucher/' + voucherCode);
    }

    removeVoucher(itemId) {
        return this.deleteRequest('shop/cart/voucher/' + itemId);
    }

    createOrder(args) {
        return this.postRequest('shop/cart/order', args);
    }

    getOrders() {
        return this.getRequest('order');
    }

    getOrder(orderId) {
        return this.getRequest(`order/${orderId}`);
    }

    getOrderPdf(orderId) {
        return this.getRequest(`order/${orderId}/pdf`);
    }

    getOrderPdfDeepLink(orderId, deeplinkCode) {
        return this.getRequest(`order/${orderId}/${deeplinkCode}/pdf`);
    }

    getCountries() {
        return this.getRequest('system/country');
    }

    getPaymentMethods() {
        return this.getRequest('shop/payment-methods');
    }

    getVerpackungZertifikatCart(objectType, objectId) {
        return this.getRequest(`shop/checkout/verpackung-zertifikat/${objectType}/${objectId}`);
    }

    customerCreate(customerAccount) {
        return this.postRequest('customer/create', { customerAccount });
    }

    customerRegister(customer) {
        const customerData = Object.assign({}, customer);
        if(!customer.differentInvoiceAddress) {
            delete customerData.invoiceAddress;
        }
        return this.postRequest('customer/register', customerData);
    }

    customerUpdate(customer) {
        const customerData = Object.assign({}, customer);
        if(!customer.differentInvoiceAddress) {
            delete customerData.invoiceAddress;
        }
        return this.patchRequest('customer/register', customerData);
    }

    customerOptin(token, hash, password = null) {
        return this.postRequest('customer/register/optin', {
            token: token,
            hash: hash,
            password: password
        });
    }

    customerLogin(email, password) {
        return this.postRequest('customer/login', { login: email, password: password })
            .then((response) => {
                Packlab.Emitter.emit('customer.loggedin');
                return response;
            });
    }

    backofficeLogin(contextToken, secureToken) {
        return this.postRequest('customer/backoffice-login', { contextToken, secureToken })
            .then((response) => {
                Packlab.Emitter.emit('customer.loggedin');
                return response;
            });
    }

    customerLogout() {
        return this.postRequest('customer/logout').then((response) => {
            this._deleteApiContextToken();
            Packlab.Emitter.emit('customer.loggedout');
            return response;
        });
    }

    getCustomerProfile() {
        return this.getRequest('customer/profile');
    }

    saveCustomerProfile(customer) {
        return this.postRequest('customer/profile', customer);
    }

    createCustomerAccount(customerAccount) {
        return this.postRequest(`customer-account`, customerAccount);
    }

    getCustomerAccount(id) {
        return this.getRequest(`customer-account/${id}`);
    }

    deleteCustomerAccount(id) {
        return this.deleteRequest(`customer-account/${id}`);
    }

    updateCustomerAccount(id, customerAccount) {
        return this.postRequest(`customer-account/${id}`, customerAccount);
    }

    saveCustomerAccountEmail(id, currentPassword, newEmail) {
        return this.postRequest(`customer-account/${id}/email`, {
            currentPassword: currentPassword,
            newEmail: newEmail
        });
    }

    saveCustomerAccountPassword(id, currentPassword, newPassword) {
        return this.postRequest(`customer-account/${id}/password`, {
            currentPassword: currentPassword,
            newPassword: newPassword
        });
    }

    sendCustomerAccountRecoverMail(email) {
        return this.postRequest(`customer-account-recover`, {
            email: email
        });
    }

    validateCustomerAccountRecoverLink(token, hash) {
        return this.postRequest(`customer-account-recover/validate`, {
            token: token,
            hash: hash
        });
    }

    saveCustomerAccountRecoverPassword(token, hash, password) {
        return this.postRequest(`customer-account-recover/save-password`, {
            token: token,
            hash: hash,
            password: password
        });
    }

    getCustomerAccounts() {
        return this.getRequest('customer-account');
    }

    getContracts() {
        return this.getRequest('contracts');
    }

    getContract(contractId) {
        return this.getRequest(`contract/${contractId}`);
    }

    getNextContractCancellationDate(contractId) {
        return this.getRequest(`contract/${contractId}/next-cancellation-date`);
    }

    cancelContract(contractId, cancellationReason, customerCancellationReason) {
        return this.postRequest(`contract/${contractId}/cancel`, {
            cancellationReason,
            customerCancellationReason
        });
    }

    getVerpackungRuleset(id = 0) {
        return new Promise((resolve, reject) => {
            if(typeof this._verpackungRuleset[id] === "object") {
                resolve(this._verpackungRuleset[id].ruleset);
            } else {
                this._loadVerpackungRuleset(id).then(resolve).catch(reject);
            }
        });
    }

    getVerpackungen(query = {}) {
        return this.getRequest(`verpackung`, query);
    }
    getVerpackung(verpackungId, kombi = true) {
        return this.getRequest(`verpackung/${verpackungId}`, {
            kombi
        });
    }

    getVerpackungsGruppen() {
        return this.getRequest(`verpackung-gruppe`);
    }

    getVerpackungsGruppe(verpackungGruppeId) {
        return this.getRequest(`verpackung-gruppe/${verpackungGruppeId}`);
    }

    deleteVerpackungsGruppe(verpackungGruppeId) {
        return this.deleteRequest(`verpackung-gruppe/${verpackungGruppeId}`);
    }

    createVerpackungGruppeChild(verpackungGruppeId, childVerpackung) {
        return this.postRequest(`verpackung-gruppe/${verpackungGruppeId}/child`, childVerpackung);
    }

    updateVerpackungGruppeChild(verpackungGruppeId, childVerpackung) {
        return this.patchRequest(`verpackung-gruppe/${verpackungGruppeId}/child`, childVerpackung);
    }

    deleteVerpackungGruppeChild(verpackungGruppeId, childVerpackungId) {
        return this.deleteRequest(`verpackung-gruppe/${verpackungGruppeId}/child/${childVerpackungId}`);
    }

    saveVerpackungsGruppe(verpackungGruppeId, verpackungGruppe) {
        return this.patchRequest(`verpackung-gruppe/${verpackungGruppeId}`, verpackungGruppe);
    }

    deleteVerpackungsGruppe(verpackungGruppeId) {
        return this.deleteRequest(`verpackung-gruppe/${verpackungGruppeId}`);
    }

    deleteVerpackung(verpackungId) {
        return this.deleteRequest(`verpackung/${verpackungId}`);
    }

    copyVerpackung(verpackungId) {
        return this.postRequest(`verpackung/${verpackungId}/copy`);
    }

    saveTotalVerpackungen(verpackungId, totalVerpackungen) {
        return this.postRequest(`verpackung/${verpackungId}/total-verpackungen`, {
            totalVerpackungen: totalVerpackungen
        });
    }

    completeOrder(orderId, orderDeepLinkCode) {
        return this.postRequest(`payment/${orderId}/${orderDeepLinkCode}/complete`);
    }

    getCustomerSettings() {
        return this.getRequest('customer/settings');
    }

    saveCustomerSettings(settings) {
        return this.patchRequest('customer/settings', { settings });
    }

    getBmg(verpackungId) {
        return this.getRequest(`verpackung/${verpackungId}/checker/bmg`);
    }

    getKombiVerpackungen() {
        return this.getRequest('verpackung-kombi');
    }

    getKombiVerpackung(kombiVerpackungId) {
        return this.getRequest(`verpackung-kombi/${kombiVerpackungId}`);
    }

    deleteKombiVerpackung(kombiVerpackungId) {
        return this.deleteRequest(`verpackung-kombi/${kombiVerpackungId}`);
    }

    saveKombiVerpackung(kombiVerpackungId, verpackungKombi, image) {
        return this.patchRequest(`verpackung-kombi/${kombiVerpackungId}`, { verpackungKombi, image });
    }

    getMaterialSelectWizard(rulesetid) {
        return this.getRequest(`verpackung/ruleset/${rulesetid}/material-select-wizard`)
    }

    getVerpackungZertifikat(objectName, objectId) {
        return this.getRequest(`verpackung-zertifikat/${objectName}/${objectId}`);
    }

    hasCertificate(objectName, objectId) {
        return this.getRequest(`verpackung-zertifikat/${objectName}/${objectId}/has-certificate`);
    }

    generateVerpackungZertifikat(objectName, objectId) {
        return this.postRequest(`verpackung-zertifikat/${objectName}/${objectId}/generate`);
    }

    getVerpackungZertifikatContactPerson(objectName, objectId) {
        return this.getRequest(`verpackung-zertifikat/${objectName}/${objectId}/contact-person`, {})
    }

    saveVerpackungZertifikatContactPerson(objectName, objectId, contactEmail, contactPerson) {
        return this.postRequest(`verpackung-zertifikat/${objectName}/${objectId}/contact-person`, {
            contactEmail, contactPerson
        })
    }

    getVerpackungZertifikatPdf(objectName, objectId) {
        return this.getRequest(`verpackung-zertifikat/${objectName}/${objectId}/pdf`, {})
    }

    getVerpackungZertifikatAttachments(objectName, objectId) {
        return this.getRequest(`verpackung-zertifikat/${objectName}/${objectId}/attachments`, {})
    }

    uploadFile(fileId, file, uploadProgress = null) {
        const formData = new FormData();
        formData.append("fileId", fileId);
        formData.append("file", file);

        return new Promise((resolve, reject) => {
            const client = this._createClient();
            client.post(this._getApiActionUrl('media/upload'), formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: uploadProgress ? uploadProgress : () => {}
            })
            .then((response) => {
                this._updateContextToken(response);
                resolve(response.data || {});
            })
            .catch(reject);
        });
    }

    customerSwitchGetCustomers() {
        return this.getRequest('customer-switch');
    }

    customerSwitchSwitchCustomer(customerId) {
        return this.postRequest('customer-switch', { customerId });
    }

    getAvVertrag() {
        return this.getRequest('av-vertrag');
    }

    xlsxReportDownload(type) {
        return this.getRequest(`report/${type}/download`);
    }

    exportAllVerpackungen() {
        return this.getRequest('verpackungen/export-all');
    }

    importVerpackungen(json) {
        return this.postRequest('verpackungen/import', json);
    }

    getWhitelabel(whitelabelKey) {
        return new Promise((resolve, reject) => {
            this.getRequest(`system/whitelabel`, {
                whitelabelKey
            }).then((result) => {
                this._whitelabelKey = whitelabelKey;
                resolve(result);
            }).catch(reject);
        });
    }

    whitelabelAuth(whitelabelKey, user) {
        return this.postRequest('system/whitelabel/auth?=' + whitelabelKey, user)
        .then((response) => {
            Packlab.Emitter.emit('customer.loggedin');
            return response;
        });
    }

    _cleanValidationReport(json) {
        const cleanedJson = {};
        for(let key in json) {
            if(key === 'validationReport') {
                continue;
            }
            if(typeof json[key] === 'object' && json[key] !== null) {
                json[key] = this._cleanValidationReport(json[key]);
            }
            cleanedJson[key] = json[key];
        }
        return cleanedJson;
    }

    _loadVerpackungRuleset(id = 0) {
        return new Promise((resolve, reject) => {
            this.getRequest(id > 0 ? `verpackung/ruleset/${id}` : 'verpackung/ruleset')
                .then((result) => {
                    const ruleset = new Ruleset(result.rulesetId, result.name, result.status, result.isDefault, result.ruleset);
                    this._verpackungRuleset[id] = {
                        ruleset: ruleset,
                        expiry: Date.now() + (this._apiAerpackungRulesetTtl * 1000),
                    }
                    resolve(ruleset);
                })
                .catch(reject);
        });
    }

    _deleteApiContextToken() {
        localStorage.removeItem(this._apiContextTokenName);
        Packlab.Emitter.emit('apiContextToken.deleted');
        this._apiContextToken = null;
    }

    _getApiContextToken() {
        let apiContextTokenStr = localStorage.getItem(this._apiContextTokenName) || null;
        if(!apiContextTokenStr) {
            return null;
        }
        try {
            const apiContextToken = JSON.parse(apiContextTokenStr);
            if(Date.now() > apiContextToken.expiry) {
                this._deleteApiContextToken();
                Packlab.Emitter.emit('apiContextToken.expired');
                return null;
            }
            return apiContextToken.value;
        } catch(e) { console.error(e); }
        return null;
    }

    /**
     * @returns {AxiosInstance}
     * @private
     */
    _createClient() {
        const headers = {
            'packlab-api-key': this._apiKey,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if(this._apiContextToken) {
            headers['packlab-api-context-token'] = this._apiContextToken;
        }
        if(this._whitelabelKey) {
            headers['packlab-whitelabel-key'] = this._whitelabelKey;
        }
        const client = axios.create({
            headers: headers
        });
        client.interceptors.response.use(response => response, error => {
            const { response: { status, data: { errors, data } } } = error;
            if(status === 401) {
                window.location.href = "/";
                return;
            }
            if(status === 500) {
                if(errors) {
                    errors.forEach(singleError => {
                        Packlab.Emitter.emit('notification.createError', {
                            code: singleError.code,
                            title: singleError.title,
                            message: singleError.detail
                        });
                    });
                }
                if(status === 500) {
                    console.error('api-error', { errors: errors || {}, error, status });
                }
            }
            return Promise.reject({ errors: errors || {}, error });
        });
        return client;
    }

    _setApiContextToken(apiContextToken) {

        if(this._apiContextToken !== apiContextToken) {
            Packlab.Emitter.emit('apiContextToken.changed', {apiContextToken});
        }
        this._apiContextToken = apiContextToken;
        localStorage.setItem(this._apiContextTokenName, JSON.stringify({
            expiry: Date.now() + (this._apiContextTokenTtl * 1000),
            value: apiContextToken
        }));
    }

    _updateContextToken(response) {
        if(typeof response.data['packlab-api-context-token'] != "undefined") {
            this.setContextToken(response.data['packlab-api-context-token']);
        }
    }

    _getApiActionUrl(endpoint) {
        return this._apiUrl + '/packlab-api/' + endpoint;
    }
}
