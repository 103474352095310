Packlab.Mixin.register('whitelabel', {
    inject: [ 'whitelabel' ],

    computed: {
        whitelabelKey() {
            return this.whitelabel.getWhitelabel()?.key;
        }
    },
    methods: {
        getWhitelabel() {
            return this.whitelabel.getWhitelabel();
        }
    }
});