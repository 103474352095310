import template from "./verpackung-checker-not-recyclable-rule.html.twig";

Packlab.Component.register('verpackung-checker-not-recyclable-rule', {
    template,

    props: {
        rule: {
            type: Object,
            required: true
        }
    }
});
