import template from "./cart-checkout-page.html.twig";

Packlab.Component.register('cart-checkout-page', {
    template,

    computed: {
        productNumber() {
            return this.$route.params.productNumber || '';
        }
    }
});
