import template from "./customer-profile-page.html.twig";

Packlab.Component.register('customer-profile-page', {
    template,

    inject: [ 'apiClient' ],

    data() {
        return {
            customer: null,
            isLoading: false
        }
    },

    created() {
        this.isLoading = true;
        this.apiClient.getCustomerProfile()
            .then((customer) => {
                this.isLoading = false;
                this.customer = customer;
            })
            .catch(() => {
                this.isLoading = false;
            });
        ;
    }
});
