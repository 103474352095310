import template from "./packlab-accordion.html.twig";
import {getUid} from "../../../../utils/utils";

Packlab.Component.register('packlab-accordion', {
    template,

    props: {
        itemKeys: {
            type: Array,
            required: true,
        },
        showItemKeys: {
            type: [ Array, String ],
            required: false,
            default: null
        },
        id: {
            type: String,
            required: false,
            default: null
        },
        openEachItem: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            accordionId: this.id || `accordion-${getUid()}`
        }
    },

    methods: {
        defaultShow(itemKey) {
            if(this.showItemKeys === null) {
                return false;
            }
            if(Array.isArray(this.showItemKeys)) {
                return this.showItemKeys.indexOf(itemKey) !== -1;
            }
            return this.showItemKeys === itemKey;
        },
        getParentId(itemKey) {
            return this.openEachItem ? `${this.accordionId}-${itemKey}` : `${this.accordionId}`
        }
    }
});