import template from "./customer-password-form.html.twig";
import { Field, ErrorMessage } from "vee-validate";

Packlab.Component.register('customer-password-form', {
    template,

    components: { Field, ErrorMessage },

    mixins: [ 'placeholder' ],

    props: {
        passwordChange: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    watch: {
        password() {
            this._validatePassword();
        }
    },
    data() {
        return {
            currentPassword: '',
            password: '',
            password2: '',
            allowedSpecialChars: [ '!', '#', '-', '?', '*' ],
            validation: {
                minLength: false,
                minOneUpperCase: false,
                minOneLowerCase: false,
                minOneNumber: false,
                minOneSpecialChar: false,
                notAllowedSpecialChars: false
            }
        }
    },
    methods: {
        _isPasswordValid() {
            for(let rule in this.validation) {
                if(!this.validation[rule]) {
                    return false;
                }
            }
            return true;
        },
        _validatePassword() {
            const allowsSpecialChars = '!#\\-?\\*';
            const pwdLengthRe = new RegExp('^.{12,}$');
            const pwdUpperRe = new RegExp('[A-Z]+');
            const pwdLowerRe = new RegExp('[a-z]+');
            const pwdNumberRe = new RegExp('[0-9]+');
            const pwdSpecialRe = new RegExp('[' + allowsSpecialChars + ']+');
            const pwdAllowedCharsRe = new RegExp('^[a-zA-Z0-9' + allowsSpecialChars + ']+$');

            this.validation.minLength = pwdLengthRe.test(this.password);
            this.validation.minOneUpperCase = pwdUpperRe.test(this.password);
            this.validation.minOneLowerCase = pwdLowerRe.test(this.password);
            this.validation.minOneNumber = pwdNumberRe.test(this.password);
            this.validation.minOneSpecialChar = pwdSpecialRe.test(this.password);
            this.validation.notAllowedSpecialChars = pwdAllowedCharsRe.test(this.password);
        },
        getPasswordRestrictionClass(rule) {
            return (this.validation[rule] || false) === true ? 'success' : 'invalid';
        },
        passwordRule() {
            if(this.password === '') {
                return this.$t('Dieses Feld ist ein Pflichtfeld.');
            }
            if(!this._isPasswordValid()) {
                return this.$t('Ihr Passwort ist ungültig');
            }
            return true;
        },
        password2Rule() {
            if(this.password2 === '') {
                return this.$t('Dieses Feld ist ein Pflichtfeld.');
            }
            if(this.password !== this.password2) {
                return this.$t('Bitte wiederholen Sie Ihr Passwort');
            }
            return true;
        },
        validatePassword(form) {
            return new Promise((resolve, reject) => {
                form.validate().then((result) => {
                    if (result.valid) {
                        resolve({
                            password: this.password,
                            currentPassword: this.currentPassword,
                            result: result
                        });
                    } else {
                        reject(result)
                    }
                });
            })

        }
    }
});
