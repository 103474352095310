import template from "./verpackung-checker-verpackung-not-recyclable-step.html.twig";

Packlab.Component.register('verpackung-checker-verpackung-not-recyclable-step', {
    template,

    props: {
        verpackung: {
            type: Object,
            required: true
        },
        ruleset: {
            type: Object,
            required: true
        },
    },

    computed: {
        recyclinginfrastruktur() {
            const materialFraktion = this.ruleset.getMaterialFraktion(this.verpackung.materialFraktion);
            if(!materialFraktion) {
                return null;
            }
            return this.ruleset.getInfrastruktur(materialFraktion?.recyclinginfrastruktur);
        },
        contactPhone() {
            return Packlab.Config.contactPhone;
        },
        contactMail() {
            return Packlab.Config.contactMail;
        },
        contactUrl() {
            return Packlab.Config.contactUrl;
        }
    }
});
