import template from "./verpackung-kombi-detail-page.html.twig";

Packlab.Component.register('verpackung-kombi-detail-page', {
    template,

    inject: [ 'apiClient' ],

    computed: {
        kombiVerpackungId() {
            return this.$route.params.kombiVerpackungId;
        },
        hasParent() {
            if(!this.kombiVerpackung) {
                return false;
            }
            return this.kombiVerpackung.parentGruppeVerpackungKombiId;
        },
        hasGruppe() {
            if(!this.kombiVerpackung) {
                return false;
            }
            return this.kombiVerpackung.verpackungGruppeId;
        }
    },

    data() {
        return {
            isLoading: false,
            kombiVerpackung: null,
            ruleset: null,
            image: null,
        }
    },

    created() {
        this.loadKombiVerpackung();
    },

    methods: {
        loadKombiVerpackung() {
            this.isLoading = true;
            this.apiClient.getKombiVerpackung(this.kombiVerpackungId)
                .then((result) => {
                    this.kombiVerpackung = result.verpackungKombi;
                    this.ruleset = result.ruleset;
                    this.image = result.image;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        }
    }
});