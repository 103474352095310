import template from "./static-page.html.twig";
import abkuerzungenDeDe from "./abkuerzungen/de-DE.html.twig";
import abkuerzungenEnGB from "./abkuerzungen/en-GB.html.twig";
import agbDeDe from "./agb/de-DE.html.twig";
import agbEnGB from "./agb/en-GB.html.twig";
import datenschutzDeDe from "./datenschutz/de-DE.html.twig";
import datenschutzEnGB from "./datenschutz/en-GB.html.twig";
import glossarDeDe from "./glossar/de-DE.html.twig";
import glossarEnGB from "./glossar/en-GB.html.twig";
import herstellerSpezifikationDeDe from "./hersteller-spezifikation/de-DE.html.twig";
import herstellerSpezifikationEnGB from "./hersteller-spezifikation/en-GB.html.twig";
import impressumDeDe from "./impressum/de-DE.html.twig";
import impressumEnGB from "./impressum/en-GB.html.twig";

const { Component, Template, Application } = Packlab;
const texts = [
    {
        key: 'abkuerzungen',
        locale: 'de-DE',
        template: abkuerzungenDeDe
    },
    {
        key: 'abkuerzungen',
        locale: 'en-GB',
        template: abkuerzungenEnGB
    },

    {
        key: 'agb',
        locale: 'de-DE',
        template: agbDeDe
    },
    {
        key: 'agb',
        locale: 'en-GB',
        template: agbEnGB
    },

    {
        key: 'datenschutz',
        locale: 'de-DE',
        template: datenschutzDeDe
    },
    {
        key: 'datenschutz',
        locale: 'en-GB',
        template: datenschutzEnGB
    },

    {
        key: 'glossar',
        locale: 'de-DE',
        template: glossarDeDe
    },
    {
        key: 'glossar',
        locale: 'en-GB',
        template: glossarEnGB
    },

    {
        key: 'hersteller-spezifikation',
        locale: 'de-DE',
        template: herstellerSpezifikationDeDe
    },
    {
        key: 'hersteller-spezifikation',
        locale: 'en-GB',
        template: herstellerSpezifikationEnGB
    },

    {
        key: 'impressum',
        locale: 'de-DE',
        template: impressumDeDe
    },
    {
        key: 'impressum',
        locale: 'en-GB',
        template: impressumEnGB
    }
];

for(let t in texts) {
    const text = texts[t];
    text.templateKey = `static-${text.key}-${text.locale}`;
    Template.registerTemplate(text.templateKey, text.template);
}

Component.register('static-page', {
    template,

    props: {
        textkey: {
            type: String,
            required: true
        }
    },

    methods: {
        getText(textKey) {
            for(let t in texts) {
                const text = texts[t];
                if(text.key === textKey && text.locale === Application.getLocale()) {
                    return Template.getRenderedTemplate(text.templateKey);
                }
            }
            return '';
        }
    }
});
