import template from "./order-payment-complete-page.html.twig";

Packlab.Component.register('order-payment-complete-page', {
    template,

    inject: [ 'apiClient' ],
    mixins: [ 'placeholder' ],

    data() {
        return {
            isLoading: false,
            result: null,
            customer: null
        }
    },

    created() {
        this.completeOrder();
    },

    methods: {
        completeOrder() {
            this.isLoading = true;
            this.apiClient.completeOrder(this.$route.params.orderId, this.$route.params.orderDeepLinkCode)
                .then((result) => {
                    this.result = result;
                    this.apiClient.getContext()
                        .then((context) => {
                            this.customer = context.customer;
                        })
                        .finally(() => this.isLoading = false );
                })
                .catch(() => this.isLoading = false );
        },
        onSaveOrder() {
            this.apiClient.getOrderPdfDeepLink(this.$route.params.orderId, this.$route.params.orderDeepLinkCode)
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = `data:application/pdf;base64,${response.contents}`;;
                    link.setAttribute('download', response.fileName);
                    document.body.appendChild(link);
                    link.click()
                })
                .catch(() => { });
        }
    }
})