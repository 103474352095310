import template from "./customer-email-change-page.html.twig";
import {Form} from "vee-validate";

Packlab.Component.register('customer-email-change-page', {
    template,

    components: { Form },

    inject: [ 'apiClient' ],
    mixins: [ 'form-validation' ],

    data() {
        return {
            isSaving: false,
            passwordChange: {
                password: '',
                newEmail: '',
                newEmail2: ''
            }
        }
    },

    methods: {
        saveEmail() {
            this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                this.isSaving = true;
                this.apiClient.saveCustomerAccountEmail('me', this.passwordChange.password, this.passwordChange.newEmail)
                    .then(() => {
                        this.$router.push({ name: 'customer-profile' });
                    })
                    .catch(({ errors }) =>  {
                        this.isSaving = false;
                        this.setCustomFormErrors(this.$refs.form, errors);
                    });
            });
        },
        repeatEmailAddress(value) {
            const requiredValue = this.isRequiredField(value);
            const requiredEmail = this.isRequiredEmailField(value);
            if(requiredValue !== true) {
                return requiredValue;
            }
            if(requiredEmail !== true) {
                return requiredEmail;
            }
            if(value !== this.newEmail) {
                return this.$t('Bitte wiederholen Sie Ihre E-Mail');
            }
            return true;
        },
    }
});