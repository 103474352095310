import template from "./verpackung-kombi-edit-page.html.twig";
import { Form } from "vee-validate";

Packlab.Component.register('verpackung-kombi-edit-page', {
    template,

    components: { Form },
    mixins: [ 'form-validation', 'placeholder' ],
    inject: [ 'apiClient' ],

    computed: {
        kombiVerpackungId() {
            return this.$route.params.kombiVerpackungId;
        },
    },

    data() {
        return {
            isLoading: false,
            kombiVerpackung: null,
            image: null
        }
    },

    created() {
        this.loadKombiVerpackung();
    },

    methods: {
        loadKombiVerpackung() {
            this.isLoading = true;
            this.apiClient.getKombiVerpackung(this.kombiVerpackungId)
                .then((result) => {
                    this.kombiVerpackung = result.verpackungKombi;
                    this.image = result.image;
                    this.isLoading = false;
                })
                .catch(() => { this.isLoading = false; });
        },
        saveKombiVerpackung() {
            this.$refs.form.validate().then((result) => {
                if(!result.valid) {
                    return;
                }
                this.apiClient.saveKombiVerpackung(this.kombiVerpackungId, this.kombiVerpackung, this.image)
                    .then(() => {
                        this.$router.replace({ name: 'verpackungen-kombi-detail', params: { kombiVerpackungId: this.kombiVerpackungId } });
                    });
            });
        }
    }
});