import template from "./fonds-beitrag-edit-page.html.twig";

Packlab.Component.register('fonds-beitrag-edit-page', {
    template,

    mixins: [ 'form-validation' ],
    inject: [ 'apiClient' ],

    computed: {
        verpackungId() {
            return this.$route.params.verpackungId;
        },
    },

    data() {
        return {
            isSaving: false,
            verpackung: null
        }
    },

    methods: {
        onVerpackungLoaded(verpackung) {
            this.verpackung = verpackung;
        },
        onStartSave() {
            this.isSaving = true;
        },
        onSaved(verpackung) {
            this.verpackung = verpackung;
            this.isSaving = false;
        },
        onSave() {
            this.$refs.verpackungFondCalculator.save();
        }
    }
});