import template from "./verpackung-wertstoff-data-fields.html.twig";

Packlab.Component.register('verpackung-wertstoff-data-fields', {
    template,

    props: {
        wertstoff: {
            type: Object,
            required: true
        },
        isReadonly: {
            type: Boolean,
            required: false,
            default: false
        },
        ruleset: {
            type: Object,
            required: false
        },
        visibilityScope: {
            type: Object,
            required: false
        }
    }
});
