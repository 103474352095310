import {isEmpty} from "../../utils/utils";

export default class Ruleset {

    constructor(rulesetId, name, status, isDefault, ruleset) {
        this.rulesetId = rulesetId;
        this.name = name;
        this.status = status;
        this.isDefault = isDefault;
        this.ruleset = ruleset;
    }

    getEnums() {
        return this.ruleset?.enums?.values;
    }

    getEnum(key) {
        return this.ruleset?.enums?.values[key] || null;
    }

    getEnumTitle(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.enums?.values[key]?.title, defaultTitle);
    }

    getEnumValues(enumKey) {
        return this.ruleset?.enums?.values[enumKey]?.values || [];
    }

    getEnumValue(enumKey, valueKey) {
        return this.ruleset?.enums?.values[enumKey]?.values[valueKey] || [];
    }

    getEnumValueTitle(enumKey, valueKey, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = valueKey;
        }
        return this._getTitle(this.ruleset?.enums?.values[enumKey]?.values[valueKey]?.title, defaultTitle);
    }

    findEnumValue(valueKey) {
        for (let e in this.ruleset?.enums?.values || []) {
            const rulesetEnum = this.ruleset.enums.values[e];
            for (let v in rulesetEnum.values) {
                if (v === valueKey) {
                    return rulesetEnum.values[v];
                }
            }
        }
        return null;
    }

    getFieldRules() {
        return this.ruleset?.fieldRules?.values;
    }

    getInfrastrukturen() {
        return this._getSortedValues(this.ruleset?.recyclinginfrastrukturen);
    }

    getInfrastruktur(key) {
        return this.ruleset?.recyclinginfrastrukturen.values[key] || null;
    }

    getInfrastrukturTitle(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.recyclinginfrastrukturen.values[key]?.title, defaultTitle);
    }

    getKomponentenNames() {
        return this._getSortedValues(this.ruleset?.komponentenNames);
    }

    getKomponentenName(key) {
        return this.ruleset?.komponentenNames.values[key] || null;
    }

    getKomponentenNameTitle(key, defaultName = undefined) {
        if(defaultName === undefined) {
            defaultName = key;
        }
        return this._getTitle(this.ruleset?.komponentenNames.values[key]?.name, defaultName);
    }

    getWertstoffColors(wertstoffKey = null) {
        const wertstoffColors = this._getSortedValues(this.ruleset?.wertstoffColors);
        if(!wertstoffKey) {
            return wertstoffColors;
        }
        const filteredWertstoffColors = {};
        const wertstoff = this.getWertstoff(wertstoffKey);
        for(let w in wertstoffColors) {
            if(wertstoffColors[w].group != wertstoff?.wertstoffColorGroup) {
                continue;
            }
            filteredWertstoffColors[w] = wertstoffColors[w];
        }

        return filteredWertstoffColors;
    }

    getWertstoffColor(key) {
        return this.ruleset?.wertstoffColors.values[key] || null;
    }

    getWertstoffColorName(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.wertstoffColors.values[key]?.name, defaultTitle);
    }

    getMaterialFraktionen() {
        return this._getSortedValues(this.ruleset?.materialFraktionen);
    }

    getMaterialFraktion(key) {
        return this.ruleset?.materialFraktionen?.values[key] || null;
    }

    getMaterialFraktionAuswahlTitle(key) {
        const titleAuswahl = this._getTitle(this.ruleset?.materialFraktionen?.values[key]?.titleAuswahl, '');
        if(titleAuswahl != '') {
            return key + ' - ' + titleAuswahl;
        }
        return key;
    }

    getLucidMaterialgruppen() {
        return this._getSortedValues(this.ruleset?.lucidMaterialgruppen);
    }

    getLucidMaterialgruppe(key) {
        return this.ruleset?.lucidMaterialgruppen?.values[key] || null;
    }

    getLucidMaterialgruppeTitle(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.lucidMaterialgruppen?.values[key]?.title, defaultTitle);
    }

    getMaterialGruppen() {
        return this._getSortedValues(this.ruleset?.materialGruppen);
    }

    getMaterialGruppe(key) {
        return this.ruleset?.materialGruppen?.values[key] || null;
    }

    getMaterialGruppeTitle(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.materialGruppen?.values[key]?.title, defaultTitle);
    }

    getMaterialUntergruppen() {
        return this._getSortedValues(this.ruleset?.materialUntergruppen);
    }

    getMaterialUntergruppe(key) {
        return this.ruleset?.materialUntergruppen?.values[key] || null;
    }

    getMaterialUntergruppeTitle(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.materialUntergruppen?.values[key]?.title, defaultTitle);
    }

    getVerpackungTypGruppen() {
        return this._getSortedValues(this.ruleset?.verpackungTypGruppen);
    }

    getVerpackungTypGruppe(key) {
        return this.ruleset?.verpackungTypGruppen?.values[key] || null;
    }

    getVerpackungTypGruppeTitle(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.verpackungTypGruppen?.values[key]?.title, defaultTitle);
    }

    getVerpackungTypen() {
        return this._getSortedValues(this.ruleset?.verpackungTypen);
    }

    getVerpackungTyp(key) {
        return this.ruleset?.verpackungTypen?.values[key] || null;
    }

    getVerpackungTypTitle(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.verpackungTypen?.values[key]?.title, defaultTitle);
    }

    getWertstoffe() {
        return this._getSortedValues(this.ruleset?.wertstoffe);
    }

    getWertstoff(key) {
        return this.ruleset?.wertstoffe?.values[key] || null;
    }

    getWertstoffTitle(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        return this._getTitle(this.ruleset?.wertstoffe?.values[key]?.title, defaultTitle);
    }

    getWertstoffTitleAuswahl(key, defaultTitle = undefined) {
        if(defaultTitle === undefined) {
            defaultTitle = key;
        }
        let title = this._getTitle(this.ruleset?.wertstoffe?.values[key]?.titleAuswahl, null);
        if(!title) {
            title = this._getTitle(this.ruleset?.wertstoffe?.values[key]?.title, defaultTitle);
        }
        return title;
    }

    getCheckResultQuotes() {
        return this.ruleset?.checkResultQuote?.values || [];
    }

    getMatchingCheckResultQuote(quote) {
        const checkResultQuotes = this.getCheckResultQuotes();
        for(let q in checkResultQuotes) {
            if(quote >= parseFloat(checkResultQuotes[q].fromValue)) {
                return checkResultQuotes[q];
            }
        }
        return null;
    }
    _getSortedValues(rulesetConfiguration) {
        if(isEmpty(rulesetConfiguration)) {
            return [];
        }
        return rulesetConfiguration?.values || [];
    }

    _getTitle(title, defaultTitle) {
        return isEmpty(title) ? defaultTitle : title;
    }
}