import Twig from 'twig';

const templateRegistry = new Map();
const normalizedTemplateRegistry = new Map();
let TwigTemplates = null;

Twig.extend((TwigCore) => {
    TwigCore.token.definitions = [
        TwigCore.token.definitions[0],
        TwigCore.token.definitions[1],
        TwigCore.token.definitions[5],
        TwigCore.token.definitions[6],
        TwigCore.token.definitions[7],
        TwigCore.token.definitions[9],
        TwigCore.token.definitions[10],
    ];

    TwigCore.exports.extendTag({
        type: 'parent',
        regex: /^parent/,
        next: [],
        open: true,

        parse(token, context, chain) {
            return {
                chain,
                output: TwigCore.placeholders.parent,
            };
        },
    });

    TwigCore.exports.placeholders = TwigCore.placeholders;

    TwigCore.exports.getRegistry = function getRegistry() {
        return TwigCore.Templates.registry;
    };

    TwigCore.exports.clearRegistry = function clearRegistry() {
        TwigCore.Templates.registry = {};
    };

    TwigTemplates = TwigCore.Templates;
    TwigCore.cache = false;
});

const parentPlaceholder = Twig.placeholders.parent.replace(/\|/g, '\\|');
const parentRegExp = new RegExp(parentPlaceholder, 'gm');

/**
 *
 * @param {string} templateName
 * @param {string} templateStr
 * @returns {boolean}
 */
function registerTemplate(templateName, templateStr) {
    const template = templateRegistry.get(templateName) || {};
    const overrides = (template.overrides ? template.overrides : []);

    templateRegistry.set(templateName, {
        name: templateName,
        src: templateStr,
        extend: null,
        overrides: overrides,
    });

    return true;
}

/**
 *
 * @param {string} templateName
 * @param {string} extendName
 * @param {string} templateExtension
 * @returns {boolean}
 */
function extendTemplate(
    templateName,
    extendName,
    templateExtension = ''
) {
    const template = templateRegistry.get(templateName) || {};
    const overrides = (template.overrides ? template.overrides : []);

    templateRegistry.set(templateName, {
        name: templateName,
        src: templateExtension,
        extend: extendName,
        overrides: overrides,
    });

    return true;
}

/**
 *
 * @param {string} templateName
 * @param {string} templateOverride
 * @param {number} overrideIndex
 * @returns {boolean}
 */
function registerTemplateOverride(
    templateName,
    templateOverride,
    overrideIndex = 0,
) {
    const component = templateRegistry.get(templateName) || {
        name: templateName,
        src: null,
        extend: null,
        overrides: [],
    };
    component.overrides.push({
        index: overrideIndex,
        src: templateOverride,
    });
    templateRegistry.set(templateName, component);
    return true;
}

/**
 *
 * @param {Object} item
 */
function registerNormalizedTemplate(item) {
    let templateDefinition = resolveExtends(item);

    if (!templateDefinition) {
        normalizedTemplateRegistry.delete(item.name);
        return;
    }

    templateDefinition = {
        ...templateDefinition,
        html: '',
    };

    const hasOverridesInExtensionChain = (component) => {
        if (!component.extend) {
            return false;
        }
        return component.extend.overrides.length > 0 || hasOverridesInExtensionChain(component.extend);
    };

    templateDefinition.template.tokens = resolveExtendTokens(templateDefinition.template.tokens, templateDefinition);

    normalizedTemplateRegistry.set(templateDefinition.name, templateDefinition);

    templateDefinition = applyTemplateOverrides(templateDefinition.name);
    templateDefinition.html = templateDefinition.html.replace(parentRegExp, '');

    normalizedTemplateRegistry.set(templateDefinition.name, templateDefinition);
}

/**
 *
 * @returns {Map<string, Object>}
 */
function resolveTemplates() {
    const templates = Array.from(templateRegistry.values());
    templates.forEach(registerNormalizedTemplate);

    return normalizedTemplateRegistry;
}

/**
 *
 * @param {string} name
 * @returns {Object}
 */
function applyTemplateOverrides(name) {
    const item = normalizedTemplateRegistry.get(name);

    if (!item.overrides.length) {
        const finalHtml = item.template.render({});
        const updatedTemplate = {
            ...item,
            html: finalHtml,
        };

        normalizedTemplateRegistry.set(updatedTemplate.name, updatedTemplate);
        return updatedTemplate;
    }

    const baseTemplate = normalizedTemplateRegistry.get(item.name);

    item.overrides.forEach((override, index) => {
        const overrideTemplate = buildTwigTemplateInstance(
            `${baseTemplate.name}-${index}`,
            override.src,
        );

        overrideTemplate.tokens.forEach((overrideTokens) => {
            if (overrideTokens.type === 'logic') {
                baseTemplate.template.tokens = resolveTokens(baseTemplate.template.tokens, [overrideTokens], name);
            }
        });
    });

    normalizedTemplateRegistry.set(baseTemplate.name, baseTemplate);

    let updatedTemplate = normalizedTemplateRegistry.get(item.name);
    const finalHtml = updatedTemplate.template.render({});

    updatedTemplate = {
        ...updatedTemplate,
        html: finalHtml,
    };

    normalizedTemplateRegistry.set(updatedTemplate.name, updatedTemplate);

    return updatedTemplate;
}

function resolveTokens(tokens, overrideTokens) {
    if (!tokens) {
        return [];
    }

    return tokens.reduce((acc, token) => {
        if (token.type !== 'logic' || !token.token || !token.token.block) {
            return [...acc, token];
        }

        const blockName = token.token.block;
        const isInOverrides = findBlock(blockName, overrideTokens);

        if (isInOverrides) {
            if (isInOverrides.type === 'logic') {
                isInOverrides.token.output = mergeTokens(token, isInOverrides.token.output);
            }

            return [...acc, isInOverrides];
        }

        token.token.output = resolveTokens(token.token.output, overrideTokens);

        return [...acc, token];
    }, []);
}

function mergeTokens(token, tokens) {
    return tokens.reduce((acc, t) => {
        if (t.type === 'logic' && t.token.type === 'parent') {
            return [...acc, ...token.token.output];
        }

        if (t.token?.output) {
            t.token.output = resolveSubTokens(t.token.output, token.token.output);
        }

        return [...acc, t];
    }, []);
}

function resolveSubTokens(subToken, replacement) {
    return subToken.reduce((xs, s) => {
        if (s.type === 'logic' && s.token.type === 'parent') {
            return [...xs, ...replacement];
        }

        return [...xs, s];
    }, []);
}

function resolveExtendTokens(tokens, item) {
    if (!item.extend) {
        return tokens;
    }

    const extendedTokens = item.extend.template.tokens;
    const extensionTokens = Array.from(resolveExtendTokens(extendedTokens, item.extend));
    const itemTokens = normalizeTokens(Array.from(tokens), extensionTokens);

    tokens = extensionTokens.map((token) => {
        return resolveToken(token, itemTokens, item.name);
    });

    return tokens;
}

function normalizeTokens(tokens, extensionTokens) {
    return tokens.reduce((acc, token) => {
        if (token.token && !findNestedBlock(token.token.block, extensionTokens)) {
            return [...acc, ...token.token.output];
        }

        return [...acc, token];
    }, []);
}

function findNestedBlock(blockName, tokens) {
    return tokens.find((t) => {
        const exists = t.token && t.token.block === blockName;

        return exists || (t.token && findNestedBlock(blockName, t.token.output));
    });
}

function findBlock(blockName, tokens) {
    return tokens.find((t) => {
        return t.token && t.token.block === blockName;
    }) || null;
}

function resolveToken(token, itemTokens, name) {
    if (token.type !== 'logic') {
        return token;
    }

    const tokenBlockName = token.token.block;
    const isIn = findBlock(tokenBlockName, itemTokens);

    if (isIn) {
        if (isIn.type !== 'logic') {
            return isIn;
        }

        isIn.token.output = mergeTokens(token, isIn.token.output);

        return isIn;
    }

    token.token.output = token.token.output.map((t) => {
        return resolveToken(t, itemTokens, name);
    });

    return token;
}

function resolveExtends(item) {
    if (!item) {
        return null;
    }

    if (item.extend) {
        const extend = resolveExtends(templateRegistry.get(item.extend));
        if (!extend) {
            return null;
        }

        return {
            ...item,
            template: buildTwigTemplateInstance(item.name, item.src),
            extend,
        };
    }

    return { ...item, template: buildTwigTemplateInstance(item.name, item.src) };
}

function buildTwigTemplateInstance(name, template) {
    return TwigTemplates.parsers.twig({
        id: `${name}-baseTemplate`,
        data: template,
        path: false,
        options: {},
    });
}

function clearTwigCache() {
    Twig.clearRegistry();
}

function getTwigCache() {
    return Twig.getRegistry();
}

function disableTwigCache() {
    Twig.cache(false);
}

function getTemplateRegistry() {
    return templateRegistry;
}

function getNormalizedTemplateRegistry() {
    return normalizedTemplateRegistry;
}

function getTemplateOverrides(templateName) {
    if (!templateRegistry.has(templateName)) {
        return [];
    }

    const template = templateRegistry.get(templateName);

    return template.overrides || [];
}

function getRenderedTemplate(templateName) {
    const template = normalizedTemplateRegistry.get(templateName);

    if (!template) {
        return null;
    }

    return template.html;
}

export default {
    registerTemplate,
    extendTemplate,
    registerTemplateOverride,
    getRenderedTemplate,
    resolveTemplates,
    clearTwigCache,
    getTwigCache,
    disableTwigCache,
    getTemplateRegistry,
    getNormalizedTemplateRegistry,
    getTemplateOverrides
}
