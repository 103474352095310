import template from "./customer-account-recover-password-change-page.html.twig";
import {Form} from "vee-validate";

Packlab.Component.register('customer-account-recover-password-change-page', {
    template,

    components: { Form },

    inject: [ 'apiClient' ],
    mixins: [ 'form-validation', 'notifications' ],

    data() {
        return {
            isLoading: false,
            isSaving: false,
            isValidUrl: true,
            password: ''
        }
    },

    created() {
        this.isLoading = true;
        this.apiClient.validateCustomerAccountRecoverLink(
            this.$route.query.token,
            this.$route.query.hash
        )
            .then((result) => {
                this.isValidUrl = result.valid;
                this.isLoading = false;
            });
        //
    },

    methods: {
        savePassword() {
            this.$refs.passwordForm.validatePassword(this.$refs.form)
                .then((result) => {
                    this.apiClient.saveCustomerAccountRecoverPassword(
                        this.$route.query.token,
                        this.$route.query.hash,
                        result.password
                    )
                    .then(() => {
                        this.$router.push('/');
                    });
                });
        },
        backToLogin() {
            this.$router.push('/');
        }
    }
});
